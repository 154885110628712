import Privacy_Policy_for_Scholar_Adda from "../assets/Legal Document/Privacy_Policy_for_Scholar_Adda.docx";
import Terms_and_Services_for_Scholar_Adda from "../assets/Legal Document/Terms_and_Services_for_Scholar_Adda-1.docx";

export const StudentFlowData = [
  {
    title: "a. Participate in Live Exams",
    subTitle:
      "Students have the opportunity to participate in live exams conducted through the Scholars Adda platform. These exams are designed to be seamless and convenient, allowing students to take exams from the comfort of their homes or classrooms.",
  },
  {
    title: "b. Access to Diverse Exam Content",
    subTitle:
      "Scholars Adda offers students access to a wide range of exams covering various subjects and academic levels. Whether preparing for school exams, competitive entrance tests or standardized assessments, students can find relevant exam content to suit their needs.",
  },
  {
    title: "C. Prepare Effectively",
    subTitle:
      "With access to comprehensive study materials, including practice exams, quizzes and study guides, students can prepare effectively for their exams. Scholars Adda provides students with the resources they need to master key concepts and succeed academically.",
  },
  {
    title: "D. Showcase Knowledge and Skills",
    subTitle:
      "During live exams, students have the opportunity to showcase their knowledge and skills in a fair and transparent manner. By demonstrating their understanding of the material, students can earn recognition and rewards for their academic achievements.",
  },
  {
    title: "E. Rewards for Academic Excellence",
    subTitle:
      "Scholars Adda rewards students for their performance in Assessment , providing incentives and recognition for their hard work and dedication. Students can earn rewards points for achieving high scores, participating in tasks, activities, exams and engaging with the platform.",
  },
  {
    title: "F. Personalized Learning Experience",
    subTitle:
      "Scholars Adda offers students a personalized learning experience, allowing them to track their progress, identify areas for improvement and receive tailored recommendations for further study. This personalized approach helps students maximize their learning potential and achieve their academic goals.",
  },
];

export const StudentFlowSideHeading = [
  "Participate in Live Exams",
  "Access to Diverse Exam Content",
  "Prepare Effectively",
  "Showcase Knowledge and Skills",
  "Rewards for Academic Excellence",
  "Personalized Learning Experience",
];

export const haveQuestions = [
  {
    title: "How does Scholars Adda make learning engaging?",
    subTitle:
      "Scholars Adda employs innovative gamification principles, turning exams and challenges into exciting adventures. By integrating quizzes, leaderboards and rewards, we transform traditional learning into an interactive and enjoyable experience.",
  },
  {
    title: "What age group does Scholars Adda target?",
    subTitle:
      "Our platform is designed for individuals aged 13 to 30, addressing the unique challenges faced by this demographic in traditional education and providing a dynamic and engaging alternative.",
  },
  {
    title: "How does Scholars Adda contribute to financial learning?",
    subTitle:
      "Scholars Adda goes beyond traditional education by seamlessly integrating financial learning. Users not only acquire theoretical knowledge but also gain practical skills, fostering financial literacy and empowering them for real-world success.",
  },
  {
    title:
      "What sets Scholars Adda apart from traditional education platforms?",
    subTitle:
      "Scholars Adda stands out by addressing the challenges of disengagement, lack of motivation and untapped potential in traditional education. Our gamified approach, financial learning integration and commitment to real-world solutions redefine the educational experience.",
  },
  {
    title: "How does Scholars Adda ensure security and data privacy?",
    subTitle:
      "We prioritize the security of our users. Scholars Adda employs robust measures to safeguard user data, ensuring a secure and trusted environment for educational exploration. Our commitment is to provide a protected space for learning and growth.",
  },
];

export const TeacherFlowData = [
  {
    title: "Teacher",
    points: [
      {
        id: 0,
        title: "A. Create Tasks and Contribute to Question Bank",
        subTitle:
          "Teachers have the ability to create tasks and contribute questions to the platform's question bank. This allows them to tailor exams to their specific curriculum and teaching objectives, ensuring relevance and alignment with educational standards.",
      },
      {
        id: 1,
        title: "B. Evaluate Answer Papers",
        subTitle:
          "Teachers are responsible for evaluating students' answer papers submitted during live exams. Using predefined criteria and rubrics, teachers provide fair and comprehensive assessments of students' performance, offering constructive feedback to aid in their learning journey.",
      },
      {
        id: 2,
        title: "C. Earn Rewards",
        subTitle:
          "Teachers are rewarded for their contributions to the platform, including creating tasks, contributing questions and evaluating answer papers. These rewards serve as incentives for teachers to actively participate in the platform and contribute to its growth and success.",
      },
    ],
  },
  {
    title: "Validator",
    points: [
      {
        id: 3,
        title: "D. Evaluate Answer Papers",
        subTitle:
          "Validators, like teachers, evaluate students' answer papers submitted during live exams. They provide independent assessments of students' performance, ensuring objectivity and fairness in the evaluation process.",
      },
      {
        id: 4,
        title: "E. Conflict Resolution",
        subTitle:
          "In cases where the evaluations of teachers and validators do not match, a conflict arises. Answer papers with conflicting evaluations are referred to the Moderator for resolution.",
      },
      {
        id: 5,
        title: "F. Earn Rewards",
        subTitle:
          "Validators are also eligible to earn rewards for their contributions to the platform. By providing accurate and consistent evaluations, validators play a key role in upholding the platform's standards and integrity.",
      },
    ],
  },
  {
    title: "Moderator",
    points: [
      {
        id: 6,
        title: "D. Conflict Resolution",
        subTitle:
          "When answer papers receive conflicting evaluations from teachers and validators, moderators step in to resolve the conflict. They carefully review the assessments provided by both parties and make an impartial decision on the accuracy of the evaluation.",
      },
      {
        id: 7,
        title: "E. Reward and Penalty Allocation",
        subTitle:
          "Moderators determine which evaluator provided the correct assessment and which one did not. The evaluator with the accurate evaluation is rewarded, while the one with the inaccurate assessment incurs a penalty.",
      },
      {
        id: 8,
        title: "F. Uphold Integrity",
        subTitle:
          "Moderators ensure the integrity and fairness of the assessment process on Scholars Adda. By resolving conflicts and ensuring consistent evaluations, moderators contribute to the platform's reputation for reliability and trustworthiness.",
      },
    ],
  },
];

export const TeacherFlowSideHeading = [
  {
    title: "Teachers",
    points: [
      { id: 0, point: "Create Tasks and Contribute to Question Bank" },
      { id: 1, point: "Evaluate Answer Papers" },
      { id: 2, point: "Earn Rewards" },
    ],
  },
  {
    title: "Validator",
    points: [
      { id: 3, point: "Evaluate Answer Papers" },
      { id: 4, point: "Conflict Resolution" },
      { id: 5, point: "Earn Rewards" },
    ],
  },
  {
    title: "Moderator",
    points: [
      { id: 6, point: "Conflict Resolution" },
      { id: 7, point: "Reward and Penalty Allocation" },
      { id: 8, point: "Uphold Integrity" },
    ],
  },
];

export const GlobalUserFlowData = [
  {
    title: "a. Streamlined Exam Creation and Administration",
    subTitle:
      "Scholars Adda provides educational institutions with a comprehensive platform for creating, administering and managing exams. Institutions can easily create custom exams tailored to their curriculum and assessment objectives.",
  },
  {
    title: "b. Secure and Transparent Assessment Process",
    subTitle:
      "With Scholars Adda's Decentralized Live Exam Platform, educational institutions can conduct exams securely and transparently. The platform ensures the integrity of the assessment process, preventing cheating and ensuring fairness for all students.",
  },
  {
    title: "C. Real-time Monitoring and Analytics",
    subTitle:
      "Educational institutions have access to real-time monitoring and analytics tools on Scholars Adda, allowing them to track student progress and performance during exams. Institutions can identify areas of strength and weakness, enabling data-driven decision-making to improve teaching and learning outcomes.",
  },
  {
    title: "D. Customized Solutions and Support",
    subTitle:
      "Scholars Adda offers customized solutions and dedicated support to meet the unique needs of each educational institution. From onboarding and training to ongoing technical assistance, Scholars Adda ensures that institutions have the resources and support they need to succeed.",
  },
  {
    title: "E. Integration with Existing Systems",
    subTitle:
      "Scholars Adda seamlessly integrates with existing systems and platforms used by educational institutions, ensuring compatibility and interoperability. Institutions can easily integrate Scholars Adda into their existing infrastructure, minimizing disruption and maximizing efficiency.",
  },
];

export const GlobalUserFlowSideHeading = [
  "Streamlined Exam Creation and Administration",
  "Secure and Transparent Assessment Process",
  "Real-time Monitoring and Analytics",
  "Customized Solutions and Support",
  "Integration with Existing Systems",
];

export const legalSideHeading = [
  "Terms of Service",
  "Privacy Policy",
  "Cookie Policy",
  "GDPR Compliance",
  "Copyright Notice",
  "Disclaimers",
  "Acceptable Use Policy",
  "Refund Policy",
  "DMCA Compliance",
  "Security Measures",
  "Contact Legal Team",
  "Regulatory Compliance",
  "Accessibility",
  "Independent Legal Advice",
];

export const privacyPolicy = `Scholar’s Adda is owned and operated by Scholar's Adda Pvt. Ltd., a company registered in India. Your privacy is important to us and we are committed to protecting it. This privacy policy (\"Policy\") explains how we collect, use, share and protect your personal information when you use our application (\"Application\"), website (\"Website\") and other products and services (\"Services\") offered by Scholar’s Adda. Please read this Policy carefully before using Scholar’s Adda or providing any personal information to us. By using Scholar’s Adda or providing any personal information to us, you agree to this Policy and consent to our collection, use, sharing and protection of your personal information as described in this Policy. If you do not agree to this Policy, please do not use Scholar’s Adda or provide any personal information to us. The governing law regarding this privacy policy is the IT Rules, 2000. This privacy policy is in compliance with the principles of EU GDPR.

*(What Information We Collect)*
We collect various types of information from you when you use Scholar’s Adda or provide any information to us, such as:
Personal information: This is the information that identifies you as an individual, such as your name, age, email address, phone number, location and password. We collect this information when you register with us, create or update your profile, make purchases, respond to surveys, participate in contests or contact us for any queries or feedback. We also collect this information from third parties, such as social media platforms, payment gateways or other service providers, when you link or integrate your account with them or use their services on Scholar’s Adda.
Educational information: This is the information that relates to your educational interests, preferences, goals, performance and progress, such as your grade, subject, course, program, quiz, assignment, test, doubt, discussion and feedback. We collect this information when you use our Services, such as watching videos, taking quizzes, completing assignments and participating in live sessions. We also collect this information from third parties, such as schools, colleges, universities or other educational institutions, when you enrol or register with them or use their services on Scholar’s Adda.
Transactional information: This is the information that relates to your transactions and payments on Scholar’s Adda, such as your order details, payment method, amount, currency, date, time, status and receipt. We collect this information when you make purchases, subscribe to plans or use in-app features on Scholar’s Adda. We also collect this information from third parties, such as payment gateways, banks or other service providers, when you use their services on Scholar’s Adda.
Reward information: This is the information that relates to your rewards and PLR Tokens on Scholar’s Adda, such as your balance, earnings, usage, exchange and withdrawal. We collect this information when you use our reward system, such as completing tasks, activities and challenges or accessing premium features and services on Scholar’s Adda. 
Technical information: This is the information that relates to your device, software and network, such as your device type, model, operating system, browser, IP address, unique device identifier, cookie and other technical data. We collect this information when you access or use Scholar’s Adda or our Services or when you allow or enable certain features or permissions on your device or software, such as location, camera, microphone or notifications.
Usage information: This is the information that relates to your usage and behaviour on Scholar’s Adda or our Services, such as your session duration, frequency, pages viewed, features used, actions taken and other usage data. We collect this information when you access or use Scholar’s Adda or our Services or when you interact with our content, features and services, such as videos, quizzes, assignments and live sessions.
Communication information: This is the information that relates to your communication with us or other users on Scholar’s Adda or our Services, such as your messages, emails, calls, chats and other communication data. We collect this information when you contact us for any queries, feedback or support or when you communicate with other users on Scholar’s Adda or our Services, such as asking doubts, participating in discussions and giving feedback.

*(How We Use Your Information)*
We use your information for various purposes, such as:
-	To provide you with our Services, such as delivering content, features and services, personalizing your experience, enhancing your learning, assessing your performance and rewarding your achievements.
-	To communicate with you, such as sending you notifications, updates, offers, promotions, newsletters and other information, responding to your queries, feedback or support requests and facilitating your communication with other users.
-	To process your transactions and payments, such as verifying your identity, validating your payment method, completing your purchases, subscriptions or in-app features and issuing receipts or invoices.
-	To manage your rewards and PLR Tokens, such as crediting your earnings, deducting your usage, enabling your exchange and facilitating your withdrawal.
-	To improve our Services, such as analysing your usage and behaviour, conducting research and surveys, testing new features and services and resolving issues or problems.
-	To protect our Services, such as monitoring and preventing fraud, abuse or unauthorized access, enforcing our terms and policies and complying with legal obligations.

*(How We Share Your Information)*
We share your information with various parties, such as:
Third parties: These are the parties that provide services or products to us or to you on Scholar’s Adda, such as social media platforms, payment gateways, educational institutions, content providers, service providers and other partners. We share your information with them to enable their services or products on Scholar’s Adda, such as linking or integrating your account with them, facilitating your transactions or payments, managing your rewards or PLR Tokens, delivering content, features or services or offering promotions or discounts. We also share your information with them to improve our Services, such as analysing your usage and behaviour, conducting research and surveys, testing new features or services or resolving issues or problems. We do not control the privacy practices of these third parties and we encourage you to review their privacy policies before using their services or products on Scholar’s Adda.
Other users: These are the users who use Scholar’s Adda or our Services, such as students, teachers, parents or mentors. We share your information with them to facilitate your communication and interaction with them, such as asking doubts, participating in discussions and giving feedback. We also share your information with them to enhance your learning and performance, such as displaying your profile, progress, achievements and rewards. You can control the visibility and accessibility of your information to other users through your settings on Scholar’s Adda.
Law enforcement: These are the authorities that enforce the law and protect the rights, property or safety of us, you or others. We share your information with them when we are required or permitted by law, such as complying with legal requests orders or obligations or cooperating with investigations, inquiries or audits.

*(How We Protect Your Information)*
We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, alteration or destruction. These measures include implementing technical, physical and administrative safeguards, such as encryption, firewalls, passwords, backups and access controls. However, no method of transmission or storage is completely secure and we cannot guarantee the absolute security of your personal information. You are responsible for maintaining the security and confidentiality of your account and password and for any activity that occurs under your account. You must notify us immediately of any unauthorized access or use of your account.

*(How Long We Retain Your Information)*
We retain your personal information for as long as necessary to provide you with our services, comply with our legal obligations, resolve disputes and enforce our agreements. The retention period may vary depending on the type and purpose of the information collected. When we no longer need your personal information, we will delete or anonymize it or securely dispose of it in accordance with applicable laws and regulations.

*(How We Share Your Information)*
We do not sell, rent or trade your personal information to third parties for their own marketing purposes, without your consent. We may share your personal information with third parties in the following circumstances:
-	With your consent or at your direction;
-	With our affiliates, subsidiaries, partners and service providers who help us operate our business and provide our services, such as hosting, payment processing, analytics, marketing and customer support;
-	With other users or third parties who are involved in your transactions or interactions on Scholar’s Adda, such as teachers, tutors, mentors, peers or examiners;
-	With other users or third parties who offer or request products or services that may be of interest to you, such as scholarships, internships or career opportunities;
-	With law enforcement, regulators, courts or other authorities, when we are required or permitted by law or when we believe it is necessary to protect our rights, property or safety or the rights, property or safety of others;
-	With other parties in connection with a merger, acquisition, reorganization, bankruptcy or other business transaction, subject to appropriate confidentiality agreements and compliance with applicable laws and regulations.

*(How We Transfer Your Information)*
We operate globally and we may transfer your personal information to our affiliates, subsidiaries, partners and service providers located in other countries or regions, where the data protection laws may differ from those of your country or region. By using Scholar’s Adda or providing us with any personal information, you consent to such transfers and agree to be bound by the applicable laws and regulations of the destination countries or regions. We will take appropriate steps to ensure that your personal information is treated securely and in accordance with this privacy policy and the applicable laws and regulations.

*(How We Use Cookies and Other Technologies)*
We use cookies and other technologies, such as web beacons, pixels, tags and scripts, to collect, store and track information about your preferences, activities and interactions on Scholar’s Adda or through Scholar’s Adda. Cookies are small files that are stored on your device when you visit a website or use an application. They help us to:
-	Remember your settings and preferences, such as language, currency and display options;
-	Provide you with personalized content, features and services, such as recommendations, quizzes and rewards;
-	Analyse and improve the performance, functionality and security of Scholar’s Adda or its content, features and services;
-	Measure and report the effectiveness of our advertising and marketing campaigns;
-	Deliver relevant and targeted ads to you on Scholar’s Adda or on other platforms.
You can manage your cookie preferences and settings through your browser or device. However, if you disable or reject cookies, some parts or features of Scholar’s Adda may not function properly or may become inaccessible. For more information about how we use cookies and other technologies, please see our cookie policy.

*(Your Rights and Choices)*
You have certain rights and choices regarding your personal information, subject to applicable laws and regulations. These rights and choices may include:
-	Accessing, updating, correcting or deleting your personal information;
-	Changing your communication and marketing preferences and opting out of receiving promotional emails, messages or calls from us or our partners;
-	Withdrawing your consent to our collection, use or sharing of your personal information at any time, without affecting the lawfulness of the processing based on your consent before its withdrawal;
-	Objecting to or restricting our processing of your personal information for certain purposes, such as direct marketing, profiling or automated decision-making;
-	Requesting a copy or a transfer of your personal information in a portable and machine-readable format;
-	Lodging a complaint with us or the relevant data protection authority, if you are not satisfied with how we handle your personal information.
To exercise your rights and choices or to make any inquiries or requests regarding your personal information, please contact us using the contact details provided below. We will respond to your request within a reasonable time frame and in accordance with the applicable laws and regulations. We may ask you to verify your identity and provide additional information or documentation to process your request.

*(Children's Privacy)*
Scholar’s Adda is not intended for children under the age of 13 or the equivalent minimum age in the relevant jurisdiction. We do not knowingly collect, use or share any personal information from children under the age of 13 or the equivalent minimum age in the relevant jurisdiction, without verifiable parental consent or as permitted by law. If we become aware that we have collected any personal information from a child under the age of 13 or the equivalent minimum age in the relevant jurisdiction, without verifiable parental consent or as permitted by law, we will delete or destroy such information as soon as possible. If you believe that we have collected any personal information from a child under the age of 13 or the equivalent minimum age in the relevant jurisdiction, without verifiable parental consent or as permitted by law, please contact us using the contact details provided below.

*(Changes to This Privacy Policy)*
We may revise and update this privacy policy from time to time, at our sole discretion, without prior notice or liability. The revised privacy policy will be effective immediately when posted on Scholar’s Adda or communicated to you. You agree to review this privacy policy periodically and to be bound by the latest version of this privacy policy. If you do not agree to the revised privacy policy, please stop using Scholar’s Adda or providing us with any personal information.
`;

export const termAndCondition = `Scholars Adda is owned and operated by Scholar's Adda Pvt. Ltd., a company registered in India. By accessing or using Scholars Adda, you agree to be bound by these terms and services, as well as our privacy policy and any other policies or guidelines that we may post on Scholars Adda from time to time. If you do not agree to these terms and services, please do not use Scholars Adda.

*(Services)*
Scholars Adda provides as test preparation and assessment services for students of different grades and subjects, as well, with the use of a decentralized platform (blockchain technology). Scholars Adda also offers a reward system that allows students to earn SRP (Scholars Reward Point), issued by Scholar's Adda Pvt. Ltd., for completing certain tasks and activities on Scholars Adda. SRP (Scholars Reward Point) can be used to access premium features and services on Scholars Adda. 

*(Registration and Account)*
To use Scholars Adda, you need to register and create an account with us. You must provide accurate and complete information when registering and keep your account information updated. You are responsible for maintaining the security and confidentiality of your account and password and for any activity that occurs under your account. You may request us to delete your account at any time by contacting us. Upon receiving your request, we will initiate the account deletion process, which may take up to 180 days to complete. During this period your account will remain inactive and inaccessible. However, if you log in to your account again within the 180-day period, the account deletion process will be cancelled and your account will be reactivated. You must notify us immediately of any unauthorized access or use of your account. We reserve the right to suspend or terminate your account at any time, for any reason, without prior notice or liability. Please note that some of your information may remain in our systems after your account is deleted, as required by law or for legitimate business purposes.

*(Fees and Payments)*
Scholars Adda offers both free and paid services. Some of the paid services include subscription plans, one-time purchases and in-app purchases. The fees and payment methods for the paid services are displayed on Scholars Adda or communicated to you before you make a purchase. You agree to pay the fees for the paid services that you select and authorize us to charge your chosen payment method for such fees. You also agree to pay any applicable taxes, levies or duties imposed by the relevant authorities. All fees are non-refundable, unless otherwise stated in these terms and services or required by law.

*(Rewards and SRP (Scholars Reward Point))*
Scholars Adda rewards students for spending time on the app for education-based services, such as watching videos, taking quizzes, completing assignments and participating in live session examinations. The rewards are in the form of SRP (Scholars Reward Point) issued by Scholar's Adda Pvt. Ltd., that can be used to access premium features and services on Scholars Adda. The amount of SRP (Scholars Reward Point) that you can earn depends on various factors, such as the type, duration and quality of the activity, your performance and your level of engagement. Scholars Adda reserves the right to modify, suspend or terminate the reward system at any time, for any reason, without prior notice or liability.
You acknowledge and agree that SRP (Scholars Reward Point) are not legal tender and do not have any intrinsic value. You are solely responsible for managing and securing your SRP (Scholars Reward Point) and for any transactions or exchanges that you make with SRP (Scholars Reward Point). Scholars Adda is not liable for any loss, theft, damage or unauthorized use of your SRP (Scholars Reward Point) or for any disputes, claims or damages arising from or related to your use of SRP (Scholars Reward Point).

*(Intellectual Property Rights)*
Scholars Adda and its licensors own all rights, title and interest in and to Scholars Adda and its content, features and services, including but not limited to the trademarks, logos, designs, graphics, texts, images, videos, audios, software, code and data. Nothing in these terms and services grants you any right, title or interest in or to Scholars Adda or its content, features and services, except for the limited license to use Scholars Adda as permitted by these terms and services. You agree not to copy, modify, reproduce, distribute, transmit, display, perform, publish, license, create derivative works from, transfer or sell any part of Scholars Adda or its content, features and services, without our prior written consent. You also agree not to reverse engineer, decompile, disassemble or otherwise attempt to discover the source code or underlying structure of Scholars Adda or its content, features and services, without our prior written consent.

*(User Content and Conduct)*
Scholars Adda allows you to interact, upload, post, share or otherwise provide content, such as comments, feedback, reviews, ratings, questions, answers and other materials, on Scholars Adda or through Scholars Adda (\"User Content\"). You retain all rights, title and interest in and to your User Content and grant us a non-exclusive, royalty-free, worldwide, perpetual, irrevocable and sub-licensable license to use, reproduce, modify, adapt, publish, translate, distribute and display your User Content on Scholars Adda or through Scholars Adda, for any purpose, without any compensation or attribution to you. You also waive any moral rights or other rights that you may have in your User Content.
You are solely responsible for your User Content and your conduct on Scholars Adda or through Scholars Adda. You agree not to upload, post, share or otherwise provide any User Content that:
- is unlawful, harmful, abusive, harassing, threatening, defamatory, libelous, slanderous, obscene, vulgar, pornographic, indecent, offensive, hateful, discriminatory or otherwise objectionable;
- infringes or violates any intellectual property rights, privacy rights, publicity rights or other rights of any person or entity;
- contains or promotes any viruses, malware, spyware or other harmful or malicious code or content;
- contains or promotes any false, misleading or inaccurate information or claims;
- contains or promotes any unsolicited or unauthorized advertising, promotional materials, spam, junk mail or any other form of solicitation;
- impersonates or misrepresents your identity or affiliation with any person or entity;
- interferes with or disrupts the operation or functionality of Scholars Adda or its content, features and services or the servers or networks that host or connect to Scholars Adda;
- violates any applicable laws, rules, regulations or policies.
We reserve the right to review, monitor, edit, remove or disable any User Content at any time, for any reason, without prior notice or liability. We also reserve the right to report any User Content or conduct that we believe violates these terms and services or any applicable laws, rules, regulations or policies, to the relevant authorities.

*(Disclaimer of Warranties)*
Scholars Adda and its content, features and services are provided \"as is\" and \"as available\", without any warranties of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, non-infringement, accuracy, completeness, reliability, availability, security or performance. We do not warrant that Scholars Adda or its content, features and services will meet your requirements or expectations or that Scholars Adda or its content, features and services will be uninterrupted, error-free, virus-free or defect-free or that any errors, viruses or defects will be corrected. We do not warrant that Scholars Adda or its content, features and services will achieve any results or outcomes or that Scholars Adda or its content, features and services will be compatible with any devices, software or systems. You use Scholars Adda and its content, features and services at your own risk and discretion and you assume all responsibility and liability for any damages, losses, costs or expenses that may result from your use of Scholars Adda or its content, features and services.

*(Limitation of Liability)*
To the fullest extent permitted by law, we and our affiliates, directors, officers, employees, agents, partners and licensors are not liable for any direct, indirect, incidental, consequential, special, punitive or exemplary damages or any other damages, arising from or related to your use of or inability to use Scholars Adda or its content, features and services or your use of or reliance on any information, content or materials provided or made available on Scholars Adda or through Scholars Adda or your User Content or conduct on Scholars Adda or through Scholars Adda or any interactions or transactions that you may have with other users or third parties on Scholars Adda or through Scholars Adda or any errors, omissions, interruptions, delays, defects or failures of Scholars Adda or its content, features and services or any SRP (Scholars Reward Point) that you may earn, use or lose on Scholars Adda or through Scholars Adda or any other matters relating to Scholars Adda or its content, features and services, whether based on contract, tort, negligence, strict liability or any other legal theory and whether or not we have been advised of the possibility of such damages. In no event shall our total liability to you for all damages, losses, costs or expenses exceed the amount paid by you, if any, for accessing or using Scholars Adda or its content, features and services or one hundred Indian Rupees (INR 100), whichever is less.

*(Third Party Advertising)*
There may be third party information, advertisement and schemes displayed through this Web site. Scholars Adda disclaims, to the fullest extent permissible, the correctness, viability, availability, merchantability or fitness of such information, advertisement and scheme. Scholars Adda hereby declares that the advertisers and their clients etc. are neither its agent, partner or principal and Scholars Adda does not provide any guarantee or warranty or any representation on behalf of any of the advertisers or their clients.
Any material downloaded and used shall be at the risk of the user and no services utilised by this Web site shall create any warranty. Scholars Adda shall not responsible if any information/page is downloaded from Scholars Adda and after downloading complete/partial, text/information is altered/removed/obscured contained therein.
Scholars Adda and/or its respective suppliers make no representations about the suitability, reliability, availability, timelines, of the products and services contained on the Web site for any purpose. All products, services are provided "as is" without warranty of any kind. and/or its respective suppliers hereby disclaim all warranties and conditions with regard to this information, products, services including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.
In no event shall Scholars Adda and/or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Scholars Adda Website, with the delay or inability to use the Scholars Adda Website or related services, the provision of or failure to provide services or for any information, software, products and services obtained through the Scholars Adda Website or otherwise arising out of the use of Scholars Adda Website, whether based on contract, negligence, strict liability or otherwise, even if Scholars Adda or any of its suppliers has been advised of the possibility of damages.

*(Indemnification)*
You agree to indemnify, defend and hold harmless us and our affiliates, directors, officers, employees, agents, partners and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses or fees (including reasonable attorneys' fees) arising from or related to:
- your use of or inability to use Scholars Adda or its content, features and services or your use of or reliance on any information, content or materials provided or made available on Scholars Adda or through Scholars Adda;
- your User Content or conduct on Scholars Adda or through Scholars Adda or your interactions or transactions with other users or third parties on Scholars Adda or through Scholars Adda;
- your violation of these terms and services or any applicable laws, rules, regulations or policies;
- your infringement or violation of any intellectual property rights, privacy rights, publicity rights or other rights of any person or entity;
- any SRP (Scholars Reward Point) that you may earn, use or lose on Scholars Adda or through Scholars Adda.
We reserve the right, at our own expense, to assume the exclusive defense and control of any matter subject to indemnification by you and you agree to cooperate with us in such defense. You agree not to settle any matter without our prior written consent.

*(Termination)*
We may terminate or suspend your access to or use of Scholars Adda or its content, features and services or your account, at any time, for any reason, without prior notice or liability, including but not limited to:
- your breach of these terms and services or any applicable laws, rules, regulations or policies;
- your request or consent;
- your inactivity or non-payment of fees;
- our discontinuation or modification of Scholars Adda or its content, features and services;
- technical or security issues or problems;
- fraudulent or illegal activities.
Upon termination or suspension, your right to use Scholars Adda or its content, features and services or your account, will cease immediately and we may delete or deactivate your account and all related information and files. We may also bar you from accessing or using Scholars Adda or its content, features and services or your account, in the future. Any provisions of these terms and services that by their nature should survive termination or suspension shall survive, including but not limited to the provisions on intellectual property rights, user content and conduct, disclaimer of warranties, limitation of liability, indemnification and governing law and jurisdiction.

*(Governing Law and Jurisdiction)*
These terms and services and your use of Scholars Adda or its content, features and services are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts of Chhatrapati Sambhaji Nagar (previously known as Aurangabad), Maharashtra, India, for any disputes, claims or actions arising from or related to these terms and services or your use of Scholars Adda or its content, features and services, unless otherwise required by applicable law.

*(Changes to These Terms and Services)*
We may revise and update these terms and services from time to time, at our sole discretion, without prior notice or liability. The revised terms and services will be effective immediately when posted on Scholars Adda or communicated to you. You agree to review these terms and services periodically and to be bound by the latest version of these terms and services. If you do not agree to the revised terms and services, please stop using Scholars Adda or its content, features and services.
`;

export const legalDataTermAndCondition = [
  {
    title: "Terms of Service",
    subTitle: termAndCondition,
    updated: "Last updated on March 2023",
    link: Terms_and_Services_for_Scholar_Adda,
  },
  {
    title: "Cookie Policy",
    subTitle:
      "Lorem ipsum dolor sit amet consectetur. Ac facilisis praesent dignissim maecenas varius risus. Enim tincidunt sed aliquam vitae porttitor ut. Accumsan commodo dui adipiscing enim. Pulvinar et dictum orci ut varius adipiscing sollicitudin. Convallis nam arcu at penatibus. Pellentesque nulla nisi arcu egestas donec facilisis vestibulum. Cursus at platea facilisis enim arcu nec ridiculus pellentesque odio. Consequat ante mattis neque vestibulum urna. Proin vel duis at nunc eu. Massa enim vestibulum donec a. Auctor nulla eu cursus placerat orci et. Mi arcu id morbi cursus sodales et eu. Iaculis tincidunt varius elit sapien a.",
    updated: "Last updated on March 2023",
  },
];

export const legalDataPrivacyPolicy = [

  {
    title: "Privacy Policy",
    subTitle: privacyPolicy,
    updated: "Last updated on March 2023",
    link: Privacy_Policy_for_Scholar_Adda,
  },
  {
    title: "Cookie Policy",
    subTitle:
      "Lorem ipsum dolor sit amet consectetur. Ac facilisis praesent dignissim maecenas varius risus. Enim tincidunt sed aliquam vitae porttitor ut. Accumsan commodo dui adipiscing enim. Pulvinar et dictum orci ut varius adipiscing sollicitudin. Convallis nam arcu at penatibus. Pellentesque nulla nisi arcu egestas donec facilisis vestibulum. Cursus at platea facilisis enim arcu nec ridiculus pellentesque odio. Consequat ante mattis neque vestibulum urna. Proin vel duis at nunc eu. Massa enim vestibulum donec a. Auctor nulla eu cursus placerat orci et. Mi arcu id morbi cursus sodales et eu. Iaculis tincidunt varius elit sapien a.",
    updated: "Last updated on March 2023",
  },
];

export const NavbarProgramName = "19 February 2024";
export const NavbarProgramDate =
  "Chh. Shivaji Maharaj Memorial Scholarly Challenge";
export const ContestAlertNameAndDate =
  "Mahatma Gandhi Jayanti Scholarship Exam on 2nd Oct, 2024, at 4:00 PM";

export const HOME_ALERT_TIMER_DATE = "10/02/2024"; //MONTH/DATE/YEAR

export const haveQuestionsRef = [
  {
    title: "How do I find my referral code?",
    subTitle:
      'You can find your referral code directly in your app profile under the "Invite Friends" section.',
  },
  {
    title: "Can I use the points for any feature within the app?",
    subTitle:
      "Yes, points can be used to redeem rewards offered by Scholars Adda.",
  },
  {
    title: "Is there a limit to how many people I can refer?",
    subTitle:
      "No, there is no limit! Invite as many friends as you like and earn points for each successful registration.",
  },
];
