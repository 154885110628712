import React, { useEffect } from "react";
import AccessPlatform from "../components/AccessPlatform";
import FooterV2 from "../components/common/FooterV2/FooterV2";

function AccessPlatformPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AccessPlatform />
      <FooterV2 />
    </div>
  );
}

export default AccessPlatformPage;
