import React, { useRef, useState } from "react";
import CustomAccordion from "../common/Accordion";
import contactUsGradient from "../../assets/contactUsGradient.png";
import { haveQuestions } from "../../Utils/Contanst";
import "../../css/howItWorksTabs/studentFlow.css";

function StudentFlow({ flowData, sideHeading }) {
  const [selectedHeading, setSelectedHeading] = useState("heading-0");

  const scrollToHeading = (index) => {
    const headingElement = document.getElementById(`heading-${index}`);
    setSelectedHeading(`heading-${index}`);
    // if (headingElement) {
    //   headingElement.scrollIntoView({ behavior: "smooth" }) ;
    // }
    if (headingElement) {
      const topOffset =
        headingElement.getBoundingClientRect().top + window.pageYOffset - 130;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="student_flow">
        <div className="right">
          <div className="contentBox custom_scroll">
            {sideHeading.map((item, index) => (
              <p
                key={index}
                className="StepSideHeading"
                style={{
                  color:
                    selectedHeading === `heading-${index}` ? "#FF6700" : "",
                }}
                onClick={() => scrollToHeading(index)}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
        <div className="left">
          <div className="contentBox">
            {flowData.map((item, index) => (
              <div key={index} id={`heading-${index}`} className="box">
                <p className="StepHeading">{item?.title}</p>
                <p className="StepSubHeading">{item?.subTitle}</p>
              </div>
            ))}
          </div>
        </div>

        <img
          src={contactUsGradient}
          className="sf_rightFloatTriangle mob_hide"
          alt="imgg"
        />
      </div>

      <div className="sf_HaveQuestion">
        <p className="HaveQuestion">Have Questions?</p>
        <div className="HQ_wrappper">
          {haveQuestions.map((item) => (
            <CustomAccordion title={item.title} subTitle={item.subTitle} />
          ))}
        </div>
      </div>
    </>
  );
}

export default StudentFlow;
