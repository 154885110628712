import React from "react";
import "../../css/HowItWorks.css";
import CustomTabs from "./Tabs";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import StudentFlow from "./StudentFlow";
import Footer from "./Footer";
import {
  GlobalUserFlowData,
  GlobalUserFlowSideHeading,
  StudentFlowData,
  StudentFlowSideHeading,
  TeacherFlowData,
  TeacherFlowSideHeading,
} from "../../Utils/Contanst";
import TeacherFlow from "./TeacherFlow/TeacherFlow";

function Hero() {
  const tabsItems = [
    {
      title: "Student Flow",
      component: (
        <StudentFlow
          flowData={StudentFlowData}
          sideHeading={StudentFlowSideHeading}
        />
      ),
    },
    {
      title: "Teacher Flow",
      component: (
        <TeacherFlow
          flowData={TeacherFlowData}
          sideHeading={TeacherFlowSideHeading}
        />
        
      ),
    },
    // {
    //   title: "Global Users",
    //   component: (
    //     <StudentFlow
    //       flowData={GlobalUserFlowData}
    //       sideHeading={GlobalUserFlowSideHeading}
    //     />
    //   ),
    // },
  ];
  return (
    <div className="howItWorks">
      <div className="content_wrapper">
        <img className="feature1Img" src={feature1} alt="imgg" />
        <p className="HeroMainText">
          Your Path to Academic Rewards <br className="mob_hide" /> Simplified
        </p>
        <p className="HeroSubText">
          From registration to rewards, here's how it all comes together
        </p>
        <p className="StepTitle">Step-by-Step Guide</p>
        <img className="feature2Img" src={feature2} alt="imgg" />
      </div>
      <CustomTabs tabsItems={tabsItems} />
      {/* <Footer /> */}
    </div>
  );
}

export default Hero;
