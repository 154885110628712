import React, { useState, useEffect } from "react";
import Styles from "./WhatIsScholarAdda.module.scss";
//Images Imports
import NameImg from "../../../assets/home/what_is_sa.svg";
import NameImgMob from "../../../assets/home/what_is_sa_mob.svg";
import triangleImg from "../../../assets/home/wi_sa_t1.svg";
import { useNavigate } from "react-router";

const WhatIsScholarAdda = () => {
  const navigate = useNavigate();

  // const [showBox1, setShowBox1] = useState(true);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowBox1(false);
  //     setTimeout(() => {
  //       setShowBox1(true);
  //     }, 2000); // Wait 2 seconds before showing box1 again
  //   }, 3000); // Wait 5 seconds before hiding box1

  //   return () => clearTimeout(timer);
  // }, [showBox1]);
  return (
    <>
      <section className={Styles.wis}>
        <picture className={Styles.wis_img1}>
          <source media="(max-width:767px)" srcSet={NameImgMob} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={NameImg} alt="desk_img" />
          <img
            data-aos="fade-right"
            data-aos-delay="300"
            src={NameImg}
            alt="bannerImg"
          />
        </picture>

        <div className={Styles.wis_img2}>
          <img
            data-aos="fade-left"
            data-aos-delay="300"
            src={triangleImg}
            alt="image2"
          />
        </div>
        <div className={Styles.wis_left}>
          <div className="hidden">
            <div data-aos="fade-right">What is Scholars Adda</div>
          </div>
        </div>
        <div data-aos="fade-left" className={Styles.wis_right}>
          {/* {showBox1 ? (
            <div className="box1_wsa">
              Welcome to Scholars Adda, your gateway to a transformative
              learning experience! Scholars Adda is not just an app; it's a
              dynamic platform designed to revolutionize the way you explore,
              engage and earn in your academic journey.
            </div>
          ) : ( */}
          <div className={`${Styles.wis_right_text2} box2_wsa`}>
            <div>
              Welcome to Scholars Adda, your gateway to a transformative
              learning experience! Scholars Adda is not just an app; it's a
              dynamic platform designed to revolutionize the way you explore,
              engage and earn in your academic journey.
            </div>
            <button
              className="learn_more"
              onClick={() => navigate("/about-us")}
            >
              <span style={{ position: "relative", zIndex: 10 }}>
                LEARN MORE
              </span>
            </button>
          </div>
          {/* )} */}
        </div>
      </section>
    </>
  );
};

export default WhatIsScholarAdda;
