import React from "react";
import Styles from "./BrandWhatDo.module.scss";
//Images Imports
import NameImg from "../../../assets/ambassodor/desk_wd.png";
import MobNameImg from "../../../assets/ambassodor/mob_wd.png";
import WhatGirl from "../../../assets/ambassodor/what_girl.png";
import { PropTypes } from "prop-types";
import { BrandWhatDoData } from "./BrandWhatDoData";
import { TickMark } from "../../../Svgs/Svgs";
import HTMLReactParser from "html-react-parser";

const BrandWhatDo = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.BrandWhatDo}>
        <picture className={Styles.BrandWhatDo_img1}>
          <source media="(max-width:767px)" srcSet={MobNameImg} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={NameImg} alt="desk_img" />
          <img
            data-aos="fade-right"
            data-aos-delay="300"
            src={NameImg}
            alt="bannerImg"
          />
        </picture>

        <div className={Styles.BrandWhatDo_img2}>
          <img
            data-aos="fade-right"
            data-aos-delay="300"
            src={WhatGirl}
            alt="image2"
          />
        </div>

        <div className={Styles.BrandWhatDo_left}>
          <div className={Styles.BrandWhatDo_left_title}>
            <div className="hidden">
              <div data-aos="fade-right">What You'll Do</div>
            </div>
          </div>
          <div className={Styles.BrandWhatDo_left_subtitle}>
            <div className="hidden">
              <div data-aos="fade-right">As a brand ambassador for Scholars Adda, you'll have the opportunity to</div>
            </div>
          </div>
        </div>
        <div className={Styles.BrandWhatDo_right}>
          <ul className={Styles.BrandWhatDo_right_list}>
            {data?.map((item, index) => {
              return (
                <>
                  <div key={index} className="hidden">
                    <div data-aos="fade-up">
                      <li className={Styles.BrandWhatDo_right_list_item}>
                        <TickMark />
                        <div>{HTMLReactParser(item)}</div>
                      </li>
                    </div>
                  </div>
                </>
              );
            })}
          </ul>
        </div>
      </section>
    </>
  );
};

BrandWhatDo.defaultProps = {
  data: BrandWhatDoData,
};
BrandWhatDo.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BrandWhatDo;
