import React from "react";
import Styles from "./AboutFeature.module.scss";
import HTMLReactParser from "html-react-parser";

const AboutFeatureCard = ({ id, url, title }) => {
  return (
    <>
      <div className={Styles.card}>
        {/* <div className={Styles.card_num}>
          <div className="hidden">
            <div data-aos="fade-down">{`0${id}`}</div>
          </div>
        </div> */}

        <div data-aos="fade-in" className={Styles.card_img}>
          <img src={url} alt="feature_img" />
        </div>
        <div className={Styles.card_title}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(title || "")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutFeatureCard;
