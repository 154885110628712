import React from "react";
import Styles from "./FeatureHighlight.module.scss";
import FA_Triangle from "../../../assets/home/fa_triangle.svg";
import FA_Triangle2 from "../../../assets/home/fh_tra_2.svg";
import FA_Stickers from "../../../assets/home/stickers_fa.svg";
import icon1 from "../../../assets/home/fh/1.png";
import icon2 from "../../../assets/home/fh/2.png";
import icon3 from "../../../assets/home/fh/3.png";
import icon4 from "../../../assets/home/fh/4.png";
import icon5 from "../../../assets/home/fh/5.png";
import imgText from "../../../assets/home/fh/fh_text.png";

const FeatureHighlight = () => {
  return (
    <>
      <section className={Styles.fh}>
        <div className={`${Styles.fh_title} desk_hide`}>
          <div className="hidden">
            <div data-aos="fade-up"> Features Highlights</div>
          </div>
          <div className={Styles.fh_title_img}>
            <img src={imgText} alt="textimg" />
          </div>
        </div>
        <div className={Styles.fh_cards}>
          <ul className={Styles.fh_cards_left}>
            <div
              style={{ position: "relative", zIndex: 9 }}
              data-aos="fade-right"
              data-aos-anchor="#person_anchor"
              data-aos-delay="700"
            >
              <li className={Styles["fh_cards_left--card1"]}>
                <img src={icon1} alt="icon1" />
                <span>
                  {" "}
                  Personalized <br /> Paths
                </span>
              </li>
            </div>

            <div className="hidden">
              <div
                id="person_anchor"
                data-aos="fade-right"
                data-aos-delay="1000"
              >
                <li className={Styles["fh_cards_left--card2"]}>
                  <img src={icon2} alt="icon2" />
                  <span>
                    {" "}
                    Decentralized and <br /> Secure Examination <br /> Framework
                  </span>
                </li>
              </div>
            </div>
          </ul>
          <ul className={Styles.fh_cards_right}>
            <div className={`${Styles.fh_title} mob_hide`}>
              <div className="hidden">
                <div data-aos="fade-up"> Features Highlights</div>
              </div>
              <div className={Styles.fh_title_img}>
                <img src={imgText} alt="textimg" />
              </div>
            </div>
            <div className="hidden">
              <div data-aos="fade-left" data-aos-delay="500">
                {" "}
                <li className={Styles["fh_cards_right--card3"]}>
                  <img src={icon3} alt="icon3" />
                  <span>
                    {" "}
                    Rewarding <br /> Talent and <br /> Skills
                  </span>
                </li>
              </div>
            </div>

            <div className="hidden">
              <div data-aos="fade-left" data-aos-delay="1000">
                {" "}
                <li className={Styles["fh_cards_right--card4"]}>
                  <span>
                    Live and Interactive <br /> Examination <br /> Experience
                  </span>
                  <img src={icon4} alt="icon4" />
                </li>
              </div>
            </div>

            <div className="hidden">
              <div data-aos="fade-left" data-aos-delay="1500">
                {" "}
                <li className={Styles["fh_cards_right--card5"]}>
                  <span>
                    Fair and <br /> Transparent <br /> Evaluation
                  </span>
                  <img src={icon5} alt="icon5" />
                </li>
              </div>
            </div>
          </ul>
          <div className={Styles.fh_cards_stiker}>
            <img data-aos="fade-down" src={FA_Stickers} alt="sticker_fa" />
          </div>
        </div>

        <div className={Styles.fh_triangleFA}>
          <img
            data-aos="fade-right"
            data-aos-delay="1000"
            src={FA_Triangle}
            alt="triangle_fa"
          />
        </div>
        <div className={`${Styles.fh_triangleFA2} desk_hide`}>
          <img src={FA_Triangle2} alt="triangle_fa2" />
        </div>
      </section>
    </>
  );
};

export default FeatureHighlight;
