import "../../css/AboutUs/statsSection.css";
import StatsSectionTri from "../../assets/aboutUs/counter_triangle.svg";
const StatsSection = () => {
  return (
    <div className="stats-section">
      <div className="stats-title">
        <div className="hidden">
          <div data-aos="fade-up"> Traction</div>
        </div>

        <div class="ss_tri">
          <img src={StatsSectionTri} alt="Vector" />
        </div>
      </div>

      {/* desk */}
      <div className="stats-container mob_hide">
        <div className="stats-data">
          <div className="hidden">
            <div data-aos="fade-up">
              <div className="stats-number">10,000+</div>
              <div className="stats-description">Students</div>
            </div>
          </div>
        </div>
        <div className="stats-data">
          <div className="hidden">
            <div data-aos="fade-down">
              <div className="stats-number">100+</div>
              <div className="stats-description">Teachers</div>
            </div>
          </div>
        </div>
        <div className="stats-data">
          <div className="hidden">
            <div data-aos="fade-up">
              <div className="stats-number">50+</div>
              <div className="stats-description">Scholarship Achievers</div>
            </div>
          </div>
        </div>
        <div className="stats-data">
          <div className="hidden">
            <div data-aos="fade-down">
              <div className="stats-number">12+</div>
              <div className="stats-description">
                 Scholarship Exams <br /> Conducted
              </div>
            </div>
          </div>
        </div>
        <div className="stats-data">
          <div className="hidden">
            <div data-aos="fade-up">
              {" "}
              <div className="stats-number">₹ 300K+</div>
              <div className="stats-description">
                Scholarship Amount <br /> Rewareded
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mob */}
      <div className="stats-containerV2 desk_hide">
        <div className="sc_mobGrid3">
          <div className="stats-dataV2">
            <div className="hidden">
              <div data-aos="fade-down-right">
                {" "}
                <div className="stats-numberV2">10,000+</div>
                <div className="stats-descriptionV2">Students</div>
              </div>
            </div>
          </div>
          {/* <div className="dividerV2"></div> */}
          <div className="stats-dataV2 middle">
            <div className="hidden">
              <div data-aos="fade-up">
                <div className="stats-numberV2">100+</div>
                <div className="stats-descriptionV2">Teachers</div>
              </div>
            </div>
          </div>
          {/* <div className="dividerV2"></div> */}
          <div className="stats-dataV2">
            <div className="hidden">
              <div data-aos="fade-down-left">
                {" "}
                <div className="stats-numberV2">50+</div>
                <div className="stats-descriptionV2">Scholarship Achievers</div>
              </div>
            </div>
          </div>
        </div>

        <div className="sc_mobGrid2">
          <div className="stats-dataV2 left">
            <div className="hidden">
              <div data-aos="fade-up-right">
                <div className="stats-numberV2">12+</div>
                <div className="stats-descriptionV2">
                   Scholarship Exams <br /> Conducted
                </div>
              </div>
            </div>
          </div>
          {/* <div className="dividerV2"></div> */}
          <div className="stats-dataV2 right">
            <div className="hidden">
              <div data-aos="fade-up-left">
                <div className="stats-numberV2 ">₹ 300K+</div>
                <div className="stats-descriptionV2">
                  {" "}
                  Scholarship Amount <br /> Rewareded
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
