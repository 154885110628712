import card1 from "../../../assets/card1.png";
import card2 from "../../../assets/card2.png";
import card3 from "../../../assets/card3.png";
import { ContestAlertNameAndDate } from "../../../Utils/Contanst";

export const HomeBannerData = {
  details: {
    id: 0,
    title:
      "Making Education <span>Memorable</span>, <br/><span>Valuable</span> and <span>Truly Rewarding</span>",
    subtitle: "Revolutionize your academic journey with Scholars Adda.",
  },
  cards: [
    {
      id: 1,
      title: "Tasks",
      cardUrl: card1,
    },
    {
      id: 1,
      title: "Scholarship Exams",
      cardUrl: card2,
    },
    {
      id: 1,
      title: "Giveaways",
      cardUrl: card3,
    },
  ],
  examAlert: {
    id: 0,
    title: "Exam Alert",
    subtitle: `Gear up for the <span>${ContestAlertNameAndDate}</span>. Unleash your potential and seize the opportunity to shine! 📚`,
    label: "KNOW MORE",
    url: "/exams",
  },
};
