import React from "react";
import Styles from "./HomeCardComp.module.scss";
import HomeCard from "./HomeCard";

const HomeCardComp = ({ data }) => {
  return (
    <>
      <div className={Styles.cards}>
        <div style={{position:"relative",zIndex:"-1"}} data-aos="fade-left" data-aos-delay="400">
          <div className={Styles.cards_card1}>
            <HomeCard data={data[0]} />
          </div>
        </div>

        <div style={{position:"relative",zIndex:"2"}} data-aos="fade-up">
          <div className={Styles.cards_card2}>
            <HomeCard data={data[1]} />
          </div>
        </div>

        <div style={{position:"relative",zIndex:"-1"}} data-aos="fade-right" data-aos-delay="400">
          <div className={Styles.cards_card3}>
            <HomeCard data={data[2]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCardComp;
