import React, { useState ,useEffect} from "react";
import "../css/otp.css";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


function OTPScreen1(props) {
  const{identity} = props
  const [otp, setOtp] = useState({});

  const navigate = useNavigate();

  const deleteAccount = async (e) => {
    e.preventDefault();

    if(!Object.values(otp)?.includes("")){
      const OTP = Object.values(otp)?.join("");
      const res = await fetch(`https://dev.polarizenetwork.com/api/v1/users/deleteme?identity=${identity}&otp=${OTP}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      const data = await res.json();
      if(data?.delete_on){
        toast.success("Account deleted successfully");
        navigate("/");

      } else {
        toast.error("Something went wrong");
      }
    }
  }

  useEffect(() => {
    const form = document.getElementById("otp-form");
    const inputs = [...form.querySelectorAll("input[type=text]")];
    const submit = form.querySelector("button[type=submit]");

    const handleKeyDown = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if ((e.key === "Delete" || e.key === "Backspace") && !e.target.value) {
        const index = inputs.indexOf(e.target);
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handleInput = (e) => {
      const { target } = e;
      console.log("ss", target)
      const index = inputs.indexOf(target);
      if (target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit.focus();
        }
      }
    };

    const handleFocus = (e) => {
      e.target.select();
    };


    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!/^\d{6}$/.test(text)) {
        return;
      }
      const digits = text.split("");
      digits.forEach((digit, index) => {
        if (index < inputs.length) {
          inputs[index].value = digit;
        }
      });
      inputs[0].focus(); // Focus on the first input after pasting
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handleInput);
        input.removeEventListener("keydown", handleKeyDown);
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("paste", handlePaste);
      });
    };
  }, []);



  return (
    <>
      <main class="content">
        <div class="content-wrapper">
          <div class="form-container">
            <header class="form-header">
              <h1 class="form-title">OTP Verification</h1>
              <p class="form-description">
                Enter the 4-digit verification code that was sent to your both
                Email / Phone number.
              </p>
            </header>
            <form id="otp-form" onSubmit={deleteAccount}>
              <div class="otp-input-container">
                <input
                  type="text"
                  class="otp-input"
                  pattern="\d*"
                  maxlength="1"
                  value={otp?.one}
                  onChange={(e) => {setOtp(prev => ({...prev, one : e?.target?.value}))}}
                />
                <input type="text" class="otp-input" maxlength="1" 
                  value={otp?.two}
                  onChange={(e) => {setOtp(prev => ({...prev, two : e?.target?.value}))}}
                />
                <input type="text" class="otp-input" maxlength="1" 
                  value={otp?.three}
                  onChange={(e) => {setOtp(prev => ({...prev, three : e?.target?.value}))}}
                />
                <input type="text" class="otp-input" maxlength="1" 
                  value={otp?.four}
                  onChange={(e) => {setOtp(prev => ({...prev, four : e?.target?.value}))}}
                />
                 <input type="text" class="otp-input" maxlength="1" 
                  value={otp?.five}
                  onChange={(e) => {setOtp(prev => ({...prev, five : e?.target?.value}))}}
                />
                 <input type="text" class="otp-input" maxlength="1" 
                  value={otp?.six}
                  onChange={(e) => {setOtp(prev => ({...prev, six : e?.target?.value}))}}
                />
              </div>
              <div class="button-container">
                <button type="submit" class="verify-btn" onClick={deleteAccount}>
                  Delete My Account
                </button>
              </div>
            </form>
            <div class="form-footer">
              Didn't receive code?{" "}
              <a class="resend-link" href="#0">
                Resend
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default OTPScreen1;
