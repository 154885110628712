export const BrandHowJoinData = [
  {
    title: "Complete the Application",
    subtitle:
      "Fill out the application form on our website and provide information about yourself, including your interests, skills and why you want to become a brand ambassador for Scholars Adda.",
  },
  {
    title: "Review and Approval",
    subtitle:
      "Our team will review your application and assess your suitability for the program based on the provided information.",
  },
  {
    title: "Welcome to the Team",
    subtitle:
      "If selected, you'll receive a confirmation email welcoming you to the Scholars Adda Brand Ambassador Program, along with further instructions on getting started.",
  },
];
