import React from "react";
import Styles from "./DisclaimerV2.module.scss";
import Bgline from "../../../assets/exam/bg_line.svg";
import HTMLReactParser from "html-react-parser";

const DisclaimerV2 = ({ title, desc }) => {
  return (
    <>
      <section className={Styles.desclaimer}>
        {title && (
          <div className={Styles.desclaimer_title}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(title)}</div>
            </div>
          </div>
        )}
        {desc && (
          <div className={Styles.desclaimer_desc}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(desc)}</div>
            </div>

            {/* <div className={Styles.desclaimer_desc_img}>
              <img src={Bgline} alt="Bgline" />
            </div> */}
          </div>
        )}
      </section>
    </>
  );
};

export default DisclaimerV2;
