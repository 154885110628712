import React, { useEffect, useState } from "react";

import FooterV2 from "../components/common/FooterV2/FooterV2";
import SubscribeSection from "../components/Home/SubscribeSection";
import ExamBanner from "../components/Exams/ExamBanner/ExamBanner";
import ExamRewards from "../components/Exams/ExamRewards/ExamRewards";
import Instructions from "../components/Exams/Instructions/Instructions";
import { useParams } from "react-router";
import { ExamData } from "../../src/components/Exams/UpcomingExam/ExamData";

const ExamDetail = () => {
  const [data, setData] = useState(null);
  let { id } = useParams();
  const examNum = parseInt(id?.split("-")[1], 10) - 1;

  useEffect(() => {
    const fetchData = () => {
      if (id.includes("UE")) {
        setData(ExamData.upcoming[examNum]);
      } else if (id.includes("OE")) {
        setData(ExamData.ongoing[examNum]);
      }
    };

    fetchData();
  }, [id, examNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ExamBanner data={data} />
      <ExamRewards data={data} />
      <Instructions />
      <SubscribeSection />
      <FooterV2 />
    </>
  );
};

export default ExamDetail;
