import React, { memo } from "react";
import Styles from "./HomeBanner.module.scss";
import { PropTypes } from "prop-types";
import { HomeBannerData } from "./HomeBannerData";
import { RightArrowSvgs } from "../../Svgs/Svgs";
import HTMLReactParser from "html-react-parser";
import HomeCardComp from "./_components/HomeCardComp/HomeCardComp";
import HomeExamAlert from "./_components/HomeExamAlert/HomeExamAlert";

const HomeBanner = (props) => {
  const data = props?.data?.details;
  return (
    <>
      <section id="trigger_timer2" className={Styles.hero}>
        <div className={Styles.hero_details}>
          <div className={Styles.hero_details_title}>
            {HTMLReactParser(data?.title || "")}
          </div>
          <div className={Styles.hero_details_subtitle}>
            {HTMLReactParser(data?.subtitle || "")}
          </div>
        </div>

        <div className={Styles.hero_cardBoxx}>
          <HomeCardComp data={props?.data?.cards} />
          <HomeExamAlert data={props?.data?.examAlert} />
        </div>
      </section>
    </>
  );
};

HomeBanner.defaultProps = {
  data: HomeBannerData,
};
HomeBanner.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(HomeBanner);
