import React from "react";
import Styles from "./VideoSlider.module.scss";
import ScholarTestiText from "../../../assets/scholar-testimonial-text.svg";
import quotev2 from "../../../assets/home/q2.svg";
import HTMLReactParser from "html-react-parser";

const VideoCardV2 = ({ carousel }) => {
  return (
    <>
      <div className={Styles.vid_card}>
        <div className={Styles.vid_card_wrap}>
          <div className={Styles.vid_card_wrap_content}>
            <div className="hidden">
              <div data-aos="fade-up" data-aos-delay="500">
                {HTMLReactParser(carousel?.description)}
              </div>
            </div>

            <div className={Styles.vid_card_wrap_content_floatQ2}>
              <img data-aos="fade-down" data-aos-delay="500" src={quotev2} alt="quotev2" />
            </div>
          </div>
          <div className={Styles.vid_card_wrap_profile}>
            <div className={Styles.vid_card_wrap_profile_name}>
              <div className="hidden">
                <div data-aos="fade-up" data-aos-delay="500">
                  <div>{HTMLReactParser(carousel?.userName)}</div>
                </div>
              </div>

              <div className={Styles["vid_card_wrap_profile_name--class"]}>
                <div className="hidden">
                  <div data-aos="fade-up" data-aos-delay="500">
                    {" "}
                    {HTMLReactParser(carousel?.class)}
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.vid_card_wrap_profile_img}>
              <div className="hidden">
                <div data-aos="fade-up" data-aos-delay="500">
                  <img src={carousel?.userImage} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${Styles.vid_card_floatText} mob_hide`}>
          <div className="hidden">
            <div data-aos="fade-up" data-aos-delay="800">
              <img src={ScholarTestiText} alt="bgFloat" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCardV2;
