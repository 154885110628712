import React, { useEffect } from "react";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import ComingSoon from "../components/Blogs/ComingSoon/ComingSoon";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ComingSoon />
      <FooterV2 />
    </>
  );
};

export default Blog;
