import "../../css/AboutUs/headSection.css";

const HeaderSection = () => {
  return (
    <div id="intro" className="aboutus-header-container">
      <div className="aboutus-header">
        <div className="aboutus-title">
          <div className="hidden">
            <div data-aos="fade-up">
              Making Education Memorable, Valuable <br />
              <span> And Truly Rewarding For All</span>
            </div>
          </div>
        </div>
        {/* <div className="aboutus-subtitle">
          <div className="hidden">
            <div data-aos="fade-up">
              We're Building the world's first decentralized live assessment
              platform!
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderSection;
