import React, { memo } from "react";
import Styles from "./BrandHowJoin.module.scss";
import { PropTypes } from "prop-types";
import { BrandHowJoinData } from "./BrandHowJoinData";
import HTMLReactParser from "html-react-parser";
import { RightArrowSvgs } from "../../Svgs/Svgs";
import deskBoy from "../../../assets/ambassodor/desk_join_boy.png";
import mobBoy from "../../../assets/ambassodor/mob_join_boy.png";

const BrandHowJoin = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.BrandHowJoin}>
        <div className={Styles.BrandHowJoin_title}>
          <div className="hidden">
            <div data-aos="fade-up">How to Join</div>
          </div>
        </div>
        <div className={Styles.BrandHowJoin_subtitle}>
          <div className="hidden">
            <div data-aos="fade-up">
              To join the Scholars Adda Brand Ambassador Program, follow these
              steps
            </div>
          </div>
        </div>
        <ul className={Styles.BrandHowJoin_list}>
          {data?.map((item, index) => {
            return (
              <>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <li className={Styles.BrandHowJoin_list_item}>
                      <div className={Styles.BrandHowJoin_list_item_data}>
                        <span
                          className={Styles["BrandHowJoin_list_item_data--num"]}
                        >
                          {index + 1}
                        </span>
                        <div
                          className={
                            Styles["BrandHowJoin_list_item_data--title"]
                          }
                        >
                          {HTMLReactParser(item?.title)}
                        </div>
                      </div>
                      <div
                        className={`${
                          Styles["BrandHowJoin_list_item--subtitle"]
                        } ${
                          index === 2
                            ? Styles.widthLow
                            : index === 1
                            ? Styles.widthLow2
                            : ""
                        }`}
                      >
                        {HTMLReactParser(item?.subtitle)}
                      </div>
                    </li>
                  </div>
                </div>
              </>
            );
          })}
        </ul>
        <div className="white_btn mob_hide">
          <button
            className="buttonFlow"
            onClick={() =>
              window.open("https://zfrmz.in/LmGSDr0fTKcQbinCR17c", "_blank")
            }
          >
            <span style={{ position: "relative", zIndex: 10 }}>APPLY NOW!</span>
            <RightArrowSvgs className="rightArrow" />
          </button>
        </div>

        <picture className={Styles.BrandHowJoin_boy}>
          <source media="(max-width:767px)" srcSet={mobBoy} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={deskBoy} alt="desk_img" />
          <img src={deskBoy} alt="deskBoy" />
        </picture>
      </section>
    </>
  );
};

BrandHowJoin.defaultProps = {
  data: BrandHowJoinData,
};
BrandHowJoin.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(BrandHowJoin);
