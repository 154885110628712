import React, { memo } from "react";
import Styles from "./FooterV2.module.scss";
import { FooterV2Data } from "./FooterV2Data";
import { PropTypes } from "prop-types";
import HTMLReactParser from "html-react-parser";
import triangle from "../../../assets/home/foo_triangle.svg";
import SocialsLinks from "../SocialsLinks/SocialsLinks";

const FooterV2 = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.foo}>
        <div className={Styles.foo_content}>
          <div className={Styles.foo_content_logo}>
            <div className="hidden">
              <div data-aos="fade-up">
                <img src={data?.logo} alt="logo" />
              </div>
            </div>

            <div>
              <div className="hidden">
                <div data-aos="fade-up">{HTMLReactParser(data?.title)}</div>
              </div>
            </div>
          </div>
          <div className={Styles.foo_content_menu}>
            {data?.list.map((item, index) => (
              <div key={index} className={Styles.foo_content_menu_box}>
                <div className={Styles["foo_content_menu_box--title"]}>
                  <div className="hidden">
                    <div data-aos="fade-up">{HTMLReactParser(item?.title)}</div>
                  </div>
                </div>
                <ul className={Styles.foo_content_menu_box_links}>
                  {item?.links.map((link, i) => (
                    <div data-aos="fade-up" data-aos-delay="100">
                      <li
                        key={i}
                        className={`${Styles["foo_content_menu_box_links--link"]} fooLink`}
                      >
                        <a href={link.url}>{HTMLReactParser(link?.label)}</a>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <SocialsLinks />

        <div className={Styles.foo_tringle}>
          <img src={triangle} alt="foo-triangle" />
        </div>
        <div className={Styles.foo_copyright}>
          {HTMLReactParser(data?.copyright)}
        </div>
      </section>
    </>
  );
};

FooterV2.defaultProps = {
  data: FooterV2Data,
};
FooterV2.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(FooterV2);
