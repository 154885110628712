import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            "@media (max-width: 767px)": {
              p: 0,
            },
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ tabsItems }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider", // Media query for mobile devices
          "@media (max-width: 767px)": {
            borderBottom: "none",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "transparent",
              height: "20px",
            },
          }}
        >
          {tabsItems?.map((item, i) => (
            <Tab
              label={item?.title}
              {...a11yProps(i)}
              sx={{
                width: "179px",
                height: "40px",
                background: "white",
                margin: "0px",
                borderTop: "1px solid gray",
                borderBottom: "1px solid gray",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.40)",
                ...(i === 0 && {
                  borderLeft: "1px solid #000",
                  borderRadius: "30px 0px 0px 30px",
                }),
                // Apply right border to the last tab
                ...(i === tabsItems.length - 1 && {
                  borderRight: "1px solid #000",
                  borderRadius: "0px 30px 30px 0px",
                }),
                ...(value === i && {
                  background:
                    "linear-gradient(111deg, #FF6700 36.05%, #EB7E08 69.27%, #FFE500 94.58%)",
                  borderRadius: "30px",
                  color: "#FFF !important",
                  borderRadius: "30px",
                }),
                // Media query for mobile devices
                "@media (max-width: 767px)": {
                  width: "120px", // Adjust width for smaller screens
                  fontSize: "11px",
                  padding: "10px 4px",
                  fontWeight: "700",
                  borderTop: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabsItems?.map((item, i) => {
        return (
          <CustomTabPanel value={value} index={i} style={{ width: "100%" }}>
            {item?.component}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}
