import TeamSection from "../components/AboutUs/teamSection";
import HeaderSection from "../components/AboutUs/headerSection";
import JourneySection from "../components/AboutUs/journeySection";
import PrincipalSection from "../components/AboutUs/principleSection";
import StatsSection from "../components/AboutUs/statsSection";
import FutureSection from "../components/AboutUs/futureSection";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import { useEffect } from "react";
import SubscribeSection from "../components/Home/SubscribeSection";
import AboutBanner from "../components/AboutUs/AboutBanner/AboutBanner";
import AboutMission from "../components/AboutUs/Mission/Mission";
import AboutProblem from "../components/AboutUs/AboutProblem/AboutProblem";
import AboutSolution from "../components/AboutUs/AboutSolution/AboutSolution";
import TeamSectionV2 from "../components/AboutUs/TeamSectionV2/TeamSectionV2";
import AboutFeature from "../components/AboutUs/AboutFeature/AboutFeature";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderSection />
      <AboutBanner />
      <AboutMission />
      {/* <AboutProblem /> */}
      {/* <AboutSolution /> */}
      {/* <AboutFeature/> */}
      <StatsSection />
      {/* <JourneySection />
      <PrincipalSection /> */}
      <TeamSectionV2 />
      {/* <TeamSection /> */}
      {/* <FutureSection /> */}
      <SubscribeSection />
      <FooterV2 />
    </div>
  );
};
export default AboutUs;
