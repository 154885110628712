import React, { useEffect } from "react";
import Home from "./pages/Home";
import Layout from "./components/common/Layout";
import HowItWorks from "./pages/HowItWorks";
import "./App.css";
import "./global.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUsPage from "./pages/ContactUs";
import AccessPlatformPage from "./pages/AccessPlatformPage";
import BrandAmbassadorPage from "./pages/BrandAmbassadorPage";
import AboutUs from "./pages/AboutUs";
import AOS from "aos";
import "aos/dist/aos.css";
import Carrer from "./pages/Carrer";
import Exams from "./pages/Exams";
import ExamDetail from "./pages/ExamDetail";
import Referrals from "./pages/Referrals";
import Support from "./pages/Support";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Blog from "./pages/blog";
import TermsofService from "./pages/terms-of-service";
import PrivacyPolicy from "./pages/privacy-policy";
import DeleteMyAccount from "./pages/delete-my-account";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/how-it-works" exact element={<HowItWorks />} />
          <Route path="/contactus" exact element={<ContactUsPage />} />
          <Route path="/terms-of-service" exact element={<TermsofService />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/delete-my-account" exact element={<DeleteMyAccount />} />
          <Route
            path="/access-platform"
            exact
            element={<AccessPlatformPage />}
          />
          <Route
            path="/brand-ambassador-program"
            exact
            element={<BrandAmbassadorPage />}
          />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/career" exact element={<Carrer />} />
          <Route path="/exams" exact element={<Exams />} />
          <Route path="/blogs" exact element={<Blog />} />
          <Route path="/exams/:id" element={<ExamDetail />} />
          <Route path="/referral" exact element={<Referrals />} />
          <Route path="/support" exact element={<Support />} />
          <Route
            path="/access-platform"
            exact
            element={<AccessPlatformPage />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
