import React from "react";
import Styles from "./HoverDropdown.module.scss";
import { DownCaret } from "../../Svgs/Svgs";
import { useNavigate, useLocation } from "react-router";

const HoverDropdown = ({ title, links, titleLink, setMenuOpen }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleLinkClick = (url, offset) => {
    navigate(url);
    setMenuOpen(false);

    // After navigation, scroll to the section
    setTimeout(() => {
      const hashPart = url.split("#")[1];
      const element = document.getElementById(`${hashPart}`);
      if (element) {
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const handleTitleClick = () => {
    navigate(titleLink);
    window.scrollTo(0, 0);
    // setMenuOpen(false);
  };

  return (
    <div className={`${Styles.menu}`}>
      <div
        onClick={handleTitleClick}
        className={`${Styles.navLinkV5} ${Styles.title}  ${
          pathname === titleLink ? Styles.active : ""
        }`}
      >
        {title}
        <div className={`${Styles.caret}`}>
          <DownCaret />
        </div>
      </div>

      <ul className={Styles.dropdown}>
        {links.map((link, index) => (
          <li
            key={index}
            onClick={() => handleLinkClick(link.url, 100)}
            className={`${Styles.item}  ${Styles.navLinkV5}  ${
              pathname === link.url ? Styles.active : ""
            }`}
          >
            {link.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HoverDropdown;
