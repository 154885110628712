import React from "react";
import mdi_linkedin from "../../../assets/mdi_linkedin.svg";
import "../../../css/AboutUs/teamSection.css";
import Styles from "./TeamSectionV2.module.scss";

const TeamCard = ({ item }) => {
  return (
    <>
      <div
        className={`${
          item?.activeBorder ? Styles.activeBorder : ""
        } memWrapper`}
      >
        <div className={`${Styles.TeamCardWrap}`}>
          <div className={`${Styles.TeamCard}`}>
            <div className={`${Styles.TeamCard_image}`}>
              <img src={item?.image} alt="memberimg" />
            </div>
            <div className={`${Styles.TeamCard_user}`}>
              <div className={`${Styles.TeamCard_user_details}`}>
                <div className={`${Styles.TeamCard_user_details_name}`}>
                  {item?.name}
                </div>

                <div className={`${Styles.TeamCard_user_details_linkdin}`}>
                  {item?.linked_id && (
                    <img
                      src={mdi_linkedin}
                      alt="mdi_linkedin"
                      onClick={() => window.open(item?.linked_id, "_blank")}
                    />
                  )}
                </div>
              </div>

              <div className={`${Styles.TeamCard_user_post}`}>{item?.post}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
