import React, { useRef, useState } from "react";
import CustomAccordion from "../../common/Accordion";
import contactUsGradient from "../../../assets/contactUsGradient.png";
import { haveQuestions } from "../../../Utils/Contanst";
import "../../../css/howItWorksTabs/studentFlow.css";
import Styles from "./TeacherFlow.module.scss";

function TeacherFlow({ flowData, sideHeading }) {
  const [selectedHeading, setSelectedHeading] = useState("heading-0");

  const scrollToHeading = (index) => {
    const headingElement = document.getElementById(`heading-${index}`);
    setSelectedHeading(`heading-${index}`);
    if (headingElement) {
      const topOffset =
        headingElement.getBoundingClientRect().top + window.pageYOffset - 130;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="student_flow">
        <div className="right">
          <div className="contentBox custom_scroll">
            {sideHeading.map((item, index) => (
              <>
                <div className="StepTitleV2 blakie">{item?.title}</div>
                {item?.points?.map((ele, index) => {
                  return (
                    <>
                      <p
                        key={ele.id}
                        className="StepSideHeading"
                        style={{
                          color:
                            selectedHeading === `heading-${ele.id}`
                              ? "#FF6700"
                              : "",
                        }}
                        onClick={() => scrollToHeading(ele.id)}
                      >
                        {ele?.point}
                      </p>
                    </>
                  );
                })}
              </>
            ))}
          </div>
        </div>
        <div className="left">
          <div className="contentBox">
            {flowData.map((item, index) => (
              <>
                <div className="StepTitleV2">{item?.title}</div>
                {item?.points?.map((ele, index) => {
                  return (
                    <>
                      <div key={index} id={`heading-${ele.id}`} className="box">
                        <p className="StepHeading">{ele?.title}</p>
                        <p className="StepSubHeading">{ele?.subTitle}</p>
                      </div>
                    </>
                  );
                })}
              </>
            ))}
          </div>
        </div>

        <img
          src={contactUsGradient}
          className="sf_rightFloatTriangle mob_hide"
          alt="imgg"
        />
      </div>

      <div className="sf_HaveQuestion">
        <p className="HaveQuestion">Have Questions?</p>
        <div className="HQ_wrappper">
          {haveQuestions.map((item) => (
            <CustomAccordion title={item.title} subTitle={item.subTitle} />
          ))}
        </div>
      </div>
    </>
  );
}

export default TeacherFlow;
