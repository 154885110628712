import React, { useEffect } from "react";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import RefBanner from "../components/Referral/RefBanner/RefBanner";
import RefJoin from "../components/Referral/RefJoin/RefJoin";
import CustomAccordion from "../components/common/Accordion";
import { haveQuestionsRef } from "../Utils/Contanst";
import "../css/howItWorksTabs/studentFlow.css";
import RefRewardTable from "../components/Referral/RefRewardTable/RefRewardTable";

const Referrals = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RefBanner
        title={"Join Our Scholars Adda Referral Program"}
        desc={`Become a part of Scholars Adda's vibrant community. Also, make the most of your experience by taking part in our Referral Program. It's simple, beneficial and rewards you right away!`}
      />
      <RefRewardTable />
      <RefJoin />

      <div className="sf_HaveQuestion">
        <p className="HaveQuestion">Have Questions?</p>
        <div className="HQ_wrappper">
          {haveQuestionsRef.map((item) => (
            <CustomAccordion title={item.title} subTitle={item.subTitle} />
          ))}
        </div>
      </div>

      <FooterV2 />
    </>
  );
};

export default Referrals;
