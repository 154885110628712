export const TickMark = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ minWidth: "20px" }}
      >
        <g clip-path="url(#clip0_1766_11806)">
          <path
            d="M5.21654 9.75729L9.01654 12.924L14.0832 6.59062M9.64987 18.624C8.48548 18.624 7.3325 18.3946 6.25674 17.949C5.18099 17.5034 4.20354 16.8503 3.38019 16.027C2.55684 15.2036 1.90373 14.2262 1.45814 13.1504C1.01255 12.0747 0.783203 10.9217 0.783203 9.75729C0.783203 8.5929 1.01255 7.43992 1.45814 6.36416C1.90373 5.28841 2.55684 4.31096 3.38019 3.48761C4.20354 2.66427 5.18099 2.01115 6.25674 1.56556C7.3325 1.11997 8.48548 0.890625 9.64987 0.890625C12.0015 0.890625 14.2567 1.82479 15.9195 3.48761C17.5824 5.15043 18.5165 7.4057 18.5165 9.75729C18.5165 12.1089 17.5824 14.3641 15.9195 16.027C14.2567 17.6898 12.0015 18.624 9.64987 18.624Z"
            stroke="currentColor"
            stroke-width="0.8"
          />
        </g>
        <defs>
          <clipPath id="clip0_1766_11806">
            <rect
              width="19"
              height="19"
              fill="white"
              transform="translate(0.150391 0.257812)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
