import React from "react";

// IMAGES IMPORTS
import { ReactComponent as FacebookIcon } from "../../../assets/socials/FacebookIcon.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/socials/Youtubr_Icon.svg";
import { ReactComponent as Whatsapp } from "../../../assets/socials/Whatsapp_Icon.svg";
import { ReactComponent as Instagram } from "../../../assets/socials/InstagramIcon.svg";
import { ReactComponent as Reddit } from "../../../assets/socials/RedditIcon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/socials/TwitterIcon.svg";
import { ReactComponent as Threads } from "../../../assets/socials/ThreadsIcon.svg";
import { ReactComponent as Linkedin } from "../../../assets/socials/LinkedinIcon.svg";
import { ReactComponent as Telegram } from "../../../assets/socials/TelegramIcon.svg";
import { ReactComponent as Medium } from "../../../assets/socials/MediumIcon.svg";
import { ReactComponent as Discord } from "../../../assets/socials/DiscordIcon.svg";
import { useMediaQuery } from "../../../Utils/useMediaQuery";

const SocialsLinks = () => {
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      <div className="socials">
        {/* <Box> */}
        <FacebookIcon
          // sx={{
          //   ':hover': {
          //     fill: '#FFE500',
          //     border: '1px solid red',
          //   },
          // }}
          className="icon"
          height="25px"
          width="25px"
          cursor="pointer"
            onClick={() =>
              window.open(
                "https://www.facebook.com/scholarsadda.org",
                "_blank",
                "noopener,noreferrer"
              )
            }
        />
        {/* </Box> */}
        <YoutubeIcon
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCu-R51smeRRVHyAV7BJ4BUg ",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        {/* <Whatsapp
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://whatsapp.com/channel/0029VaDW5y70rGiO5a94FE1S",
              "_blank",
              "noopener,noreferrer"
            )
          }
        /> */}
        <Instagram
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://www.instagram.com/scholarsadda.ai",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        {/* <Reddit
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://www.reddit.com/r/Scholarsadda/",
              "_blank",
              "noopener,noreferrer"
            )
          }
        /> */}
        <TwitterIcon
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://x.com/scholarsadda_ai",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        {/* <Threads
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://www.threads.net/@scholars__adda",
              "_blank",
              "noopener,noreferrer"
            )
          }
        /> */}
        <Linkedin
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/scholarsadda/",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <Telegram
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://t.me/scholars_adda",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <Medium
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://medium.com/@scholarsadda",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />

        {/* <Discord
          className="icon"
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://discord.gg/ePWxpY8S",
              "_blank",
              "noopener,noreferrer"
            )
          }
        /> */}
      </div>
    </>
  );
};

export default SocialsLinks;
