import React from "react";
import Styles from "./RefBanner.module.scss";
import deskImg from "../../../assets/referals/desk_banner.png";
// import mobImg from "../../../assets/referals/mob_banner.png";
import vector from "../../../assets/referals/left_vector.png";
import HTMLReactParser from "html-react-parser";

const RefBanner = ({ title, desc }) => {
  return (
    <>
      <section className={Styles.RefBanner}>
        <div className={Styles.RefBanner_left}>
          <div className={Styles.RefBanner_left_top}>
            {title && (
              <div className={Styles["RefBanner_left_top--title"]}>
                <div className="hidden">
                  <div data-aos="fade-up">{HTMLReactParser(title)}</div>
                </div>
              </div>
            )}
          </div>
          {desc && (
            <div className={`${Styles["RefBanner_left--bottom"]} mob_hide`}>
              <div className="hidden">
                <div data-aos="fade-up">{HTMLReactParser(desc)}</div>
              </div>
            </div>
          )}
        </div>

        <picture data-aos="fade-up" className={Styles.RefBanner_right}>
          <source media="(max-width:767px)" srcSet={deskImg} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={deskImg} alt="desk_img" />
          <img src={deskImg} alt="deskImg" />
        </picture>

        {desc && (
          <div className={`${Styles["RefBanner_left--bottom"]} desk_hide`}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(desc)}</div>
            </div>
          </div>
        )}

        <div data-aos="fade-in" className={Styles.RefBanner_vector}>
          <img src={vector} alt="vector" />
        </div>
      </section>
    </>
  );
};

export default RefBanner;
