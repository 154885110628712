import React from "react";
import feature1 from "../assets/feature1.png";
import "../css/AccessPlatform.css";
import Mobile from "../assets/Mobile.png";
import Star from "../assets/star.png";
import Dot from "../assets/feature2.png";
import web from "../assets/web.svg";
import mobile from "../assets/mobile.svg";
import desktop from "../assets/desktop.svg";
import mobileScreen from "../assets/mobile-screen.svg";
import checkIcon from "../assets/teenyicons_tick-circle-outline.svg";
import triangle from "../assets/triangle.svg";
import polygon30 from "../assets/Polygon 30.svg";
import accessleftTriangle from "../assets/accessleftTriangle.svg";
import Vector from "../assets/Vector.svg";
import ring60 from "../assets/ring60.png";
import ringrot from "../assets/ringrot.png";
import systemTriangle from "../assets/leftSideTriangle.svg";
import blackCheck from "../assets/black-check.svg";
import Testimonials from "./common/Testimonials";
import SlickSliderTestimonials from "./common/SlickSlider";
import playStore from "../assets/bxl_play-store.png";

function getUrlVars() {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

function AccessPlatform() {
  return (
    <>
      <div style={{ padding: "62px 70px 0" }}>
        <img
          style={{
            position: "absolute",
            top: "130px",
            right: "350px",
            zIndex: 10,
          }}
          src={feature1}
          alt="imgg"
        />
        <img
          style={{
            position: "absolute",
            top: "240px",
            right: "50px",
            zIndex: 10,
          }}
          src={ringrot}
          alt="ring60"
        />
        <img
          style={{
            position: "absolute",
            top: "260px",
            right: "0px",
            zIndex: 0,
          }}
          src={Vector}
          alt="Vector"
        />
        <p className="HeroMainText">Access Scholar Adda</p>
        <p className="anyWhereText">anytime, anywhere</p>
        <img
          style={{
            position: "absolute",
            left: "130px",
            top: "300px",
            zIndex: 10,
          }}
          src={feature1}
          alt="imgg"
        />
        <p className="HeroSubText" style={{ fontSize: "22px" }}>
          Loreum ipsum dolor sit amet consectetur.
        </p>
        <div className="mobileBox">
          <div>
            <p className="mobileBoxDownloadText">
              Download our platform or use it online for an engaging and <br />{" "}
              rewarding educational experience.
            </p>
            <div style={{ padding: "20px 0", display: "flex", gap: "7px" }}>
              <button
                style={{
                  background: "#FFF",
                  color: "#000",
                  borderRadius: "50px",
                  border: "none",
                  padding: "8px 18px",
                  fontWeight: 700,
                  fontSize: "12px",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let vars = getUrlVars();
                  if (vars) {
                    setTimeout(
                      async () =>
                        console.log(
                          await window.navigator.clipboard.writeText(
                            vars["referred_by"]
                          )
                        ),
                      3000
                    );
                  }
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                    "_blank"
                  );
                }}
              >
                <img src={playStore} /> Download Now
              </button>
              {/* <button className='downloadButton'>
                                Download Now
                            </button> */}
              {/* <button className='accessOnlineButton'>
                                Access Online
                            </button> */}
            </div>
          </div>
          <div>
            <img
              src={Mobile}
              alt="imgg"
              style={{ zIndex: 10, position: "absolute", right: 40, bottom: 0 }}
            />
            <img
              src={Star}
              alt="imgg"
              style={{
                zIndex: 10,
                position: "absolute",
                left: -30,
                bottom: 30,
              }}
            />
            <img
              src={Dot}
              alt="imgg"
              style={{
                zIndex: 10,
                position: "absolute",
                left: -50,
                bottom: 15,
              }}
            />
            <img
              style={{
                position: "absolute",
                top: "-50px",
                right: "370px",
                zIndex: 10,
              }}
              src={ring60}
              alt="ringrot"
            />

            {/* <img style={{ position: 'absolute', left: '130px', top: '300px', zIndex: 10 }} src={feature1} alt="imgg" /> */}
          </div>
        </div>
        <div
          style={{
            margin: "62px 37px 50px",
            display: "flex",
            justifyContent: "space-between",
            gap: "50px",
          }}
        >
          <div>
            <div
              style={{ display: "flex", gap: "15px", justifyContent: "center" }}
            >
              <img src={desktop} alt="imgg" />
              <p className="versionHead">Desktop Version:</p>
            </div>
            <p className="versionSubText">
              Participate in quizzes, discussions and challenges to showcase
              your expertise.
            </p>
          </div>
          <div>
            <div
              style={{ display: "flex", gap: "15px", justifyContent: "center" }}
            >
              <img src={mobile} alt="imgg" />
              <p className="versionHead">Mobile/Tablet Version:</p>
            </div>
            <p className="versionSubText">
              Participate in quizzes, discussions and challenges to showcase
              your expertise.
            </p>
          </div>
          <div>
            <div
              style={{ display: "flex", gap: "15px", justifyContent: "center" }}
            >
              <img src={web} alt="imgg" />
              <p className="versionHead">Web Access:</p>
            </div>
            <p className="versionSubText">
              Participate in quizzes, discussions and challenges to showcase
              your expertise.
            </p>
          </div>
        </div>
      </div>
      <div style={{ margin: "100px 0 0", position: "relative" }}>
        <p className="platformText">Why Choose Our Platform?</p>
        <img
          src={triangle}
          alt="mobileImage"
          style={{ position: "absolute", right: "300px" }}
        />
        <img
          src={polygon30}
          alt="polygon30"
          style={{ position: "absolute", right: "0px" }}
        />
        <img
          src={accessleftTriangle}
          alt="accessleftTriangle"
          style={{ position: "absolute", left: "0px", top: "380px" }}
        />
        <div style={{ display: "flex", gap: "48px", justifyContent: "center" }}>
          <img src={mobileScreen} alt="mobileImage" />
          <div
            style={{
              margin: "95px 0 0",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormOrangeText">Personalized &nbsp;</p>
              <p className="platFormBlackText">growth</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormBlackText">Competence &nbsp;</p>
              <p className="platFormOrangeText">Hub</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormOrangeText">Interactive &nbsp;</p>
              <p className="platFormBlackText">Learning</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormBlackText">Exciting &nbsp;</p>
              <p className="platFormOrangeText">Reward</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormOrangeText">Community &nbsp;</p>
              <p className="platFormBlackText">Support</p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormBlackText">Gamification &nbsp;</p>
              <p className="platFormOrangeText"></p>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={checkIcon}
                alt="checkIcon"
                style={{ marginRight: "13px" }}
              />
              <p className="platFormOrangeText">Financial &nbsp;</p>
              <p className="platFormBlackText">Growth</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "76px 0 0" }}>
        <p className="testimonialText">Testimonials</p>
        {/* <Testimonials /> */}
        <SlickSliderTestimonials />
      </div>
      <div style={{ margin: "90px 0 0", position: "relative" }}>
        <p className="platformText">System Requirements</p>
        <img
          src={systemTriangle}
          alt="accessleftTriangle"
          style={{ position: "absolute", left: "0px", top: "0px" }}
        />
        <div className="systemParentDiv">
          <div style={{ display: "flex", gap: "30px" }}>
            <div className="systemSubDiv">
              <img src={blackCheck} alt="blackCheck" />
              <p className="systemSubText">Device Compatibility:</p>
            </div>
            <div className="systemSubDiv">
              <img src={blackCheck} alt="blackCheck" />
              <p className="systemSubText">Internet Connection:</p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "30px" }}>
            <div className="systemSubDiv">
              <img src={blackCheck} alt="blackCheck" />
              <p className="systemSubText">Browser Support:</p>
            </div>
            <div className="systemSubDiv">
              <img src={blackCheck} alt="blackCheck" />
              <p className="systemSubText">Operating System:</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <p className="platformText">Need help?</p>
        <p className="needHelpSubText">
          "Stuck or confused? Reach out to our support team for swift
          assistance. We're here to guide you through every step of your
          learning journey."
        </p>
        <button className="contactButton">Contact Support</button>
      </div>
    </>
  );
}

export default AccessPlatform;
