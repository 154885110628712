import React, { useState } from "react";
import feature1 from "../assets/feature1.png";
import "../css/ContactUs.css";
import { OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";
import OTPScreen1 from "./OTPScreen1";

function DeleteMyAc() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState({});
  const [showOTP, setShowOTP] = useState(false);
  const [identity, setIdentity] = useState("");

  const validateEmail = () => {
    if (
      !email?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg((prev) => {
        return { ...prev, email: "Please Enter Valid Email" };
      });
      return false; // Validation failed
    } else {
      setErrorMsg({ email: "" });
      return true; // Validation passed
    }
  };

  const onFirstNameBlur = () => {
    if (firstName) {
      setErrorMsg({
        ...errorMsg,
        firstName: "",
      });
      return false; // Validation failed
    } else {
      setErrorMsg((prev) => {
        return {
          ...prev,
          ...errorMsg,
          firstName: "Please enter first name",
        };
      });
      return true; // Validation passed
    }
  };

  const onLastNameBlur = () => {
    if (lastName) {
      setErrorMsg({
        ...errorMsg,
        lastName: "",
      });
      return false; // Validation failed
    } else {
      setErrorMsg((prev) => {
        return {
          ...prev,
          ...errorMsg,
          lastName: "Please enter last name",
        };
      });
      return true; // Validation passed
    }
  };

  const validateMobileNumber = () => {
    if (!mobileNumber?.match(/^\d{10}$/)) {
      setErrorMsg((prev) => {
        return { ...prev, mobileNumber: "Please Enter Valid Mobile Number" };
      });
      return false; // Validation failed
    } else {
      setErrorMsg({ mobileNumber: "" });
      return true; // Validation passed
    }
  };

  const handleSendMail = async () => {
    // Validate fields
    const isEmailValid = validateEmail();
    const isFirstNameValid = !onFirstNameBlur();
    const isLastNameValid = !onLastNameBlur();
    const isMobileNumberValid = validateMobileNumber();
  

    // Check if either email or mobile number is valid along with first name and last name
    if ((isEmailValid || isMobileNumberValid) && isFirstNameValid && isLastNameValid) {
      // Reset error messages
      setErrorMsg({});  
      if(isMobileNumberValid){
        setIdentity(mobileNumber)
          const res = await fetch(`https://dev.polarizenetwork.com/api/v1/utils/gen-sms-otp?mobile_no=${mobileNumber}`);
          const data = await res.json();
          if (data?.msg === "sms sent") {
            // Reset form fields
            setEmail("");
            setFirstName("");
            setLastName("");
            setMobileNumber("");
            // Show OTP screen
            setShowOTP(true);
            toast.success("Form Submitted Successfully.");
          } else {
            // Show error message for invalid fields
            toast.error("Something went wrong");
          }
      } else {
        if(isEmailValid){
        setIdentity(email)
          const res = await fetch(`https://dev.polarizenetwork.com/api/v1/utils/gen-email-otp?email_to=${email}`);
          const data = await res.json();
          if (data?.msg === "email sent") {
            // Reset form fields
            setEmail("");
            setFirstName("");
            setLastName("");
            setMobileNumber("");
            // Show OTP screen
            setShowOTP(true);
            toast.success("Form Submitted Successfully.");
          } else {
            // Show error message for invalid fields
            toast.error("Something went wrong");
          }
        }
      }
    } else {
      // Show error message for invalid fields
      toast.error("Please fill the required fields correctly.");
    }
  };

  return (
    <div className="cu_wrapper">
      <img className="cu_feature1Img" src={feature1} alt="imgg" />
      <p className="HeroMainText">Delete Account?</p>
      <p className="HeroSubText">You'll permanently loose your</p>
      {!showOTP ? (
        <div className="cu_rightBox cu-form-delete-wrap">
          <div className="cu_form cu-form-delete">
            <div className="cu_fn_ln">
              <div className="contactInputDiv cu_fn">
                <label className="contactInputFieldLabel">First Name</label>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="contactInputField"
                  placeholder="Enter your First Name"
                  onBlur={onFirstNameBlur}
                />
                {errorMsg.firstName && (
                  <div className="emailError">{errorMsg?.firstName}</div>
                )}
              </div>
              <div className="contactInputDiv cu_ln">
                <label className="contactInputFieldLabel">Last Name</label>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="contactInputField"
                  placeholder="Enter your Last Name"
                  onBlur={onLastNameBlur}
                />
                {errorMsg.lastName && (
                  <div className="emailError">{errorMsg?.lastName}</div>
                )}
              </div>
            </div>
            <div className="contactInputDiv">
              <label className="contactInputFieldLabel">Email</label>
              <OutlinedInput
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setEmail(e.target.value)}
                className="contactInputField"
                value={email}
                placeholder="Enter your Email"
                onBlur={validateEmail}
              />
              {errorMsg?.email && email?.length ? <div className="emailError">{errorMsg?.email}</div> : null}
            </div>
            <div className='loginSeperate'>
              <span>Or</span>
            </div>
            <div className="contactInputDiv">
              <label className="contactInputFieldLabel">Mobile Number</label>
              <OutlinedInput
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setMobileNumber(e.target.value)}
                className="contactInputField"
                value={mobileNumber}
                placeholder="Enter your Mobile Number"
                onBlur={validateMobileNumber}
              />
              {errorMsg?.mobileNumber && mobileNumber?.length ? (
                <div className="emailError">{errorMsg?.mobileNumber}</div>
              ): null}
            </div>
            <button className="submitButton" onClick={handleSendMail}>
              Send OTP
            </button>
          </div>
        </div>
      ) : (
        <OTPScreen1 identity={identity}/>
      )}
    </div>
  );
}

export default DeleteMyAc;
