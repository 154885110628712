import React, { useEffect } from "react";
import Hero from "../components/HowItWork/Hero";
import FooterV2 from "../components/common/FooterV2/FooterV2";

function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
  <>
    <Hero />
    <FooterV2/>
  </>
    

  );
}

export default HowItWorks;
