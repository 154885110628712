import React, { memo } from "react";
import Styles from "./BrandFeatures.module.scss";
import { PropTypes } from "prop-types";
import { BrandFeaturesData } from "./BrandFeaturesData";
import HTMLReactParser from "html-react-parser";

const BrandFeatures = (props) => {
  const data = props?.data;

  return (
    <>
      <section className={Styles.brandFeature}>
        {data?.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className={`${Styles.brandFeature_card} ${
                  index % 2 !== 0 ? Styles.right : ""
                }`}
              >
                <div className={Styles.brandFeature_card_icon}>
                  <div className="hidden">
                    <div data-aos="fade-down">
                      <img src={item?.icon} alt="desk_icon" />
                    </div>
                  </div>
                </div>

                <div className={Styles.brandFeature_card_data}>
                  <div
                    className={`${Styles.brandFeature_card_data_title} ${
                      index % 2 !== 0 ? Styles.right : ""
                    }`}
                  >
                    <div className="hidden">
                      <div data-aos="fade-up">
                        {HTMLReactParser(item?.title)}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${Styles.brandFeature_card_data_subtitle} ${
                      index % 2 !== 0 ? Styles.right : ""
                    }`}
                  >
                    <div className="hidden">
                      <div data-aos="fade-up">
                        {HTMLReactParser(item?.subtitle)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
};

BrandFeatures.defaultProps = {
  data: BrandFeaturesData,
};
BrandFeatures.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(BrandFeatures);
