import React, { memo } from "react";
import Styles from "./Mission.module.scss";
import missionImg from "../../../assets/aboutUs/mission.svg";
import mission_desk from "../../../assets/aboutUs/mission_img.png";
import vission_desk from "../../../assets/aboutUs/vision_img.png";
import visionImg from "../../../assets/aboutUs/vision.svg";

const AboutMission = () => {
  return (
    <>
      <section className={Styles.aboutGrid}>
        <div id="vision" className={Styles.aboutGrid_mission}>
          <div data-aos="fade-right" className={Styles.aboutGrid_misImg}>
            <img src={visionImg} alt="visionImg" />
          </div>
          <div className="hidden">
            <div className={Styles.text} data-aos="fade-up">
              We aim to facilitate learners, educators and institutions to
              thrive in a dynamic and evolving educational landscape. By
              leveraging technology, fostering collaboration and promoting
              accessibility.
            </div>
          </div>
          <div
            data-aos="fade-left"
            className={Styles.aboutGrid_mission_deskBig}
          >
            <img src={vission_desk} alt="vission_desk" />
          </div>
        </div>
        <div id="mission" className={Styles.aboutGrid_vision}>
          <div className={Styles.aboutGrid_vision_data}>
            <div
              data-aos="fade-left"
              data-aos-anchor="#left-anchor"
              className={Styles.aboutGrid_visImg}
            >
              <img src={missionImg} alt="missionImg" />
            </div>
            <div className="hidden">
              <div className={Styles.text} data-aos="fade-up">
                Our mission at Scholars Adda is to transform India's education
                ecosystem within the next five years. We aspire to revolutionize
                the way education is accessed, delivered and experienced across
                the country.
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className={Styles.aboutGrid_vision_deskBig}
          >
            <img src={mission_desk} alt="mission_desk" />
          </div>
        </div>

        {/* <picture data-aos="fade-right" className={Styles.aboutGrid_misImg}>
          <source media="(max-width:767px)" srcSet={visionImg} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={visionImg} alt="desk_img" />
          <img src={visionImg} alt="visionImg" />
        </picture> */}
        {/* <div
          data-aos="fade-left"
          data-aos-anchor="#left-anchor"
          className={Styles.aboutGrid_visImg}
        >
          <img src={missionImg} alt="missionImg" />
        </div> */}
      </section>
    </>
  );
};

export default memo(AboutMission);
