import React, { useEffect } from "react";
import Legal from "../components/Legal";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import { legalDataPrivacyPolicy } from "../Utils/Contanst";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Legal data={legalDataPrivacyPolicy} title='privacy'/>
      <FooterV2 />
    </div>
  );
}

export default PrivacyPolicy;
