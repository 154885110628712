import React from "react";
import Styles from "./BrandBenefits.module.scss";
//Images Imports
import NameImg from "../../../assets/ambassodor/ap_bene.png";
import triangleImg from "../../../assets/ambassodor/ap_tri.png";
import { RightArrowSvgs } from "../../Svgs/Svgs";

const BrandBenefits = () => {
  return (
    <>
      <section className={Styles.brandB}>
        <picture className={Styles.brandB_img1}>
          <source media="(max-width:767px)" srcSet={NameImg} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={NameImg} alt="desk_img" />
          <img
            data-aos="fade-right"
            data-aos-delay="300"
            src={NameImg}
            alt="bannerImg"
          />
        </picture>

        <div className={Styles.brandB_img2}>
          <img
            data-aos="fade-left"
            data-aos-delay="300"
            src={triangleImg}
            alt="image2"
          />
        </div>
        <div className={Styles.brandB_left}>
          <div className="hidden">
            <div data-aos="fade-right">Ambassador Benefits</div>
          </div>
        </div>
        <div data-aos="fade-left" className={Styles.brandB_right}>
          <div className={`${Styles.brandB_right_text}`}>
            The Scholars Adda Ambassador Program is an initiative designed to
            engage passionate individuals who share our vision for transforming
            education and empowering learners. As a Scholar ambassador for
            Scholars Adda, you'll play a important role in representing our
            platform, promoting our mission and advocating for the importance of
            education within your community and networks.
          </div>
        </div>
        <div className="white_btn desk_hide">
          <button
            className="buttonFlow"
            onClick={() =>
              window.open(
                "https://polarize-network.gitbook.io/scholars-adda-whitepaper/",
                "_blank"
              )
            }
          >
            <span style={{ position: "relative", zIndex: 10 }}>
              GET INVOLVED
            </span>
            <RightArrowSvgs className="rightArrow" />
          </button>
        </div>
      </section>
    </>
  );
};

export default BrandBenefits;
