import React from "react";
import "../../css/AboutUs/futureSection.css";
import future1 from "../../assets/future1.png";
import future2 from "../../assets/futur2.png";
import future3 from "../../assets/futur3.png";
import SubscribeSection from "../Home/SubscribeSection";
import feature1 from "../../assets/feature1.png";
import aboutTriangle1 from "../../assets/aboutTriangle1.svg";
import aboutTriangle2 from "../../assets/aboutTriangle2.svg";

function FutureSection() {
  return (
    <div id="features" className="futureContainer">
      <div style={{ position: "relative" }}>
        <img src={feature1} alt="imgg" className="futureImg33" />
      </div>
      <p className="headingText htextFuture">
        The Future of{" "}
        <span style={{ color: "rgba(255, 103, 0, 1)" }}>Scholar Adda</span>
      </p>
      <div className="FutureMainContainer">
        <div className="futurecard">
          <img src={future1} className="futurecardImg" />
          <p className="futurecardNumberText">01</p>
          <p className="futurecardBottomText">
            Ai:Integrating Ai for Data enhancing
          </p>
        </div>
        <div className="futurecard">
          <img src={future2} className="futurecardImg" />
          <p className="futurecardNumberText">02</p>
          <p className="futurecardBottomText">
            Metaverse : Integrating Metaverse for engaging Experience.
          </p>
        </div>
        <div className="futurecard">
          <img src={future3} className="futurecardImg" />
          <p className="futurecardNumberText">03</p>
          <p className="futurecardBottomText">
            NFTs : NFTs certification for Students
          </p>
        </div>
      </div>
      <div className="floatTriangle">
        <img
          src={aboutTriangle1}
          alt="imgg"
          className="absolute-right-bottom"
        />
        <img
          src={aboutTriangle2}
          alt="imgg"
          className="absolute_right_bottom2"
        />
      </div>
    </div>
  );
}

export default FutureSection;
