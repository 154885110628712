import React from "react";
import Clock from "../../assets/ph_clock.png";
import Calender from "../../assets/solar_calendar-outline.png";
import BlackArrow from "../../assets/blank-arrow.png";
import OrangeArrow from "../../assets/orange-arrow.png";
import EyeIcon from "../../assets/carbon_view-filled.png";
import blog1 from "../../assets/blog1.png";
import blog2 from "../../assets/blog4.png";
import blog4 from "../../assets/blog2.png";
import blog5 from "../../assets/blog5.png";
import "../../css/Blog.css";

function Blog() {
  const data = [
    {
      image: blog5,
      title: "Prevent another NEET Exam Scam with Fair Solutions Like Scholars Adda!",
      subTitle:
        "Achieving Integrity in Assessment: How Scholars Adda Promotes Transparency and Security",
      views: "281",
      time: "7 min read",
      date: "March 15, 2024",
      link: "https://medium.com/@scholarsadda/prevent-another-neet-exam-scam-with-fair-solutions-like-scholars-adda-a9a951677ee4",
    },
    {
      image: blog2,
      title: "Gamify, Learn and Earn: Navigating the Scholars Adda Ecosystem",
      subTitle:
        "Transforming Learning Milestones into Exciting Adventures with SRP Points",
      views: "281",
      time: "7 min read",
      date: "Nov 21, 2023",
      link: "/blogs",
    },
    {
      image: blog1,
      title:
        "Proof of Talent: Scholars Adda's Blockchain Revolution in Education",
      subTitle:
        "Verifying Skills, Empowering Individuals and Reshaping the Educational Landscape",
      views: "281",
      time: "7 min read",
      date: " February 23, 2024",
      link: "/blogs",
    },
    {
      image: blog4,
      title: "Engaging Quizzes: Scholars Adda's Playful Approach to Learning",
      subTitle:
        "Quizzes That Spark Curiosity and Ignite a Passion for Knowledge",
      views: "281",
      time: "7 min read",
      date: "March 15, 2024",
      link: "/blogs",
    },
  ];

  return (
    <div className="mainBlogWrap">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="headingText" style={{ margin: 0 }}>
          <div className="hidden">
            <div data-aos="fade-up">Latest Blog</div>
          </div>
        </p>
        <div
          className="mob_hide"
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => window.open("/blogs", "_blank")}
        >
          <p className="viewAllText">
            <div className="hidden">
              <div data-aos="fade-up">View All</div>
            </div>
          </p>
          <div className="hidden">
            <div data-aos="fade-up">
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  background: "#FF6700",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={BlackArrow}
                  alt="imgg"
                  style={{ zIndex: 10, height: "30px", width: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BlogMainContainer hidden">
        {data.map((item) => (
          <div
            onClick={() => window.open(item?.link, "_blank")}
            data-aos="fade-up"
            className="BlogCardContainer"
          >
            <img
              src={item?.image}
              alt="imgg"
              style={{ width: "100%", height: "225px" }}
            />
            <p className="blogCardTitle">{item?.title}</p>
            <p className="blogCardSubTitle">{item?.subTitle}</p>
            <div className="blogData">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img
                  src={EyeIcon}
                  alt="imgg"
                  style={{ height: "17px", width: "17px" }}
                />
                <p className="blogCardIconText">{item?.views}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img
                  src={Clock}
                  alt="imgg"
                  style={{ height: "17px", width: "17px" }}
                />
                <p className="blogCardIconText">{item?.time}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img
                  src={Calender}
                  alt="imgg"
                  style={{ height: "17px", width: "17px" }}
                />
                <p className="blogCardIconText">{item?.date}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "0px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => window.open(item?.link, "_blank")}
            >
              <p className="blogCardReadMoreText">Read More</p>
              <img
                src={OrangeArrow}
                alt="imgg"
                style={{ zIndex: 10, height: "30px", width: "30px" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;
