import React from "react";
import Styles from "./Instructions.module.scss";
import Bullet from "../../../assets/exam/ins_dot.svg";
import { instructionsData } from "./InstructionsData";
import { PropTypes } from "prop-types";
import HTMLReactParser from "html-react-parser";

const Instructions = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.instr}>
        <div className={Styles.instr_title}>
          <div className="hidden">
            <div data-aos="fade-up">Instructions*</div>
          </div>
        </div>

        <div className={Styles.instr_desc}>{data?.section1?.desc}</div>
        <div className="orange1">{data?.section1?.heading}</div>
        <div className={Styles.instr_subhead}>{data?.section1?.subheading}</div>
        <ul className={Styles.instr_points}>
          {data?.section1?.list.map((item, index) => {
            return (
              <>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <li key={index}>
                      <div className={Styles.title2}>
                        <img src={Bullet} alt="bullet" />
                        {HTMLReactParser(item?.title)}
                      </div>
                      <div className={Styles.subtitle2}>
                        {HTMLReactParser(item?.subtitle)}
                      </div>
                    </li>
                  </div>
                </div>
              </>
            );
          })}
        </ul>

        <div className="orange1">{data?.section1?.heading}</div>
        <div className={Styles.instr_subhead}>{data?.section1?.subheading}</div>
        <ul className={Styles.instr_points}>
          {data?.section2?.list.map((item, index) => {
            return (
              <>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <li key={index}>
                      <div className={Styles.subtitle2}>
                        <img src={Bullet} alt="bullet" />
                        {HTMLReactParser(item)}
                      </div>
                    </li>
                  </div>
                </div>
              </>
            );
          })}
        </ul>
        <span className="orange1">We wish all examinees the best of luck!</span>
      </section>
    </>
  );
};

Instructions.defaultProps = {
  data: instructionsData,
};
Instructions.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Instructions;
