import React from "react";
import Styles from "./ExamBanner.module.scss";
import HTMLReactParser from "html-react-parser";

const ExamBanner = ({ data }) => {
  return (
    <>
      <section className={Styles.exam}>
        <div className={Styles.exam_title}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(data?.title || "-")}</div>
          </div>
        </div>
        <div className="hidden">
          <div data-aos="fade-up">
            <div className={Styles.exam_hero}>
              {data?.banner && <img src={data?.banner} alt="banner" />}
            </div>
          </div>
        </div>

        <div className={Styles.exam_details}>
          <div className={Styles.exam_details_left}>
            <div className={Styles.exam_details_left_box}>
              <div className={Styles.exam_details_left_box_each}>
                <div className="hidden">
                  <div data-aos="fade-up">
                    {" "}
                    <span>Eligibility:</span>
                    {data?.details?.eligibility || "-"}
                  </div>
                </div>
              </div>
              <div className={Styles.exam_details_left_box_each}>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <span>Total Questions:</span>
                    {data?.details?.totalquestions || "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.exam_details_left_box}>
              <div className={Styles.exam_details_left_box_each}>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <span> Exam Date:</span>
                    {data?.details?.examdate || "-"}
                  </div>
                </div>
              </div>
              <div className={Styles.exam_details_left_box_each}>
                <div className="hidden">
                  <div data-aos="fade-up">
                    {" "}
                    <span>Total Marks:</span>
                    {data?.details?.totalmarks || "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.exam_details_left_box}>
              <div className={Styles.exam_details_left_box_each}>
                <div className="hidden">
                  <div data-aos="fade-up">
                    <span>Time:</span>
                    {data?.details?.examtime || "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.exam_lastTwo}>
          <div className={Styles.exam_details_left_box_each}>
            <div className="hidden">
              <div data-aos="fade-up">
                {" "}
                <span>Subjects:</span>
                {data?.details?.subjects || "-"}
              </div>
            </div>
          </div>
          <div className={Styles.exam_details_left_box_each}>
            <div className="hidden">
              <div data-aos="fade-up">
                <span>Last Date of Registration:</span>
                {data?.details?.registrationdeadline || "-"}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamBanner;
