import React, { memo } from "react";
import Styles from "./AboutBanner.module.scss";
import { PropTypes } from "prop-types";
import { AboutData } from "./AboutData";
import HTMLReactParser from "html-react-parser";

const AboutBanner = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.aboutus}>
        <div className={Styles.aboutus_left}>
          <div className={Styles["aboutus_left--title"]}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(data?.title)}</div>
            </div>
          </div>
          <div className={Styles["aboutus_left--subtitle"]}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(data?.subtitle)}</div>
            </div>
          </div>
          <div className={`${Styles["aboutus_left--desc"]} mob_hide`}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(data?.desc)}</div>
            </div>
          </div>
        </div>

        <div className={Styles["aboutus--right"]}>
          <img data-aos="fade-left" src={data?.image} alt="heroImg" />
        </div>

        <div className={`${Styles["aboutus_left--desc"]} desk_hide`}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(data?.desc)}</div>
          </div>
        </div>
      </section>
    </>
  );
};

AboutBanner.defaultProps = {
  data: AboutData,
};
AboutBanner.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(AboutBanner);
