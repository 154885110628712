import "../../../css/Home/ThirdSection.css";
import ranking from "../../../assets/new-ranking.svg";
import partyCrackers from "../../../assets/party-baloons.svg";
import HoverButton from "../../../assets/leaderHoverButton.svg";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material/Tooltip";
import Styles from "./LeaderBoard.module.scss";
import { tabNames, leaderBoardData } from "./LeaderBoardData";
import React, { useState, useEffect, useRef } from "react";
import HTMLReactParser from "html-react-parser";
import Confetti from "react-dom-confetti";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    background: "rgba(255, 255, 255, 1)",
    color: "black",
    boxShadow: "-3px 7.984395503997803px 10px 0px rgba(0, 0, 0, 0.2)",
    whiteSpace: "pre-line",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "14px",
  },
});

function LeaderBoard() {
  const [activeTab, setActiveTab] = useState(0);
  const [isCelebrating, setIsCelebrating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const handleCelebrate = () => {
    setIsCelebrating(true);
    setTimeout(() => {
      setIsCelebrating(false);
    }, 50);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    handleCelebrate();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      // const interval = setInterval(() => {
      // setActiveTab((prevTab) => (prevTab === 3 ? 0 : prevTab + 1));
      handleCelebrate();
      setActiveTab(1);
      // }, 8000);

      // return () => clearInterval(interval);
    }
  }, [isVisible]);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 100,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 1,
    width: "20px",
    height: "20px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const renderActiveTabData =
    activeTab === 0
      ? leaderBoardData.tab1
      : activeTab === 1
      ? leaderBoardData.tab2
      : activeTab === 2
      ? leaderBoardData.tab3
      : activeTab === 3
      ? leaderBoardData.tab4
      : "";

  const winner3rd =
    activeTab === 1 ? renderActiveTabData[3] : renderActiveTabData[2];

  const rearrangedData = [
    winner3rd, // 3rd object
    renderActiveTabData[0], // 1st object
    renderActiveTabData[1], // 2nd object
  ];

  return (
    <>
      <section className={Styles.lb}>
        <Confetti
          className="confettiv1"
          active={isCelebrating}
          config={confettiConfig}
        />
        <div className={Styles.lb_topbar}>
          <div className={Styles["lb_topbar--title"]}>
            <div className="hidden">
              <div data-aos="fade-up">Leaderboard</div>
            </div>
          </div>
          <div className="hidden">
            <div data-aos="fade-up">
              <ul ref={ref} className={Styles.lb_topbar_tabs}>
                {tabNames.map((tab, index) => (
                  <li
                    key={tab.id}
                    className={`${Styles.lb_topbar_tabs_tab} ${
                      activeTab === index ? Styles.active : ""
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    {tab.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="third-Section">
          {/* Winners */}
          <div className="rank-section">
            <div className="rank-container">
              {rearrangedData &&
                rearrangedData?.slice(0, 3)?.map((ele, index) => {
                  return (
                    <>
                      <div
                        data-aos="fade-up"
                        data-aos-delay={
                          ele?.rank === 1
                            ? 0
                            : ele?.rank === 2
                            ? 300
                            : ele?.rank === 3
                            ? 500
                            : 0
                        }
                        key={index}
                        className={`newrank${ele?.rank}`}
                      >
                        <div style={{ textAlign: "center" }}>
                          <CustomWidthTooltip
                            title={HTMLReactParser(ele?.college)}
                            placement="top-start"
                          >
                            <img src={HoverButton} alt="imgg" />
                          </CustomWidthTooltip>
                          <img
                            src={ele?.image}
                            alt="imgg"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="user-name">
                          {HTMLReactParser(ele?.name)}
                        </div>
                        <div className="user-amount">
                          {HTMLReactParser(ele?.amount)}
                        </div>
                        <div className="scoree">
                          {HTMLReactParser(ele?.per)}
                        </div>
                        {activeTab === 2 && ele?.rank === 1 && (
                          <div className="comin_s">Coming Soon</div>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>

            <div data-aos="fade-up" className="ranking-image-container">
              <img
                src={ranking}
                alt="imgg"
                style={{ width: "100%", zIndex: 0 }}
              />
            </div>
          </div>

          {/* Leaderboard */}
          <div className="leadboard-image-container">
            <div className="main-leader-container">
              <img src={partyCrackers} alt="imgg" className="leaderBoardImg" />
              <div data-aos="fade-up" className="leadboard-container">
                <p className="leaderBoardHeading">Achievers </p>

                <div className="overflow-scroll custom_scroll">
                  {renderActiveTabData &&
                    renderActiveTabData?.map((item, index) => (
                      <div key={index} className="student-container">
                        <div className="rank-number">{item?.rank}</div>
                        <div className="student-sub-container">
                          <div className="subcard-div1">
                            <img
                              src={item?.profile}
                              alt="img"
                              className="subcard-img"
                            />
                            <div>
                              <p className="subcard-heading">
                                {/* {activeTab === 3 ? "Name" : "Team"} */}
                                Name
                              </p>
                              <p className="subcard-subHeading">
                                {(item?.name).split(" ")[0]}
                              </p>
                            </div>
                          </div>
                          <div>
                            {item?.per && (
                              <>
                                {" "}
                                <p
                                  className="subcard-heading"
                                  style={{
                                    margin: "0 0 8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Percentile
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p
                                    className="subcard-subHeading"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {item?.per}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                          <div>
                            <p className="subcard-heading">
                              {activeTab === 3 ? "Total Referrals" : "Winnings"}
                            </p>

                            <p className="subcard-price-text">{item?.amount}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.lb_dots}>
          {tabNames.map((tab, index) => (
            <span
              key={tab.id}
              className={`${Styles.lb_dots_dot} ${
                activeTab === index ? Styles.active_dot : ""
              }`}
              onClick={() => handleTabClick(index)}
            ></span>
          ))}
        </div>
      </section>
    </>
  );
}

export default LeaderBoard;
