import React, { useEffect } from "react";
import Legal from "../components/Legal";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import { legalDataTermAndCondition } from "../Utils/Contanst";

function TermsofService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Legal data={legalDataTermAndCondition} title='terms' />
      <FooterV2 />
    </div>
  );
}

export default TermsofService;
