import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../css/SixthSectionCarousel.css";
import userImage from "../../assets/userImage.png";
import invertedComma from "../../assets/invertedComma.png";

const carouselData = [
  {
    description:
      "This is a very wonderful app and a good opportunity for students to express their talent. 💯💯",
    userName: "Naman Picha",
    class: "10th Grade",
    userImage: userImage,
  },
  {
    description:
      "This Scholars Adda app is incredibly user-friendly and intuitive, streamlining my tasks effortlessly. Highly recommended for its efficiency and convenience. I'm very happy with the exam and the rewards that they are offering for students",
    userName: "Roshni Lilhare",
    class: "8th Grade",
    userImage: userImage,
  },
  {
    description:
      "Scholars Adda app is the most helpful app this app gives the best opportunity for students to show their talent any student can test their knowledge and earn money without any registration fees this app I earned 1100 INR I will suggest this app to all students because this app is really helpful Thankyou polarize for giving this opportunity to students",
    userName: "Poorwa Sonwane",
    class: "10th Grade",
    userImage: userImage,
  },
  {
    description:
      "The mock exams in the app are like real-time sitting in the exam that helped me achieve scholarship and success.",
    userName: "Shubhangi Sabale",
    class: "9th Grade",
    userImage: userImage,
  },
  {
    description:
      "Yes, this app will provide you with scholarships you can test by mock tests and monthly exams and you just need to read your school books.",
    userName: "Darshan Rakhecha",
    class: "10th Grade",
    userImage: userImage,
  },
  {
    description:
      "Thanks to Scholars Adda's detailed analysis and practice tests, I came very close to winning the scholarship. I highly recommend this application for serious students.",
    userName: "Navnath Barh",
    class: "8th Grade",
    userImage: userImage,
  },
  {
    description:
      "Scholars Adda helped me get the scholarship through a live online exam, which made me more confident.",
    userName: "Rushikesh Sabale",
    class: "10th Grade",
    userImage: userImage,
  },
];

const finalCarouselData = [];
for (let i = 0; i < carouselData.length; i += 3) {
  finalCarouselData.push(carouselData.slice(i, i + 3));
}

const SixthSectionCarousel = () => {
  return (
    <CarouselComponent
      autoPlay
      showIndicators={false}
      interval={1500}
      infiniteLoop
      width={"100vw"}
      centerMode
      thumbWidth={50}
      showArrows
    >
      {finalCarouselData?.map((carousel) => (
        <div className="carousel-data-container">
          {carousel?.map((data) => (
            <div className="data-container">
              <div style={{ display: "flex" }}>
                <img src={invertedComma} alt="img" width={50} height={50} />
              </div>
              <div className="user-desription">{data?.description}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    textAlign: "start",
                  }}
                >
                  <div className="user-name">{data?.userName}</div>
                  <div className="user-class">{data?.class}</div>
                </div>
                <div className="user-image">
                  <img src={data?.userImage} alt="img" width={66} height={69} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </CarouselComponent>
  );
};
export default SixthSectionCarousel;
