import Dastageer from "../../../assets/TeamMembar/Dastageer Momin.jpeg";
import Manmath from "../../../assets/TeamMembar/Manmath Bellale.jpeg";
import Parshuram from "../../../assets/TeamMembar/Parshuram Thorat.png";
import Sadanad from "../../../assets/TeamMembar/Sadanad Rahangdale.png";
import Siddhanth from "../../../assets/TeamMembar/Siddhanth Rathod.png";
import Vinayak from "../../../assets/TeamMembar/Vinayak Kumbhar.jpeg";
import Mohini from "../../../assets/TeamMembar/Mohini Suryawanshi.png";

export const teamSectionV2Data = [
  {
    image: Manmath,
    name: "Manmath Bellale",
    post: "Co-Founder & COO",
    linked_id:
      "https://www.linkedin.com/in/manmath-bellale-1a23a8276?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    image: Dastageer,
    name: "Dastageer Momin",
    post: "Founder & CEO",
    linked_id:
      "https://www.linkedin.com/in/dastageermomin?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    activeBorder: true,
  },
  {
    image: Parshuram,
    name: "Parshuram Thorat",
    post: "Chief Technology Officer",
    linked_id:
      "https://www.linkedin.com/in/parshuramthorat?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    image: Sadanad,
    name: "Sadanand Rahangdale",
    post: "Community Head",
    linked_id:
      "https://www.linkedin.com/in/sadanand-rahangdale-990156276?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    image: Siddhanth,
    name: "Siddhanth Rathod",
    post: "Lead Developer",
    linked_id: "",
  },
  {
    image: Vinayak,
    name: "Vinayak Kumbhar",
    post: "Chartered Accountant",
    linked_id:
      "https://www.linkedin.com/in/vinayak-kumbhar-816087293?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    image: Mohini,
    name: "Dr. Adv. Mohini Suryawanshi",
    post: "Legal Advisor",
    linked_id:
      "https://www.linkedin.com/in/mohini-suryawanshi-4788321ab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
];
