export const RightArrowSvgs = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        className={`${className}`}
      >
        <path
          d="M5.2403 11.9448L16.8263 11.9448L12.3263 7.44476L13.7403 6.03076L20.6543 12.9448L13.7403 19.8588L12.3263 18.4448L16.8263 13.9448L5.2403 13.9448L5.2403 11.9448Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export const DownCaret = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        className={`${className}`}
      >
        <path
          d="M4.743 5.82788L0.5 1.58588L1.915 0.171875L4.743 2.99988L7.571 0.171875L8.986 1.58588L4.743 5.82788Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export const NextBtn = (props) => {
  const { className, fill, style, onClick } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        style={{ cursor: "pointer" }}
        onClick={onClick}
        className={`${className}`}
      >
        <circle cx="28" cy="28" r="28" fill={fill ? fill : "black"} />
        <path
          d="M13.2388 28.5241L35.2609 28.5241L25.1455 38.6394L27.7151 41.1909L42.1914 26.7146L27.7151 12.2383L25.1636 14.7897L35.2609 24.9051L13.2388 24.905L13.2388 28.5241Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export const PrevBtn = (props) => {
  const { className, style,fill, onClick } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        style={{ cursor: "pointer" }}
        onClick={onClick}
        className={`${className}`}
      >
        <circle
          cx="28"
          cy="28"
          r="28"
          transform="rotate(-180 28 28)"
          fill={fill ? fill : "black"}
        />
        <path
          d="M42.7612 27.4759L20.7391 27.4759L30.8545 17.3606L28.2849 14.8091L13.8086 29.2854L28.2849 43.7617L30.8364 41.2103L20.7391 31.095L42.7612 31.095L42.7612 27.4759Z"
          fill="white"
        />
      </svg>
    </>
  );
};
