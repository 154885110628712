import React from "react";
import Styles from "./SupportBanner.module.scss";
import support from "../../../assets/Support/support.svg";
import Tilt from "react-parallax-tilt";
import deskImg from "../../../assets/Support/banner_img.png";
// import mobImg from "../../../assets/referals/mob_banner.png";
import vector from "../../../assets/Support/sup_Tri.png";
import { useNavigate } from "react-router";
const SupportBanner = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className={Styles.supBanner}>
        <div className={Styles.supBanner_left}>
          <div className={Styles.supBanner_left_top}>
            <div className={Styles["supBanner_left_top--title"]}>
              <div className="hidden">
                <div data-aos="fade-up">
                  Are you facing any <br /> problem?
                </div>
              </div>
            </div>
            <div className={Styles["supBanner_left_top--subtitle"]}>
              <div className="hidden">
                <div data-aos="fade-up">
                  If you need instant support then use live chat option to reach
                  us quickly. Our support will reply as soon as possible after
                  you send us a message.
                </div>
              </div>
            </div>
          </div>
          {/* <div className={Styles["supBanner_left--bottom"]}>
            <a href="#">support@co.co</a>
            <a href="#">+88 123 456 789</a>
          </div> */}
          <div className={`${Styles.supBanner_right} mob_hide`}>
            <Tilt className="track-on-window" trackOnWindow={true}>
              <div
                // onClick={() => {
                //   navigate('help.scholarsadda.ai', '_blank')
                // }}
                onClick={() => {
                  window.open(
                    "https://help.scholarsadda.ai/portal/en/home",
                    "_blank"
                  );
                }}
                className={Styles.supBanner_right_btn}
              >
                <img
                  className={Styles.supBanner_right_btn_explore}
                  src={support}
                  alt="support now"
                />
              </div>
            </Tilt>
          </div>
        </div>

        <picture data-aos="fade-up" className={Styles.supBanner_Image}>
          <source media="(max-width:767px)" srcSet={deskImg} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={deskImg} alt="desk_img" />
          <img src={deskImg} alt="deskImg" />
        </picture>

        <div className={`${Styles.supBanner_right} desk_hide`}>
          <Tilt className="track-on-window" trackOnWindow={true}>
            <div
              // onClick={() => {
              //   window.open('#', '_blank')
              // }}
              className={Styles.supBanner_right_btn}
            >
              <img
                className={Styles.supBanner_right_btn_explore}
                src={support}
                alt="support now"
              />
            </div>
          </Tilt>
        </div>

        <div data-aos="fade-in" className={Styles.supBanner_vector}>
          <img src={vector} alt="vector" />
        </div>
      </section>
    </>
  );
};

export default SupportBanner;
