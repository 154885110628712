import React, { memo } from "react";
import Styles from "./AboutSolution.module.scss";
import { PropTypes } from "prop-types";
import { AboutSolutionData } from "./AboutSolutionData";
import HTMLReactParser from "html-react-parser";
import { TickMark } from "../../../Svgs/Svgs";
import desk_girl from "../../../assets/aboutUs/desk_girl.png";
import imgText from "../../../assets/aboutUs/solution_text.png";
import { useMediaQuery } from "../../../Utils/useMediaQuery";

const AboutSolution = (props) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const data = props?.data;
  return (
    <>
      <section id="solutions" className={Styles.au_solution}>
        <div className={Styles.au_solution_title}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(data?.title)}</div>
          </div>
          <div className={Styles.au_solution_title_img}>
            <img src={imgText} alt="textimg" />
          </div>
        </div>

        <ul className={Styles.au_solution_list}>
          {data?.list.map((item, index) => {
            return (
              <>
                <div key={index} className="hidden">
                  <div data-aos="fade-up">
                    <li className={Styles.au_solution_list_item}>
                      <TickMark />
                      <div>{HTMLReactParser(item)}</div>
                    </li>
                  </div>
                </div>
              </>
            );
          })}
        </ul>

        <div
          data-aos="fade-up-right"
          className={`${Styles.au_solution_girl} mob_hide`}
        >
          <img src={desk_girl} alt="desk_girl" />
        </div>

        {/* {isMobile ? (
          <picture data-aos="fade-left" className={Styles.au_solution_girl}>
            <source media="(max-width:767px)" srcSet={mob_girl} alt="mob_img" />
            <source
              media="(min-width:768px)"
              srcSet={desk_girl}
              alt="desk_img"
            />
            <img src={desk_girl} alt="desk_girl" />
          </picture>
        ) : (
          <picture data-aos="fade-up-right" className={Styles.au_solution_girl}>
            <source media="(max-width:767px)" srcSet={mob_girl} alt="mob_img" />
            <source
              media="(min-width:768px)"
              srcSet={desk_girl}
              alt="desk_img"
            />
            <img src={desk_girl} alt="desk_girl" />
          </picture>
        )} */}
      </section>
    </>
  );
};

AboutSolution.defaultProps = {
  data: AboutSolutionData,
};
AboutSolution.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(AboutSolution);
