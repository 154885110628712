import React from "react";
import Styles from "./RefJoin.module.scss";
import HTMLReactParser from "html-react-parser";

const RefJoinCard = ({step,stepImg,title,desc}) => {
  return (
    <>
      <div className={Styles.RefJoin_steps_card}>
        <div className={Styles.RefJoin_steps_card_left}>
          <div className="hidden">
            <div data-aos="fade-down">Step:</div>
          </div>

          <div className={Styles.RefJoin_steps_card_left_num}>
            <div className="hidden">
              <div data-aos="fade-up">{step}</div>
            </div>
            <img src={stepImg} alt="stepImg" />
          </div>
        </div>
        <div className={Styles.RefJoin_steps_card_right}>
          <div className={Styles.RefJoin_steps_card_right_subtitle}>
            <div className="hidden">
              <div data-aos="fade-up">{HTMLReactParser(title)}</div>
            </div>
          </div>
          <div className={Styles.RefJoin_steps_card_right_desc}>
            <div className="hidden">
              <div data-aos="fade-up">
              {HTMLReactParser(desc)}
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefJoinCard;
