import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/FourthSectionCarousel.css";
import mobileBackground from "../../assets/mobileBackground.png";
import screen1 from "../../assets/Screen1.svg";
import screen2 from "../../assets/Screen2.svg";
import screen3 from "../../assets/Screen3.svg";
import screen4 from "../../assets/screen-v44.png";
import howItWorksText from "../../assets/How it Works_.svg";
import howItWorksEllpse from "../../assets/Ellipse 514.svg";
import HTMLReactParser from "html-react-parser";
import { useMediaQuery } from "../../Utils/useMediaQuery";
import { NextBtn, PrevBtn } from "../Svgs/Svgs";
import BlackArrow from "../../assets/blank-arrow.png";
const carouselData = [
  {
    step: 1,
    description1: "Download <br/> Scholars Adda",
    description2: "",
    button: "Download Now",
    screen: screen1,
  },
  {
    step: 2,
    description1: "Complete Your Registration",
    description2:
      "Enter Your Mobile Number, Full Name, Date of Birth (DOB) & Gender",
    screen: screen2,
  },
  {
    step: 3,
    description1: "Choose Your Role",
    description2: "Student or Teacher",
    screen: screen3,
  },
  {
    step: 4,
    description1: "Explore, Engage & Earn",
    description2:
      "Explore the vast repository of educational content including exams, quizzes, tasks and assessments",
    screen: screen4,
  },
];

function HowItWorkSlider() {
  const isMobile = useMediaQuery("(max-width:767px)");
  // const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  //   useEffect(() => {
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         setIsVisible(entry.isIntersecting);
  //       },
  //       {
  //         root: null,
  //         rootMargin: "0px",
  //         threshold: 0.5,
  //       }
  //     );

  //     if (ref.current) {
  //       observer.observe(ref.current);
  //     }

  //     return () => {
  //       if (ref.current) {
  //         observer.unobserve(ref.current);
  //       }
  //     };
  //   }, []);

  //   useEffect(() => {
  //     let timer;
  //     if (isVisible) {
  //       timer = setTimeout(() => {
  //         setIsVisible(true);
  //       }, 3000); // Delay autoplay start for 3 seconds (3000 milliseconds)
  //     }

  //     return () => {
  //       clearTimeout(timer); // Clear the timer if component unmounts or changes
  //     };
  //   }, [isVisible]);

  // console.log('isVisible', isVisible)
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToScroll: 2,
    // autoplay: true,
    // autoplaySpeed: 10000,
    speed: 1000,
    pauseOnHover: true,
    nextArrow: <NextBtn fill="#00000080" />,
    prevArrow: <PrevBtn fill="#00000080" />,

    responsive: [
      {
        breakpoint: 2600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="howItWorksTitleDiv">
        <img src={howItWorksEllpse} alt="imgg" className="howItWorksTitleImg" />
      </div>
      <div className="howWorksWrapper">
        <div className="title_sliderr" style={{ textAlign: "center" }}>
          <img
            data-aos="fade-up"
            data-aos-delay="1000"
            src={howItWorksText}
            alt="imgg"
          />
          <p className="headingText" style={{ margin: "-30px 0 0" }}>
            <div className="hidden">
              <div ref={ref} data-aos="fade-up" data-aos-delay="1000">
                How it Works?
              </div>
            </div>
          </p>
        </div>
        <div
          className="mob_hide view_all_work"
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => window.open("/how-it-works", "_self")}
        >
          <p className="viewAllText">
            <div className="hidden">
              <div data-aos="fade-up">Know More</div>
            </div>
          </p>
          <div className="hidden">
            <div data-aos="fade-up">
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  background: "#FF6700",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={BlackArrow}
                  alt="imgg"
                  style={{ zIndex: 10, height: "30px", width: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Slider className="slider-container1" {...settings}>
        {carouselData?.map((data) => (
          // <div style={{ height: '200px', width: '200px', background: 'red' }}></div>
          <div
            className="how-it-works-main-carousel-container"
            style={{ width: "100%", display: "flex !important" }}
          >
            {/* {isMobile ? (
              <div className="carousel-card">
                <div className="step-number">Step {data?.step}</div>
                <div className="card-description-1">
                  {HTMLReactParser(data?.description1)}
                </div>
                <div className="card-description-2">
                  {HTMLReactParser(data?.description2)}
                </div>
                {data?.button && (
                  <div>
                    <button
                      className="signup-btn"
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                          "_blank"
                        )
                      }
                    >
                      {data?.button}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="mobbleScreenWrap hidden">
                <motion.div
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 1 }}
                  className="carousel-card"
                >
                  <div className="step-number">Step {data?.step}</div>
                  <div className="card-description-1">
                    {HTMLReactParser(data?.description1)}
                  </div>
                  <div className="card-description-2">
                    {HTMLReactParser(data?.description2)}
                  </div>
                  {data?.button && (
                    <div>
                      <button
                        className="signup-btn"
                        onClick={() =>
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                            "_blank"
                          )
                        }
                      >
                        {data?.button}
                      </button>
                    </div>
                  )}
                </motion.div>
              </div>
            )} */}

            <div className="carousel-card">
              <div className="step-number">Step {data?.step}</div>
              <div className="card-description-1">
                {HTMLReactParser(data?.description1)}
              </div>
              <div className="card-description-2">
                {HTMLReactParser(data?.description2)}
              </div>
              {data?.button && (
                <div>
                  <button
                    className="signup-btn"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                        "_blank"
                      )
                    }
                  >
                    {data?.button}
                  </button>
                </div>
              )}
            </div>

            <div className="mobbleScreenWrap hidden">
              <div style={{ position: "relative" }}>
                <img
                  src={mobileBackground}
                  alt="img"
                  className="mobileBackground"
                />
                <img className="screenn" src={data?.screen} alt="img" />
              </div>
              {/* {isMobile ? (
               
              ) : (
                <motion.div
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6 }}
                  style={{ position: "relative" }}
                >
                  <img
                    src={mobileBackground}
                    alt="img"
                    className="mobileBackground"
                  />
                  <img className="screenn" src={data?.screen} alt="img" />
                </motion.div>
              )} */}
            </div>
          </div>
        ))}
      </Slider>
      {/* <SlickSliderTestimonials /> */}

      <div style={{ position: "relative", marginTop: "8px" }}>
        <div
          className="desk_hide view_all_work"
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => window.open("/how-it-works", "_self")}
        >
          <p className="viewAllText">
            <div className="hidden">
              <div data-aos="fade-up">Know More</div>
            </div>
          </p>
          <div className="hidden">
            <div data-aos="fade-up">
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  background: "#FF6700",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={BlackArrow}
                  alt="imgg"
                  style={{ zIndex: 10, height: "30px", width: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorkSlider;
