import feature1 from "../../../assets/ambassodor/features/1.png";
import feature2 from "../../../assets/ambassodor/features/2.png";
import feature3 from "../../../assets/ambassodor/features/3.png";
import feature4 from "../../../assets/ambassodor/features/4.png";
import feature5 from "../../../assets/ambassodor/features/5.png";
import feature6 from "../../../assets/ambassodor/features/6.png";
import feature7 from "../../../assets/ambassodor/features/7.png";
import feature8 from "../../../assets/ambassodor/features/8.png";

export const BrandFeaturesData = [
  {
    id: 1,
    title: "Exclusive Merchandise",
    subtitle:
      "Brand ambassadors will receive limited edition Scholars Adda merchandise, including branded apparel, stationery and accessories as a token of appreciation for their dedication and efforts.",
    icon: feature1,
  },
  {
    id: 2,
    title: "Monthly Rewards and Incentives",
    subtitle:
      "Earn rewards and incentives for your contributions as an ambassador, such as gift cards, cash bonuses and Scholars Adda Points redeemable for various products and services.",
    icon: feature2,
  },
  {
    id: 3,
    title: "Networking Opportunities",
    subtitle:
      "Connect with like-minded individuals, educators and industry professionals within the Scholars Adda community. Expand your network, build valuable relationships and collaborate on exciting projects and initiatives.",
    icon: feature3,
  },
  {
    id: 4,
    title: "Professional Development",
    subtitle:
      "Enhance your leadership, communication and marketing skills through hands-on experience as a brand ambassador. Gain valuable insights into digital marketing, social media management, event planning and community engagement.",
    icon: feature4,
  },
  {
    id: 5,
    title: "Recognition and Visibility",
    subtitle:
      "Receive recognition and visibility for your contributions to the Scholars Adda community. Showcase your achievements on social media or the web and other marketing channels, boosting your personal brand credibility.",
    icon: feature5,
  },
  {
    id: 6,
    title: "Access to Exclusive Content",
    subtitle:
      "Gain access to exclusive content, resources and training materials designed to support your role as a brand ambassador. Stay informed about the latest updates, campaigns and initiatives within the Scholars Adda ecosystem.",
    icon: feature6,
  },
  {
    id: 7,
    title: "Personal Growth",
    subtitle:
      "Grow personally and professionally as you take on new challenges, overcome obstacles and achieve milestones as a brand ambassador. Develop valuable skills, build confidence and make a positive impact in the education sector.",
    icon: feature7,
  },
  {
    id: 8,
    title: "Opportunity to Make a Difference",
    subtitle:
      "Play a pivotal role in transforming education and empowering learners worldwide. Make a tangible difference in the lives of students, educators and communities by bringing them access to quality education and innovative learning solutions.",
    icon: feature8,
  },
];
