import React, { memo } from "react";
import Styles from "./VideoSlider.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";

import VideoCardV2 from "./VideoCardV2";

import { VidioCarData } from "./VidioCarData";
import { useMediaQuery } from "../../../Utils/useMediaQuery";
import { NextBtn, PrevBtn } from "../../Svgs/Svgs";

const VideoSlider = (props) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 10000,
    pauseOnHover:true,
    speed: 1000,
    nextArrow: <NextBtn  />,
    prevArrow: <PrevBtn />,
    // cssEase: "linear",
    // appendDots: (dots) => (
    //   <div className="custom-dots">
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
    // responsive: [
    //   {
    //     breakpoint: 4000, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 5,
    //     },
    //   },
    //   {
    //     breakpoint: 2500, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 4.6,
    //     },
    //   },
    //   {
    //     breakpoint: 1900, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 4,
    //     },
    //   },
    //   {
    //     breakpoint: 1624, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 3.6,
    //     },
    //   },
    //   {
    //     breakpoint: 1452, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 1224, // for screens larger than 1024px
    //     settings: {
    //       slidesToShow: 2.6,
    //     },
    //   },
    //   {
    //     breakpoint: 1045, // for screens between 768px and 1024px
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 800, // for screens smaller than 768px
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 637, // for screens smaller than 768px
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  return (
    <>
      <p className="headingText">
        <div className="hidden">
          <div data-aos="fade-up">
            Voices of Success: Hear What Our <br /> Achievers Have to Say!
          </div>
        </div>
      </p>

      <div className={Styles.vidSlider_wrapper}>
        <Slider className="slider-container1 video_slider" {...settings}>
          {props?.data?.map((carousel, index) => {
            return (
              <>
                <div key={index} className={Styles.vidSlider}>
                  <div className={Styles.vidSlider_vid}>
                    {isMobile ? (
                      <iframe
                        width="100%"
                        height="100%"
                        src={carousel?.videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <motion.iframe
                        initial={{ opacity: 0, y: 300 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.5 }}
                        width="100%"
                        height="100%"
                        src={carousel?.videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></motion.iframe>
                    )}
                  </div>

                  <div className={Styles.vidSlider_card}>
                    <VideoCardV2 carousel={carousel} />
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

VideoSlider.defaultProps = {
  data: VidioCarData,
};
VideoSlider.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(VideoSlider);
