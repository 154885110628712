import icon0 from "../../../assets/referals/reward/0.jpeg";
import icon1 from "../../../assets/referals/reward/1.png";
import icon2 from "../../../assets/referals/reward/2.png";
import icon3 from "../../../assets/referals/reward/3.png";
import icon4 from "../../../assets/referals/reward/4.png";
import icon5 from "../../../assets/referals/reward/5.png";
import icon6 from "../../../assets/referals/reward/6.png";
import icon7 from "../../../assets/referals/reward/7.png";
import icon8 from "../../../assets/referals/reward/8.png";
import icon9 from "../../../assets/referals/reward/9.png";
import icon10 from "../../../assets/referals/reward/10.png";

export const RefRewardData = [
  {
    id: 0,
    title: "School bag",
    points: "375",
    icon: icon0,
  },
  {
    id: 1,
    title: "Airdopes Hype Earbuds",
    points: "1050",
    icon: icon1,
  },
  {
    id: 2,
    title: "JBL Tune 5 10BT",
    points: "2450",
    icon: icon2,
  },
  {
    id: 3,
    title: "Diamond Smart watches",
    points: "3500",
    icon: icon3,
  },
  {
    id: 4,
    title: "Redmi Pad",
    points: "10500",
    icon: icon4,
  },
  {
    id: 5,
    title: "ASUS Chromebook CX1 <br/> (CX1400)",
    points: "15500",
    icon: icon5,
  },
  {
    id: 6,
    title: "Galaxy F54 5G",
    points: "17500",
    icon: icon6,
  },
  {
    id: 7,
    title: "Xiaomi Smart TV",
    points: "25200",
    icon: icon7,
  },
  {
    id: 8,
    title: "Asus Vivobook 14",
    points: "30500",
    icon: icon8,
  },
  {
    id: 9,
    title: "iPhone 15 Pro",
    points: "105000",
    icon: icon9,
  },
  {
    id: 10,
    title: "BMW G 310 RR Bike",
    points: "225000",
    icon: icon10,
  },
];
