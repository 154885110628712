import React from "react";
import Styles from "./CarrerBanner.module.scss";
import exploreCarrer from "../../../assets/home/explore_c.svg";
import Tilt from "react-parallax-tilt";
import StickerMob from "../../../assets/home/sticker_mob_carrer.svg";
import vector_cp from "../../../assets/home/right_cp.svg";
const CarrerBanner = () => {
  return (
    <>
      <section className={Styles.carrer}>
        <div className={Styles.carrer_left}>
          <div className={Styles.carrer_left_top}>
            <div className={Styles["carrer_left_top--title"]}>
              <div className="hidden">
                <div data-aos="fade-up"> Join Scholar’s Adda team</div>
              </div>
            </div>
            <div className={Styles["carrer_left_top--subtitle"]}>
              <div className="hidden">
                <div data-aos="fade-up"> We work hard and play harder.</div>
              </div>
            </div>
          </div>
          <div className={Styles["carrer_left--bottom"]}>
            <div className="hidden">
              <div data-aos="fade-up">
                {" "}
                and we’re always looking for talented new members that push us
                to be bolder, dream bigger and perform best.
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.carrer_right}>
          <Tilt className="track-on-window" trackOnWindow={true}>
            <div
              onClick={() => {
                window.open(
                  "https://polarize-network-career.vercel.app/",
                  "_blank"
                );
              }}
              className={Styles.carrer_right_btn}
            >
              <img
                className={Styles.carrer_right_btn_explore}
                src={exploreCarrer}
                alt="Explore now"
              />
            </div>
          </Tilt>
        </div>
        <div className={`${Styles.carrer_sticker} desk_hide`}>
          <img src={StickerMob} alt="sticker" />
        </div>
        <div className={`${Styles.carrer_vectorCp} mob_hide`}>
          <img src={vector_cp} alt="left" />
        </div>
      </section>
    </>
  );
};

export default CarrerBanner;
