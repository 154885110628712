import React, { memo } from "react";
import Styles from "./BrandCriteria.module.scss";
import { PropTypes } from "prop-types";
import { BrandCriteriaData } from "./BrandCriteriaData";
import { TickMark } from "../../../Svgs/Svgs";
import HTMLReactParser from "html-react-parser";

const BrandCriteria = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.BrandCriteria}>
        <div className={Styles.BrandCriteria_title}>
          <div className="hidden">
            <div data-aos="fade-up">
              Eligibility Criteria for the Scholar Ambassador Program
            </div>
          </div>
        </div>
        <ul className={Styles.BrandCriteria_list}>
          {data?.map((item, index) => {
            return (
              <>
                <div key={index} className="hidden">
                  <div data-aos="fade-up">
                    <li className={Styles.BrandCriteria_list_item}>
                      <TickMark />
                      <div>{HTMLReactParser(item)}</div>
                    </li>
                  </div>
                </div>
              </>
            );
          })}
        </ul>
      </section>
    </>
  );
};

BrandCriteria.defaultProps = {
  data: BrandCriteriaData,
};
BrandCriteria.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(BrandCriteria);
