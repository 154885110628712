export const instructionsData = {
  section1: {
    desc: "To ensure the integrity and fairness of the Live Online Examination, it is imperative that all participants adhere to the guidelines outlined below. Non-compliance may lead to deduction of marks & percentiles and forfeiture of eligibility for rewards.",
    heading: "Content Protection Measures:",
    subheading:
      "Unauthorised actions such as taking screenshots, recording the screen or copying exam content are strictly prohibted to protect the confidentiality of examination material.",
    list: [
      {
        title: "Examination Continuity:",
        subtitle:
          "Participants are required to remain in the examination application for its duration. Exiting the app twice will automatically end your examination attempt.",
      },
      {
        title: "Environment Monitoring:",
        subtitle:
          "The exam environment will be monitored for integrity. The presence of others or detection of suspicious activities will lead to the invalidation of your exam results.",
      },
      {
        title: "Evaluation Criteria:",
        subtitle:
          "Winners are determined based on percentiles, providing a fair assessment across all examinees.",
      },
      {
        title: "Post-Exam Requirements for Winners:",
        subtitle:
          "Winners must submit video testimonials with consent for social media sharing. Rewards will be distributed after completing all processes defined by Scholars Adda.",
      },
      {
        title: "Technical Preparedness:",
        subtitle:
          "Ensure your device is prepared with a stable internet connection. Technical issues on the participant's side are not grounds for re-examination.",
      },
      {
        title: "Identification Verification:",
        subtitle:
          "Identity verification may be required prior to the exam through a valid ID card presentation via webcam.",
      },
      {
        title: "Prohibited Tools and Resources:",
        subtitle:
          "The use of unauthorized aids such as calculators, textbooks or online resources is forbidden unless stated otherwise.",
      },
      {
        title: "Direct Communication Channels:",
        subtitle:
          "Stay updated through official channels and report technical issues promptly.",
      },
      {
        title: "Timely Submission:",
        subtitle:
          "Complete submissions within the allocated time. Late submissions will not be accepted.",
      },
    ],
  },
  section2: {
    heading: "Tie-Breaker Process for Top Scoring Students:",
    subheading:
      "In the event that top students achieve the same percentile, a multi-round tie-breaker process will be initiated to determine the final winners:",
    list: [
      "Second Round: Private Exam",
      "A private examination will be conducted exclusively for students with tied scores to distinguish their performances.",
      "Third Round : VC Interview",
      "Should ties persist following the second round, a further examination will take place through a video conference. This round aims to rigorously assess the remaining top performers under close supervision.",
      "Winner Announcement:",
      "Winners will be announced following the comprehensive evaluation process through these rounds, ensuring a fair and competitive determination of final standings.",
      "Winners will get their scholarship reward in INR in their bank account & points will get credited to their wallet after winners submit video and all the necessary info through the winners form provided by Scholars Adda.",
      "Participants are encouraged to prepare diligently and adhere strictly to these guidelines to ensure a smooth and fair examination process.",
    ],
  },
};
