import React from "react";
import Styles from "./HomeExamAlert.module.scss";
import Timer from "../../../../common/Timer";
import redirectArrow from "../../../../../assets/redirectaArrow.png";
import { useNavigate } from "react-router";
import { HOME_ALERT_TIMER_DATE } from "../../../../../Utils/Contanst";
import HTMLReactParser from "html-react-parser";

const HomeExamAlert = ({ data }) => {
  const navigate = useNavigate();
  // Get current date
  const currentDate = new Date(HOME_ALERT_TIMER_DATE);
  const targetHour = 16;
  const targetMinute = 0;

  // Create a new Date object for today at the target hour and minute
  const targetDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    targetHour,
    targetMinute
  );

  // Calculate the difference in seconds between the current time and the target time
  const timeDifferenceInSeconds = Math.floor(
    (targetDate.getTime() - currentDate.getTime()) / 1000
  );

  // Set the end time
  const endTime =
    Math.floor(currentDate.getTime() / 1000) + timeDifferenceInSeconds;

  return (
    <>
      <div className={Styles.alert}>
        <div className={Styles.alert_details}>
          <div className={Styles.alert_details_title}>
            <div className="hidden">
              <div data-aos="fade-up" data-aos-anchor="#trigger_timer2">
                {HTMLReactParser(data?.title || "")}
              </div>
            </div>
          </div>
          <div className={Styles.alert_details_subtitle}>
            <div className="hidden">
              <div data-aos="fade-up" data-aos-anchor="#trigger_timer2">
                {HTMLReactParser(data?.subtitle || "")}
              </div>
            </div>
          </div>
          <div className={`${Styles.alert_details_btn} mob_hide`}>
            <div className="hidden ">
              <div data-aos="fade-up" data-aos-anchor="#trigger_timer2">
                <button
                  className="download-btn mob_hide"
                  onClick={() => navigate(data?.url)}
                >
                  {HTMLReactParser(data?.label || "")}
                  <img
                    src={redirectArrow}
                    alt="imgg"
                    style={{ zIndex: 10, height: "16px", width: "16px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.alert_timer}>
          <Timer endTime={endTime} />
        </div>
        <div className={`${Styles.alert_details_btn} hidden desk_hide`}>
          <div data-aos="fade-up" data-aos-anchor="#trigger_timer2">
            <button
              className="download-btn desk_hide"
              onClick={() => navigate(data?.url)}
            >
              {HTMLReactParser(data?.label || "")}
              <img
                src={redirectArrow}
                alt="imgg"
                className="whiteImg"
                style={{ zIndex: 10 }}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeExamAlert;
