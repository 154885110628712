import React, { memo } from "react";
import Styles from "./BrandBanner.module.scss";
import ApBoy from "../../../assets/ambassodor/ap_boy.png";
import ApGrad from "../../../assets/ambassodor/ap_grad.png";
import { RightArrowSvgs } from "../../Svgs/Svgs";

const BrandBanner = (props) => {
  return (
    <>
      <section className={Styles.brandHero}>
        <div className={Styles.brandHero_right}>
          <div className={Styles.brandHero_right_title}>
            <div className="hidden">
              <div data-aos="fade-up"> Become an Ambassador</div>
            </div>
          </div>
          <div className={Styles.brandHero_right_subtitle}>
            <div className="hidden">
              <div data-aos="fade-up">
                Ignite Minds, Inspire Futures, Earn Rewards!
              </div>
            </div>
          </div>
          <div className="white_btn">
            <button
              className="buttonFlow"
              onClick={() =>
                window.open("https://zfrmz.in/LmGSDr0fTKcQbinCR17c", "_blank")
              }
            >
              <span style={{ position: "relative", zIndex: 10 }}>
                APPLY NOW!
              </span>
              <RightArrowSvgs className="rightArrow" />
            </button>
          </div>
        </div>
        <div data-aos="fade-right" className={Styles.brandHero_boy}>
          <img src={ApBoy} alt="ApBoy" />
        </div>
        <div className={Styles.brandHero_grad}>
          <img src={ApGrad} alt="ApGrad" />
        </div>
      </section>
    </>
  );
};

export default memo(BrandBanner);
