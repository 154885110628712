export const AboutProblemData = {
  title: "Problems <span>We Found In The</span> <br /> Traditional Exam System",
  rightToLeft: [
    "Lack of Credibility and Transparency",
    "Delayed Feedback",
    "Cheating and Examination Scandals",
    "Lack of Credibility and Transparency",
    "Delayed Feedback",
    "Cheating and Examination Scandals",
  ],
  leftToRight: [
    "Lack of Quality",
    "Inadequate Regulations",
    "Bias and Subjectivity in Evaluation",
    "Environmental Concerns",
    "Lack of Quality",
    "Inadequate Regulations",
    "Bias and Subjectivity in Evaluation",
    "Environmental Concerns",
  ],
  points: [
    "Delayed Feedback",
    "Lack of Quality",
    "Lack of Credibility and Transparency",
    "Inadequate Regulations",
    "Cheating and Examination Scandals",
    "Bias and Subjectivity in Evaluation",
    "Frequency of Paper Leaks",
    "Environmental Concerns",
    "Administrative and Governance Failures",
  ],
};
