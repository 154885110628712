import React, { memo } from "react";
import Styles from "./AboutProblem.module.scss";
import { PropTypes } from "prop-types";
import { AboutProblemData } from "./AboutProblemData";
import Marquee from "react-marquee-slider";
import HTMLReactParser from "html-react-parser";
import desk_boy from "../../../assets/aboutUs/boy.png";
import mob_boy from "../../../assets/aboutUs/boy_mob.png";

const AboutProblem = (props) => {
  const data = props?.data;
  return (
    <>
      {/* <div className={Styles.imgWrapAp}>
        <picture
          data-aos="fade-right"
          data-aos-anchor="#left-anchor"
          className={Styles.imgWrapAp_boy}
        >
          <source media="(max-width:767px)" srcSet={mob_boy} alt="mob_img" />
          <source media="(min-width:768px)" srcSet={desk_boy} alt="desk_img" />
          <img src={desk_boy} alt="desk_boy" />
        </picture>
      </div> */}
      <section id="problems" className={Styles.ap}>
        <div className={Styles.ap_title}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(data.title)}</div>
          </div>
        </div>

        <div className={Styles.ap_marquee}>
          {data?.points?.map((item, index) => {
            return (
              <>
                <div
                  data-aos="fade-up"
                  key={index}
                  className={Styles.ap_marquee_item}
                >
                  {HTMLReactParser(item)}
                </div>
              </>
            );
          })}
        </div>

        {/* <div className={Styles.ap_marquee}>
          <Marquee velocity={50} minScale={0.5} resetAfterTries={200}>
            {data?.rightToLeft.map((item, index) => {
              return (
                <>
                  <div key={index} className={Styles.ap_marquee_item}>
                    {HTMLReactParser(item)}
                  </div>
                </>
              );
            })}
          </Marquee>

          <Marquee
            direction="ltr"
            velocity={50}
            minScale={0.5}
            resetAfterTries={200}
          >
            {data?.leftToRight.map((item, index) => {
              return (
                <>
                  <div key={index} className={Styles.ap_marquee_item}>
                    {HTMLReactParser(item)}
                  </div>
                </>
              );
            })}
          </Marquee>
        </div> */}
      </section>
    </>
  );
};

AboutProblem.defaultProps = {
  data: AboutProblemData,
};
AboutProblem.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(AboutProblem);
