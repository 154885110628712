import React, { useState } from "react";
import "../../css/Home/SixthSection.css";
import { OutlinedInput } from "@mui/material";
import Footer from "../Footer";
import CheckIcon from "@mui/icons-material/Check";

function SubscribeSection() {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSendMail = async () => {
    try {
      if (!validateEmail(email)) {
        setErrorMsg("Please Enter Valid Email");
        return;
      }
      setErrorMsg("");
      const requestOptions = {
        method: "POST",
        // headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({
        //   "email": email
        // })
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/utils/add-mail-to-mailing-list?email=${email}`,
        requestOptions
      );
      if (res.status === 200) {
        setEmail("");
        setIsSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div>
        <p className="headingText head2">
          <div className="hidden">
            <div data-aos="fade-up">Subscribe to Our Newsletter</div>
          </div>
        </p>
        <p className="subscribeSubText">
          <div className="hidden">
            <div data-aos="fade-up">
              Explore our educational resources, master new skills and expand
              your knowledge.
            </div>
          </div>
        </p>
        <div className="hidden">
          <div data-aos="fade-up">
            {" "}
            <div className="SubscribeInputDiv">
              <div>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  className="SubscribeInput"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errorMsg && <div className="emailError">{errorMsg}</div>}
              </div>
              <button className="downloadPdfButton" onClick={handleSendMail}>
                {isSuccess ? (
                  <>
                    <CheckIcon /> Subscribed{" "}
                  </>
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="space_80"></div>
      <Footer />
    </>
  );
}

export default SubscribeSection;
