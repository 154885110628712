import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "../../css/HowItWorks.css";

export default function CustomAccordion({ title, subTitle }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
          "& .MuiAccordionSummary-root": {
            padding: "0 35px",
          },
          "& .MuiAccordionSummary-content": {
            margin: "30px 0px",
          },
          "& .MuiAccordionDetails-root": {
            padding: "8px 60px 20px",
          },
          // Media query for mobile devices
          "@media (max-width: 767px)": {
            "& .MuiAccordionSummary-root": {
              padding: "0",
            },
            "& .MuiAccordionDetails-root": {
              padding: "8px 16px 20px",
            },
            "& .MuiAccordionSummary-content": {
              margin: "20px 0",
            },
          },
        }}
        style={{
          boxShadow: "none",
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.20)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "27.514px",
              /* 125.066% */
              letterSpacing: "0.44px",
              // Media query for mobile devices
              "@media (max-width: 767px)": {
                fontSize: "16px",
              },
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.40)",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "27.514px" /* 152.858% */,
              letterSpacing: "0.36px",
              // Media query for mobile devices
              "@media (max-width: 767px)": {
                fontSize: "14px",
                lineHeight: "21px",
              },
            }}
          >
            {subTitle}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
