import React, { useState } from "react";
import feature1 from "../assets/feature1.png";
import lock from "../assets/lock.svg";
import { legalSideHeading } from "../Utils/Contanst";
import "../css/Legal.css";
import { useNavigate } from "react-router";

function Legal({ data, title }) {
  const [selectedHeading, setSelectedHeading] = useState(0);
  const navigate = useNavigate();

  const scrollToHeading = (index) => {
    const headingElement = document.getElementById(`heading-${index}`);
    setSelectedHeading(index);
    if (headingElement) {
      headingElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="legalPageContainer">
      <img className="lp_feature1" src={feature1} alt="imgg" />
      <p className="HeroMainText">
        Scholar Adda’s legal <br /> framework
      </p>
      <p className="HeroSubText lp_sub">
        Features that Empower, Engage and ensure Safety
      </p>
      <img className="lp_feature2" src={feature1} alt="imgg" />
      <div className="lpg_wrapper">
        <div className="legalSideItemsContainer2">
          <div className="legalSideItems custom_scroll">
            {legalSideHeading.map((item, index) => (
              <p
                key={index}
                className="StepSideHeading"
                // style={{
                //   color:
                //     `heading-${selectedHeading}` === `heading-${index}`
                //       ? "#FF6700"
                //       : "",
                // }}
                // onClick={() => index <= 1 && scrollToHeading(index)}
                style={{
                  color:
                    (title === "terms" && index === 0) ||
                    (title === "privacy" && index === 1)
                      ? "#FF6700"
                      : "",
                }}
                onClick={() => {
                  if (index === 0) {
                    navigate("/terms-of-service");
                  } else if (index === 1) {
                    navigate("/privacy-policy");
                  }
                }}
              >
                {index > 1 && <img src={lock} alt="lock" />} {item}
              </p>
            ))}
          </div>
        </div>
        <div className="legalSideItemsContainer1">
          <div className="lpg_content_box">
            <div id={`heading`} className="lpg_box">
              <p className="StepHeading">{data?.[selectedHeading]?.title}</p>
              <div>
                {data?.[selectedHeading]?.subTitle
                  ?.split("*")
                  ?.map((word, index) => {
                    console.log(word);
                    const shouldBold =
                      word.startsWith("(") && word.endsWith(")");
                    console.log(shouldBold);
                    const actualWord = shouldBold ? word.slice(1, -1) : word;
                    // Apply bold style if necessary
                    return shouldBold ? (
                      <strong className="legalSubHeadingBold" key={index}>
                        {actualWord}{" "}
                      </strong>
                    ) : (
                      <p className="StepSubHeading lpg_sub11">{actualWord} </p>
                    );
                  })}
              </div>
              {/* <p className="lastUpdateText" style={{ fontSize: "16px" }}>
                {data?.[selectedHeading]?.updated}
              </p>
              <div style={{ display: "flex", gap: "7px" }}>
                <button className="downloadLegalDocumentButton">
                  <a
                    href={data?.[selectedHeading]?.link}
                    download="document.docx"
                  >
                    Download PDF
                  </a>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;
