import React, { memo } from "react";
import Styles from "./TeamSectionV2.module.scss";
import { PropTypes } from "prop-types";
import { teamSectionV2Data } from "./TeamSectionV2Data";
import "../../../css/AboutUs/teamSection.css";
import BlackArrow from "../../../assets/blank-arrow.png";
import TeamCard from "./TeamCard";
import QuoteAbout from "../../../assets/aboutUs/quote_au.svg";

const TeamSectionV2 = (props) => {
  const data = props?.data;

  return (
    <>
      <section id="au_team" className={Styles.auTeam}>
        <div className="team-section-title">
          <div className="teamHead">
            <div className="hidden">
              <div data-aos="fade-up">
                The Minds Behind{" "}
                <span className="prime_color">Scholar Adda</span>
              </div>
            </div>
          </div>
          <div
            className="mob_hide"
            style={{
              display: "flex",
              gap: "10px",
              width: "25%",
              justifyContent: "end",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                "https://polarize-network-career.vercel.app/",
                "_blank"
              )
            }
          >
            <div className="hidden">
              <div data-aos="fade-up">
                <p className="JoinTeamText">Join our Team</p>
              </div>
            </div>
            <div className="hidden">
              <div data-aos="fade-up">
                {" "}
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    background: "#FF6700",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={BlackArrow}
                    alt="imgg"
                    style={{ zIndex: 10, height: "30px", width: "30px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*desk  FOUNDERS */}
        <div className={`${Styles.auTeam_founders} mob_hide`}>
          {data?.slice(0, 3).map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index === 1 ? "0" : "300"}
                >
                  <TeamCard item={item} key={index} />
                </div>
              </>
            );
          })}
        </div>

        {/* Mobile FOUNDERS */}
        <div className={`${Styles.auTeam_founders} desk_hide`}>
          <div className={Styles.auTeam_founders_mobGrid1}>
            {data?.slice(1, 2).map((item, index) => {
              return (
                <>
                  <TeamCard item={item} key={index} />
                </>
              );
            })}
          </div>
          <div className={Styles.auTeam_founders_mobGrid2}>
            {data?.slice(0, 1).map((item, index) => {
              return (
                <>
                  <TeamCard item={item} key={index} />
                </>
              );
            })}
            {data?.slice(2, 3).map((item, index) => {
              return (
                <>
                  <TeamCard item={item} key={index} />
                </>
              );
            })}
          </div>
        </div>

        {/* desc */}
        <div className={Styles.auTeam_info}>
          <div className="hidden">
            <div data-aos="fade-up">
              Together, let's create a future filled with innovation, impact and
              endless possibilities at Scholars Adda.
            </div>
          </div>

          <div className={Styles.auTeam_info_img}>
            <div className="hidden">
              <div data-aos="fade-down">
                <img src={QuoteAbout} alt="QuoteAbout" />
              </div>
            </div>
          </div>
        </div>

        {/* MEMBERS */}
        <div className={Styles.auTeam_members}>
          {data?.slice(3).map((item, index) => {
            return (
              <>
                <div className="hidden">
                  <div data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}>
                    <TeamCard item={item} key={index} />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

TeamSectionV2.defaultProps = {
  data: teamSectionV2Data,
};
TeamSectionV2.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(TeamSectionV2);
