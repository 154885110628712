import React from "react";
import Styles from "./ComingSoon.module.scss";
import imgText from "../../../assets/Coming Soon.png";

const ComingSoon = () => {
  return (
    <>
      <section className={Styles.soon}>
        <div className={Styles.soon_title}>
          <div className="hidden">
            <div data-aos="fade-up">Coming Soon</div>
          </div>
          <div className={Styles.soon_title_img}>
            <img src={imgText} alt="textimg" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
