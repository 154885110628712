import React, { useEffect } from "react";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import BrandJoinUs from "../components/Ambassador/BrandJoinUs/BrandJoinUs";
import BrandBanner from "../components/Ambassador/BrandBanner/BrandBanner";
import BrandBenefits from "../components/Ambassador/BrandBenefits/BrandBenefits";
import BrandFeatures from "../components/Ambassador/BrandFeatures/BrandFeatures";
import BrandWhatDo from "../components/Ambassador/BrandWhatDo/BrandWhatDo";
import BrandCriteria from "../components/Ambassador/BrandCriteria/BrandCriteria";
import BrandHowJoin from "../components/Ambassador/BrandHowJoin/BrandHowJoin";
import Disclaimer from "../components/Exams/Disclaimer/Disclaimer";
import DisclaimerV2 from "../components/Ambassador/DisclaimerV2/DisclaimerV2";

function BrandAmbassadorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BrandBanner />
      <BrandBenefits />
      <BrandFeatures />
      <BrandWhatDo />
      <BrandCriteria />
      <BrandHowJoin />
      <BrandJoinUs />
      <DisclaimerV2
        desc={`If you have any questions about the brand ambassador program or would like more information, please contact us at <a href="#">ambassador@scholarsadda.ai  or 1800 309 2838</a>.We look forward to welcoming you to the Scholars Adda community and working together to make a positive impact in the world of education!
              `}
      />
      <FooterV2 />
    </div>
  );
}

export default BrandAmbassadorPage;
