import rankPerson1 from "../../../assets/winners/w1_profile.svg";
import rankPerson2 from "../../../assets/winners/Savitra_Waghe-person.svg";
import rankPerson3 from "../../../assets/winners/w3_profile.svg";

export const VidioCarData = [
  {
    description:
      "The examination process was very straightforward. The exam provided knowledge about the life of Chhatrapati Shivaji Maharaj. I learned a lot about the life of Chhatrapati Shivaji Maharaj through this test.",
    userName: "Akaanksha Sonawane",
    class: "Below 10th",
    userImage: rankPerson1,
    videoUrl: "https://www.youtube.com/embed/Z6Ns0K9QdQE?si=TPS3NsYt0bxJNX6C",
  },
  {
    description:
      "It is a very wonderful app & good opportunities for students to express their talent. I hope that now students can score nice marks in their school exams. Thanks, scholars Adda Team",
    userName: "Savitra Waghe",
    class: "Graduate",
    userImage: rankPerson2,
    videoUrl: "https://www.youtube.com/embed/X5P1JNVrVbE?si=ffRgu17xww2l8Vh2",
  },
  {
    description:
      "It was an amazing exam to test our general knowledge of social science and current affairs news. It helps me to recapture my old knowledge. I prefer that every individual or a student must take this exam.",
    userName: "Gaurav Sadgir",
    class: "Below 10th",
    userImage: rankPerson3,
    videoUrl: "https://www.youtube.com/embed/OjzZVMkRWwQ?si=P65_BPX877_2z2nK",
  },
];
