import React, { useEffect } from "react";
import CarrerBanner from "../components/Carrer/Hero/CarrerBanner";
import GridContent from "../components/Carrer/GridContent/GridContent";
import FooterV2 from "../components/common/FooterV2/FooterV2";

const Carrer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CarrerBanner />
      {/* <GridContent /> */}
      <FooterV2 />
    </>
  );
};

export default Carrer;
