import React from "react";
import { PropTypes } from "prop-types";
import { ExamData } from "./ExamData";
import { useState } from "react";
import Styles from "./UpcomingExam.module.scss";
import notifyBtn from "../../../assets/exam/notify.svg";
import HTMLReactParser from "html-react-parser";
import upTri from "../../../assets/exam/up_tri.svg";
import { useNavigate } from "react-router";
import dummyProfile from "../../../assets/dummy-pro.jpg";

const UpcomingExam = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  // UPCOMING EXAM CARDS
  const getUpComingCards = () => {
    return props?.data?.upcoming.map((item, index) => {
      // const pageId = index + 1 < 10 ? `UE-${index + 1}` : `UE-${index + 1}`;
      const pageId = `UE-${index + 1}`;
      return (
        <>
          <div className="hidden">
            <div data-aos="fade-up">
              <ul
                key={index}
                onClick={() => navigate(`/exams/${pageId}`)}
                className={`${Styles.upcoming_content_upCard} `}
              >
                <li className={Styles.first}>
                  <div className={Styles.upcoming_content_upCard_title}>
                    {HTMLReactParser(item?.title)}
                  </div>
                  <div
                    className={`${Styles.upcoming_content_upCard_date} ${Styles.datee}`}
                  >
                    {HTMLReactParser(item?.details?.examdate)}
                  </div>
                </li>
                <li className={Styles.upcoming_content_upCard_subtitle}>
                  <div>{HTMLReactParser(item?.details?.duration)} minutes</div>

                  <div>{HTMLReactParser(item?.details?.totalmarks)} marks</div>
                </li>
                <li className={Styles.last}>
                  <button
                    // onClick={() => navigate(`/exams/${pageId}`)}
                    className={Styles.upcoming_content_upCard_button}
                  >
                    <img src={notifyBtn} alt="notifyBtn" />
                    {HTMLReactParser(item?.label)}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    });
  };

  // ONGOING EXAM CARDS
  const getOngoingCards = () => {
    return props?.data?.ongoing.map((item, index) => {
      // const pageId = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`;
      const pageId = `OE-${index + 1}`;
      return (
        <>
          <div className="hidden">
            <div data-aos="fade-up">
              <ul
                key={index}
                onClick={() => navigate(`/exams/${pageId}`)}
                className={`${Styles.upcoming_content_upCard} `}
              >
                <li className={Styles.first}>
                  <div className={Styles.upcoming_content_upCard_title}>
                    {HTMLReactParser(item?.title)}
                  </div>
                  <div
                    className={`${Styles.upcoming_content_upCard_date} ${Styles.datee}`}
                  >
                    {HTMLReactParser(item?.details?.examdate)}
                  </div>
                </li>
                <li className={Styles.upcoming_content_upCard_subtitle}>
                  <div>{HTMLReactParser(item?.details?.duration)} minutes</div>

                  <div>{HTMLReactParser(item?.details?.totalmarks)} marks</div>
                </li>
                <li className={Styles.last}>
                  <button
                    // onClick={() => navigate(`/exams/${pageId}`)}
                    className={Styles.upcoming_content_upCard_button}
                  >
                    <img src={notifyBtn} alt="notifyBtn" />
                    {HTMLReactParser(item?.label)}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    });
  };

  // PREVIOUS EXAM CARDS
  const getPrevCards = () => {
    return props?.data.previous.map((item, index) => {
      return (
        <>
          <div className="hidden">
            <div data-aos="fade-up">
              <ul
                key={index}
                className={`${Styles.upcoming_content_prevCard} `}
              >
                <li className={Styles.first}>
                  <div className={Styles.upcoming_content_prevCard_title}>
                    {HTMLReactParser(item?.title)}
                  </div>
                  <div
                    className={`${Styles.upcoming_content_prevCard_date} ${Styles.datee}`}
                  >
                    {HTMLReactParser(item?.date)}
                  </div>
                </li>
                <li className={Styles.upcoming_content_prevCard_subtitle}>
                  Winners
                </li>
                <li className={Styles.last}>
                  {item?.list.map((ele, index) => {
                    return (
                      <>
                        <div key={index} className={Styles.last_box}>
                          <span>
                            {index === 0
                              ? "1st"
                              : index === 1
                              ? "2nd"
                              : index === 2
                              ? "3rd"
                              : ""}
                          </span>
                          {ele?.profile ? (
                            <img src={ele?.profile} alt="profile" />
                          ) : (
                            <img src={dummyProfile} alt="profile" />
                          )}
                          {ele?.winner && (
                            <div> {HTMLReactParser(ele?.winner)}</div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    });
  };

  // Active EXAM CARDS
  const getActiveCards = () => {
    if (activeTab === 0) {
      return getUpComingCards();
    }
    if (activeTab === 1) {
      return getOngoingCards();
    }
    return getPrevCards();
  };

  return (
    <>
      <section id="upcoming_exam" className={Styles.upcoming}>
        <div className={Styles.upcoming_tabs}>
          <div
            onClick={() => {
              setActiveTab(1);
            }}
            className={`${Styles.upcoming_tabs_tab} ${
              activeTab === 1 ? Styles.active : ""
            }`}
          >
            <div className="hidden">
              <div data-aos="fade-up">Ongoing Exams</div>
            </div>
          </div>
          <div
            onClick={() => {
              setActiveTab(0);
            }}
            className={`${Styles.upcoming_tabs_tab} ${
              activeTab === 0 ? Styles.active : ""
            }`}
          >
            <div className="hidden">
              <div data-aos="fade-up">Upcoming Exams</div>
            </div>
          </div>
          <div
            onClick={() => {
              setActiveTab(2);
            }}
            className={`${Styles.upcoming_tabs_tab} ${
              activeTab === 2 ? Styles.active : ""
            }`}
          >
            <div className="hidden">
              <div data-aos="fade-up">Previous Exams</div>
            </div>
          </div>
        </div>

        <div className={Styles.upcoming_content}>{getActiveCards()}</div>

        <div className={Styles.upcoming_upTri}>
          <img src={upTri} alt="upTri" />
        </div>
      </section>
    </>
  );
};

UpcomingExam.defaultProps = {
  data: ExamData,
};
UpcomingExam.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UpcomingExam;
