export const BrandCriteriaData = [
  "<span>Passion for Education</span> Applicants must demonstrate a genuine passion for education and a strong desire to make a positive impact in the field of learning.",
  "<span>Active Engagement</span>  Brand ambassadors should be active and engaged members of their community, school or college, with a willingness to promote Scholars Adda and its mission to their peers and networks.",
  "<span>Effective Communication Skills</span> Strong communication skills, both verbal and written, are essential for effectively promoting Scholars Adda and engaging with fellow learners.",
  "<span>Social Media Presence</span>  Brand ambassadors should have an active presence & followers on social media platforms (e.g., Instagram, Facebook, Twitter, LinkedIn) and be willing to create and share content about Scholars Adda.",
  "<span>Creativity and Initiative</span>  We're looking for individuals who are creative, proactive and innovative in their approach to promoting Scholars Adda and generating engagement within their community.",
  "<span>Enrollment in an Educational Institution</span> Applicants must be enrolled in a school, college or educational institution at the time of application to be eligible for the Brand Ambassador Program.",
  "<span>Commitment and Reliability</span> Brand ambassadors should be committed to fulfilling their responsibilities and representing Scholars Adda in a positive and professional manner at all times.",
  "<span>Alignment with Scholars Adda's Values</span>  Applicants should align with Scholars Adda's values and mission of empowering learners through education, diversity and innovation.",
];
