import React from "react";
import Styles from "./RefRewardTable.module.scss";
import star from "../../../assets/referals/star.svg";
import HTMLReactParser from "html-react-parser";

const RefRow = ({ title, points, icon, index }) => {
  return (
    <>
      <div className={Styles.refRow}>
        <div className={Styles.refRow_num}>{`${index + 1}.`}</div>
        <div className={Styles.refRow_data}>
          <div className={Styles.refRow_data_img}>
            <img src={icon} alt="REWARD_IMG" />
          </div>
          <div className={Styles.refRow_data_title}>
            {HTMLReactParser(title)}
          </div>
        </div>
        <div className={Styles.refRow_point}>
          <div className={Styles.refRow_point_star}>
            <img src={star} alt="star" />
          </div>
          <div>{HTMLReactParser(points)}</div>
        </div>
      </div>
    </>
  );
};

export default RefRow;
