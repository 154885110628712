import React, { useEffect } from "react";
import Features from "../components/Home/FifthSection";
import Blog from "../components/Home/Blog";
import Footer from "../components/Footer";
import FirstSection from "../components/Home/FirstSection";
import SecondSection from "../components/Home/SecondSection";
import ThirdSection from "../components/Home/ThirdSection";
import FourthSection from "../components/Home/FourthSection";
import FifthSection from "../components/Home/FifthSection";
import SixthSection from "../components/Home/SixthSection";
import LeaderBoard from "../components/Home/LeaderBoard/LeaderBoard";
import SlickSliderTestimonials from "../components/common/SlickSlider";
import SubscribeSection from "../components/Home/SubscribeSection";
import HowItWorkSlider from "../components/Home/HowItWorkSlider";
import WhatIsScholarAdda from "../components/Home/WhatIsScholarAdda/WhatIsScholarAdda";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import VideoSlider from "../components/Home/VideoSlider/VideoSlider";
import FeatureHighlight from "../components/Home/FeatureHighlight/FeatureHighlight";
import HomeBanner from "../components/Home/HomeBanner/HomeBanner";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HomeBanner />
      {/* <FirstSection /> */}
      <WhatIsScholarAdda />
      {/* Blending education section 🔻 */}
      {/* <SecondSection /> */}
      <LeaderBoard />
      {/* <ThirdSection /> */}
      {/* <FourthSection /> */}
      {/* <FifthSection /> */}
      <FeatureHighlight />
      <HowItWorkSlider />
      {/* <SixthSection /> */}
      <VideoSlider />
      <SlickSliderTestimonials isHeading={true} />
      <Blog />
      <SubscribeSection />
      {/* old footer 🔻 */}
      {/* <Footer /> */}
      <FooterV2 />
    </div>
  );
};

export default Home;
