import React, { useEffect } from "react";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import SupportBanner from "../components/Support/SupportBanner/SupportBanner";
import SupportHelp from "../components/Support/SupportHelp/SupportHelp";

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SupportBanner />
      {/* <SupportHelp /> */}
      <FooterV2 />
    </>
  );
};

export default Support;
