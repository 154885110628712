import React from "react";
import rewardV2 from "../assets/home/rewardv2.png";
import playStore from "../assets/home/Google Play.svg";
import apple from "../assets/home/App Store.svg";
import logo from "../assets/logo.png";
import instagram from "../assets/mdi_instagram.png";
import twitter from "../assets/ri_twitter-x-fill.png";
import FooterShadow from "../assets/FooterShadow.svg";
import CustomFooter from "../components/HowItWork/Footer";
import "../css/Home/footerv1.css";
import { motion } from "framer-motion";

function Footer() {
  return (
    <>
      <div className="footerv1">
        <div className="fooV2">
          <div>
            <p className="text11">
              <div className="hidden">
                <div data-aos="fade-up">Start Your Rewarding</div>
              </div>
            </p>
            <p className="text11">
              <div className="hidden">
                <div data-aos="fade-up">Academic Adventure!</div>
              </div>
            </p>
            <div className="spce_20 desk_hide"></div>
            <p className="text12">
              <div className="hidden">
                <div data-aos="fade-up">Download now and kickstart your</div>
              </div>
            </p>
            <p className="text12">
              <div className="hidden">
                <div data-aos="fade-up">
                  journey to earning with Scholars Adda!
                </div>
              </div>
            </p>
            <div style={{ padding: "20px 0", display: "flex", gap: "8px" }}>
              <div className="hidden">
                <div data-aos="fade-up">
                  <div
                    className="AppBtn"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                        "_blank"
                      )
                    }
                  >
                    <img src={playStore} alt="playStore" />
                  </div>
                </div>
              </div>
              <div className="hidden">
                <div data-aos="fade-up">
                  <div className="AppBtn">
                    <img src={apple} alt="applestore" />
                  </div>
                  <p className="cs_2">Coming soon</p>
                </div>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <img src={rewardV2} alt="imgg" className="bodyImgv1" />
          </motion.div>

          <img
            src={FooterShadow}
            alt="imgg"
            style={{
              zIndex: 10,
              position: "absolute",
              right: 0,
              bottom: "-290px",
              zIndex: -1,
            }}
            className="mob_hide"
          />
        </div>
        {/* <div style={{ margin: "100px 0 0" }}> */}
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div>
              <img src={logo} alt="logo" />
              <p
                style={{
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: 400,
                  width: "193px",
                  margin: 0,
                }}
              >
                Unlock limitless opportunities for growth and knowledge.
              </p>
            </div>
            <div style={{ marginLeft: "50px" }}>
              <div>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  Home
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  About Us
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  How it Works
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  Contact Us
                </a>
              </div>
              <div style={{ margin: "10px 0" }}>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  Brand Ambassador Program
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  Legal
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    margin: "0 10px",
                  }}
                >
                  Blogs
                </a>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "25px" }}>
            <img
              src={instagram}
              alt="logo"
              style={{ height: "25px", width: "25px" }}
            />
            <img
              src={twitter}
              alt="logo"
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        </div> */}
        {/* </div> */}
      </div>
      {/* <CustomFooter /> */}
    </>
  );
}

export default Footer;
