import image1 from "../../../assets/aboutUs/features/1.png";
import image2 from "../../../assets/aboutUs/features/2.png";
import image3 from "../../../assets/aboutUs/features/3.png";
import image4 from "../../../assets/aboutUs/features/4.png";
import image5 from "../../../assets/aboutUs/features/5.png";

export const AboutFeatureData = {
  title: "The Features of <span>Scholars Adda</span>",
  cards: [
    {
      id: 1,
      title: "Integrating Ai for <br /> Data enhancing",
      url: image1,
    },
    {
      id: 2,
      title: "360° live proctoring",
      url: image2,
    },
    {
      id: 3,
      title: "DAO",
      url: image3,
    },
    {
      id: 4,
      title: "PoT Infrastructure",
      url: image4,
    },
    {
      id: 5,
      title: "Blockchain base <br /> certifications",
      url: image5,
    },
  ],
};
