import React, { useEffect } from "react";

import FooterV2 from "../components/common/FooterV2/FooterV2";
import SubscribeSection from "../components/Home/SubscribeSection";
import ExamBanner from "../components/Exams/ExamBanner/ExamBanner";
import ExamRewards from "../components/Exams/ExamRewards/ExamRewards";
import Instructions from "../components/Exams/Instructions/Instructions";
import Disclaimer from "../components/Exams/Disclaimer/Disclaimer";
import UpcomingExam from "../components/Exams/UpcomingExam/UpcomingExam";
import { ExamData } from "../../src/components/Exams/UpcomingExam/ExamData";

const Exams = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ExamBanner data={ExamData?.ongoing[0]} />
      <ExamRewards data={ExamData?.ongoing[0]} />
      <UpcomingExam />
      <Instructions />
      <Disclaimer
        title={"Scholarship Exam Disclaimer"}
        desc={`The scholarship exam conducted by Scholars Adda aims to assess
              candidates' academic proficiency for potential scholarship awards.
              While the exam is crafted to ensure fairness, specific outcomes
              are not guaranteed. Scholarships depend on availability and
              eligibility criteria set by Scholars Adda. Participation doesn't
              assure a scholarship, as decisions consider various factors.
              Personal information is kept confidential. Scholars Adda is not
              liable for inaccuracies and candidates participate at their own
              risk. Candidates agree to the terms outlined in this disclaimer
              upon registering. For inquiries, contact Scholars Adda at{" "}
              <a href="#">inquiry@scholarsadda.ai or 1800 309 2838.</a>`}
      />
      <SubscribeSection />
      <FooterV2 />
    </>
  );
};

export default Exams;
