import React, { memo } from "react";
import Styles from "./RefRewardTable.module.scss";
import { PropTypes } from "prop-types";
import { RefRewardData } from "./RefRewardData";
import RefRow from "./RefRow";

const RefRewardTable = (props) => {
  const data = props?.data;
  return (
    <>
      <section className={Styles.table}>
        <div className="hidden">
          <div data-aos="fade-up">
            <div className={Styles.table_head}>
              <div></div>
              <div>Rewards</div>
              <div className={Styles.table_head_points}>Points</div>
            </div>
          </div>
        </div>

        <div className={Styles.table_rows}>
          {data?.map((item, index) => {
            return (
              <>
                <div style={{ width: "100%" }} key={index} className="hidden">
                  <div data-aos="fade-up">
                    <RefRow
                      key={index}
                      index={index}
                      title={item?.title}
                      points={item?.points}
                      icon={item?.icon}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

RefRewardTable.defaultProps = {
  data: RefRewardData,
};
RefRewardTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(RefRewardTable);
