import React from "react";
import Styles from "./RefJoin.module.scss";
import one from "../../../assets/referals/01.png";
import two from "../../../assets/referals/02.png";
import Three from "../../../assets/referals/03.png";
import RefJoinCard from "./RefJoinCard";

const RefJoin = () => {
  return (
    <>
      <section className={Styles.RefJoin}>
        <div className={Styles["RefJoin--title"]}>
          <div className="hidden">
            <div data-aos="fade-up">How to Join</div>
          </div>
        </div>

        <div className={Styles.RefJoin_steps}>
          <RefJoinCard
            step="01"
            stepImg={one}
            title="Share Your Referral Code:"
            desc="Get your unique referral code from the app and share it with friends and family. Spread the word through text, email, social media, or any way you prefer. Every new member you bring helps us build a stronger community of learners."
          />
          <RefJoinCard
            step="02"
            stepImg={two}
            title="Welcome New Members:"
            desc="When someone you refer downloads the Scholars Adda app and registers using your referral code, both you and the new member will receive 25 points as an instant reward."
          />
          <RefJoinCard
            step="03"
            stepImg={Three}
            title="Enjoy Your Rewards:"
            desc="Use your points across the Scholars Adda platform to redeem the rewards offered!"
          />
        </div>
      </section>
    </>
  );
};

export default RefJoin;
