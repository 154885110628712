export const AboutSolutionData = {
  title: "<span>Solutions</span> We're Creating",
  list: [
    "Decentralized and Secure Examination Framework",
    "Rewarding Talent and Skills",
    "Live and Interactive Examination Experience",
    "Fair and Transparent Evaluation",
    "Institutional Platform & Tools",
    "Psychological Well-being of Students",
    "Bridging Socio-economic Gaps",
    "Preparing for a Digital Future",
  ],
};
