// import { Carousel as CarouselComponent } from " ";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import userImage from "../../assets/userImage.png";
import invertedComma from "../../assets/invertedComma.png";
import NamanImg from "../../assets/Testimonial's/Naman Picha.jpeg";
import RoshniImg from "../../assets/Testimonial's/Roshni Lilhare.jpeg";
import PoorwaImg from "../../assets/Testimonial's/Poorwa Sonwane.jpeg";
import DarshanImg from "../../assets/Testimonial's/Darshan Rakhecha.jpeg";
import KinjalImg from "../../assets/Testimonial's/Kinjal Gohil.jpeg";
import GauravImg from "../../assets/Testimonial's/Gaurav Sadgir.jpeg";
import WagheImg from "../../assets/Testimonial's/Waghe Savitra.jpeg";
import MahimaImg from "../../assets/Testimonial's/Mahima Admane.jpeg";
import ShubhangiImg from "../../assets/Testimonial's/Shubhangi Sabale.jpeg";
import FalendraImg from "../../assets/Testimonial's/Falendra Harinkhede.jpeg";
import TanayaImg from "../../assets/Testimonial's/Tanaya Deshbhratar.jpeg";
import TruptiImg from "../../assets/Testimonial's/Trupti Gatir.jpeg";
import NavnathImg from "../../assets/Testimonial's/Navnath Barh.jpeg";
import RushikeshImg from "../../assets/Testimonial's/Rushikesh Sabale.jpeg";
import SwarajImg from "../../assets/Testimonial's/Swaraj Kajale.jpeg";
import leftSideBrand1 from "../../assets/leftSideBrand1.svg";
import ScholarTestiText from "../../assets/scholar-testimonial-text.svg";

const carouselData = [
  {
    description:
      "This is a very wonderful app and a good opportunity for students to express their talent. 💯💯",
    userName: "Naman Picha",
    class: "10th Grade",
    userImage: NamanImg,
  },
  {
    description:
      "This Scholars Adda app is incredibly user-friendly and intuitive, streamlining my tasks effortlessly. Highly recommended for its efficiency and convenience. I'm very happy with the exam and the rewards that they are offering for students",
    userName: "Roshni Lilhare",
    class: "8th Grade",
    userImage: RoshniImg,
  },
  {
    description:
      "Scholars Adda app is the most helpful app this app gives the best opportunity for students to show their talent any student can test their knowledge and earn money without any registration fees this app I earned 1100 INR I will suggest this app to all students because this app is really helpful Thankyou polarize for giving this opportunity to students",
    userName: "Poorwa Sonwane",
    class: "10th Grade",
    userImage: PoorwaImg,
  },
  {
    description:
      "Yes, this app will provide you with scholarships you can test by mock tests and monthly exams and you just need to read your school books.",
    userName: "Darshan Rakhecha",
    class: "10th Grade",
    userImage: DarshanImg,
  },
  {
    description:
      "I'm glad I won the scholarship thanks to Scholars Adda. The questions about how the app worked were bang on.",
    userName: "Kinjal Gohil",
    class: "8th Grade",
    userImage: KinjalImg,
  },
  {
    description:
      "I was able to secure the first rank in the Scholars Adda scholarship exam and claimed Rs 6100 as a reward. 8t was an amazing test to our knowledge. I would encourage every 8th to Postgraduate Student to appear and test your knowledge.",
    userName: "Gaurav Sadgir",
    class: "9th Grade",
    userImage: GauravImg,
  },
  {
    description:
      "Scholars Adda is truly the treasure hub for scholarship aspirants, bursaries, internships and many more. The constant personalized feedback from the initiation stage helped gaining excellence in the exam and hence win the scholarship.",
    userName: "Waghe Savitra",
    class: "Graduate",
    userImage: WagheImg,
  },
  {
    description:
      "Scholars Adda made it easy to clear the scholarship test. The rigorous and insightful study material and tests in the app paid off.",
    userName: "Mahima Admane",
    class: "9th Grade",
    userImage: MahimaImg,
  },
  {
    description:
      "The mock exams in the app are like real-time sitting in the exam that helped me achieve scholarship and success.",
    userName: "Shubhangi Sabale",
    class: "12th Grade",
    userImage: ShubhangiImg,
  },
  {
    description:
      "I received my scholarship thanks to Scholars Adda's detailed practice tests and valuable feedback.",
    userName: "Falendra Harinkhede",
    class: "10th Grade",
    userImage: FalendraImg,
  },
  {
    description:
      "I won a scholarship through Scholars Adda thanks to the unique approach to preparing for the exams the app takes.",
    userName: "Tanaya Deshbhratar",
    class: "12th Grade",
    userImage: TanayaImg,
  },
  {
    description:
      "I got a prestigious scholarship thanks to Scholars Adda's help. Highly recommended!",
    userName: "Trupti Gatir",
    class: "11th Grade",
    userImage: TruptiImg,
  },
  {
    description:
      "Thanks to Scholars Adda's detailed analysis and practice tests, I came very close to winning the scholarship. I highly recommend this application for serious students.",
    userName: "Navnath Barh",
    class: "12th Grade",
    userImage: NavnathImg,
  },
  {
    description:
      "Scholars Adda helped me get the scholarship through a live online exam, which made me more confident.",
    userName: "Rushikesh Sabale",
    class: "10th Grade",
    userImage: RushikeshImg,
  },
  {
    description:
      "This is a god gift for poor children who cannot afford school can be educated from this app👍👍",
    userName: "Swaraj Kajale",
    class: "10th Grade",
    userImage: SwarajImg,
  },
];

const SlickSliderTestimonials = ({ isHeading }) => {
  const settings = {
    // dots: true,
    infinite: true,
    // slidesToShow: 3.8,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 4000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 4000, // for screens larger than 1024px
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 2500, // for screens larger than 1024px
        settings: {
          slidesToShow: 4.6,
        },
      },
      {
        breakpoint: 1900, // for screens larger than 1024px
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1624, // for screens larger than 1024px
        settings: {
          slidesToShow: 3.6,
        },
      },
      {
        breakpoint: 1452, // for screens larger than 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1224, // for screens larger than 1024px
        settings: {
          slidesToShow: 2.6,
        },
      },
      {
        breakpoint: 1045, // for screens between 768px and 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800, // for screens smaller than 768px
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 637, // for screens smaller than 768px
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };

  return (
    <div className="testi-main-container">
      {/* {isHeading && (
        <p className="headingText">
          Voices of Success: Hear What Our <br /> Achievers Have to Say!
        </p>
      )} */}
      <Slider className="slider-container1" {...settings}>
        {carouselData?.map((carousel, index) => (
          <div key={index} className="carousel-data-container">
            <div className="data-container hidden">
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                style={{ position: "relative" }}
              >
                <img
                  src={ScholarTestiText}
                  alt="scholar-adda"
                  className="scholar-testimonial-text"
                />
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                style={{ display: "flex" }}
              >
                <img src={invertedComma} alt="img" className="quoteIMg" />
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                className="user-desription"
              >
                {carousel?.description}
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    textAlign: "start",
                  }}
                >
                  <div className="testimonialUserNameText">
                    {carousel?.userName}
                  </div>
                  <div className="testimonialClassText">
                    class: {carousel?.class}
                  </div>
                </div>
                <div className="testimonialImage">
                  <img
                    src={carousel?.userImage}
                    alt="img"
                    width={66}
                    height={69}
                    style={{ borderRadius: "97px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <img
        style={{
          position: "absolute",
          bottom: "-290px",
          left: "0px",
          zIndex: 0,
        }}
        src={leftSideBrand1}
        alt="Vector"
        className="mob_hide"
      />
    </div>
  );
};
export default SlickSliderTestimonials;
