import React from "react";
import logo from "../../assets/scholar-adda-logo.svg";
import instagram from "../../assets/mdi_instagram.png";
import twitter from "../../assets/ri_twitter-x-fill.png";
import playstore from "../../assets/play-store-white.png";
import star1 from "../../assets/FooterStar1.svg";
import star2 from "../../assets/FooterStar2.svg";
import "../../css/Footer.css";
import { useNavigate } from "react-router";

function Footer() {
  const navigate = useNavigate();
  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <div className="costumWrap">
        <div className="logoSocisla">
          <div style={{ display: "flex" }}>
            <div>
              <img src={logo} alt="logo" />
              <p className="FooterDesc">
                Unlock limitless opportunities <br /> for growth and knowledge.
              </p>
              <button
                className="playStoreButton"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.polarizenetwork.edutechfrontend&pcampaignid=web_share",
                    "_blank"
                  )
                }
              >
                <img src={playstore} /> Download Now
              </button>
            </div>
            <div className="mob_hide" style={{ marginLeft: "50px" }}>
              <div style={{ display: "flex", gap: "37px" }}>
                <a className="FooterTitle" onClick={() => navigate("/home")}>
                  Home
                </a>
                <a
                  className="FooterTitle"
                  onClick={() => navigate("/about-us")}
                >
                  About Us
                </a>
                <a
                  className="FooterTitle"
                  onClick={() => navigate("/how-it-works")}
                >
                  How it Works
                </a>
                <a
                  className="FooterTitle"
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </a>
              </div>
              <div style={{ marginLeft: "37px", display: "flex", gap: "37px" }}>
                <a
                  className="FooterTitle"
                  onClick={() => navigate("/brand-ambassador-program")}
                >
                  Brand Ambassador Program
                </a>
                <a className="FooterTitle" onClick={() => navigate("/legal")}>
                  Legal
                </a>
                <a
                  className="FooterTitle"
                  onClick={() => navigate("/access-platform")}
                >
                  Blogs
                </a>
              </div>
            </div>
          </div>
          <div className="socialMediaDiv">
            <img
              src={instagram}
              alt="logo"
              onClick={() =>
                openLink(
                  "https://www.instagram.com/scholars__adda/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                )
              }
            />
            <img
              src={twitter}
              alt="logo"
              onClick={() =>
                openLink(
                  "https://x.com/AddaSchola14472?t=JJuOaif7-y35tvlotUVDUg&s=09"
                )
              }
            />
          </div>
        </div>
        <div className="desk_hide mob_link_wrapp">
          <div className="mob_links">
            <a className="FooterTitle" onClick={() => navigate("/home")}>
              Home
            </a>
            <a className="FooterTitle" onClick={() => navigate("/about-us")}>
              About Us
            </a>
            <a
              className="FooterTitle"
              onClick={() => navigate("/how-it-works")}
            >
              How it Works
            </a>
            <a className="FooterTitle" onClick={() => navigate("/contactus")}>
              Contact Us
            </a>
            <a
              className="FooterTitle"
              onClick={() => navigate("/brand-ambassador-program")}
            >
              Ambassador Program
            </a>
            <a className="FooterTitle" onClick={() => navigate("/legal")}>
              Legal
            </a>
            <a
              className="FooterTitle"
              onClick={() => navigate("/access-platform")}
            >
              Blogs
            </a>
          </div>
        </div>
      </div>
      <div className="copyrightDiv">
        <img
          src={star1}
          alt="logo"
          className="foo_star1"
         
        />
        <img
          src={star2}
          alt="logo"
          className="foo_star2"
        />
        <p className="copyrightText">© 2023 Scholars Adda</p>
      </div>
    </>
  );
}

export default Footer;
