import React from 'react';
import womanCelebrating from "../../../assets/woman-celebrating.png";
import "../../../css/HowItWorks.css";
import "../../../css/BrandAmbassador.css";

const BrandJoinUs = () => {
  return (
    <>
        <div className="ba_wrapper">
        <div className="ba_join_us">
        <div>
          <div
            className="ba_join_content"
            style={{
              background: `linear-gradient(to left, rgb(0 0 0 / 0), rgba(0 0 0 / 0), rgba(235, 126, 8, 1), rgba(235, 126, 8, 1), rgba(235, 126, 8, 1)),
    url(${womanCelebrating})`,
            }}
          >
            <p className="joinUsText">
              Join us in reshaping education, one engaging experience at a time!
            </p>
            <p className="joinUsSubText">
              The Scholars Adda Brand Ambassador Program is your platform to not
              only learn but also actively contribute to the unfolding narrative
              of Web3.
            </p>
            <button
              className="accessOnlineButton ba_ac_btn"
              onClick={() =>
                window.open(
                  "https://x.com/AddaSchola14472?t=JJuOaif7-y35tvlotUVDUg&s=09",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Join Now 🎉
            </button>
          </div>
        </div>
      </div>
        </div>
    </>
  )
}

export default BrandJoinUs