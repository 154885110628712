import React from "react";
import starImg from "../../assets/star.png";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import frame from "../../assets/Frame.png";
import Polygon from "../../assets/Polygon 42.png";
import Layer_1 from "../../assets/Layer_1.png";
import Diamond1 from "../../assets/Diamond1.png";
import "../../css/Home/FifthSection.css";

const FifthSection = () => {
  return (
    <div className="fifth_section_container">
      <div className="diamond_container">
        <img src={Diamond1} alt="img" />
      </div>
      <div className="content_container">
        <div className="highlight_title desk_hide">
          <h2>Features Highlights</h2>
        </div>
        <div className="inner_container">
          <img className="star_img" src={starImg} alt="imgg" />
          <img className="feature_img" src={feature2} alt="imgg" />
          <img className="feature_img2" src={feature1} alt="imgg" />
          <div className="academic_align">
            <div className="wrapp2">
              <h2>Academic Alignment</h2>
              <p>
                Explore our educational resources, master new skills and expand
                your knowledge.
              </p>
            </div>
          </div>
          <div className="earn_while_learn">
            <div className="wrapp2">
              <h2>Earn while you Learn</h2>
              <p>
                Explore our educational resources, master new skills and expand
                your knowledge.
              </p>
            </div>
          </div>
          <div className="wrapp">
            <div className="highlight_title mob_hide">
              <h2>Features Highlights</h2>
            </div>
            <div className="engaging_quizzes">
              <div className="quiz_1">
                <h2>Engaging Quizzes</h2>
                <p>
                  Explore our educational resources, master new skills and
                  expand your knowledge.
                </p>
              </div>
            </div>
            <div className="security">
              <div className="wrapp2">
                <h2>Security</h2>
                <p>
                  Explore our educational resources, master new skills and
                  expand your knowledge.
                </p>
              </div>
            </div>
            <img className="polygon_img" src={Polygon} alt="imgg" />
            <img className="layer_img" src={Layer_1} alt="imgg" />
            <img className="frame_img" src={frame} alt="imgg" />
            <img className="feature2_img" src={feature2} alt="imgg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
