import React from "react";
import Styles from "./HomeCardComp.module.scss";
import HTMLReactParser from "html-react-parser";

const HomeCard = ({ data }) => {
  return (
    <>
      <div className={Styles.card}>
        <div className={Styles.card_img}>
          <img src={data?.cardUrl} alt="card_img" />
        </div>
        <div className={Styles.card_title}>
          {HTMLReactParser(data?.title || "")}
        </div>
      </div>
    </>
  );
};

export default HomeCard;
