import React, { memo } from "react";
import Styles from "./AboutFeature.module.scss";
import { PropTypes } from "prop-types";
import { AboutFeatureData } from "./AboutFeatureData";
import HTMLReactParser from "html-react-parser";
import AboutFeatureCard from "./AboutFeatureCard";

const AboutFeature = (props) => {
  const data = props?.data;
  return (
    <>
      <section id="features" className={Styles.feature}>
        <div className={Styles.feature_title}>
          <div className="hidden">
            <div data-aos="fade-up">{HTMLReactParser(data?.title || "")}</div>
          </div>
        </div>
        <div className={Styles.feature_cards}>
          <div className={Styles.feature_cards_grid2}>
            {data?.cards.slice(0, 2).map((item, index) => {
              return (
                <>
                  <AboutFeatureCard
                    id={item.id}
                    url={item?.url}
                    title={item?.title}
                  />
                </>
              );
            })}
          </div>
          <div className={Styles.feature_cards_grid3}>
            {data?.cards.slice(2).map((item, index) => {
              return (
                <>
                  <AboutFeatureCard
                    id={item.id}
                    url={item?.url}
                    title={item?.title}
                  />
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

AboutFeature.defaultProps = {
  data: AboutFeatureData,
};
AboutFeature.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(AboutFeature);
