import React, { useEffect } from "react";
import FooterV2 from "../components/common/FooterV2/FooterV2";
import DeleteMyAc from "../components/DeleteMyAc";

function DeleteMyAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <DeleteMyAc />
      <FooterV2 />
    </div>
  );
}

export default DeleteMyAccount;
