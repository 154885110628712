import Logo from "../../../assets/home/foo_logo.svg";
export const FooterV2Data = {
  logo: Logo,
  title: "Unlock limitless opportunities for growth and knowledge.",
  copyright: "© 2024 All Rights Are Reserved. Designed & Developed By Scholars Adda Private Limited",
  list: [
    // {
    //   title: "Home",
    //   links: [
    //     { label: "About Us", url: "#" },
    //     { label: "How it Works", url: "#" },
    //     { label: "Contact Us", url: "#" },
    //     { label: "Ambassador Program", url: "#" },
    //     { label: "Legal", url: "#" },
    //     { label: "B2B", url: "#" },
    //   ],
    // },
    {
      title: "Company",
      links: [
        { label: "About Us", url: "/about-us" },
        { label: "Blogs", url: "/blogs" },
        // { label: "Legal", url: "/legal" },
        { label: "Upcoming Scholarship Exams", url: "/exams" },
        {
          label: "Brand Assets",
          url: "https://drive.google.com/drive/folders/1GG_81UClyKxVxdFRAcbIwdvrgg4t62-s?usp=sharing",
        },
      ],
    },
    {
      title: "Legal",
      links: [
        { label: "Terms & Conditions", url: "/terms-of-service" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        // { label: "Security Measures", url: "#" },
        // { label: "Points & Rewards", url: "#" },
      ],
    },
    // {
    //   title: "Socials",
    //   links: [
    //     { label: "Facebook", url: "#" },
    //     { label: "Instagram", url: "#" },
    //     { label: "Telegram", url: "#" },
    //     { label: "LinkedIn", url: "#" },
    //     { label: "x.com", url: "#" },
    //     { label: "YouTube", url: "#" },
    //     { label: "Discord", url: "#" },
    //     { label: "WhatsApp", url: "#" },
    //     { label: "Threads", url: "#" },
    //     { label: "Reddit", url: "#" },
    //   ],
    // },
    {
      title: "Join Us",
      links: [
        { label: "Careers", url: "/career" },
        { label: "Ambassador Program", url: "/brand-ambassador-program" },
        { label: "Referral Program", url: "/referral" },
      ],
    },
    {
      title: "Need Help",
      links: [
        { label: "Support", url: "/support" },
        { label: "Feedback", url: "https://zfrmz.in/ZlVLXmjT0roL07bF3pb6" },
        // { label: "Raise Complaint", url: "#" },
        // { label: "Feature Request", url: "#" },
        { label: "Contact Us", url: "/contactus" },
        { label: "Delete Account", url: "/delete-my-account" },
      ],
    },
  ],
};
