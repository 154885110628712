import React, { useState } from "react";
import feature1 from "../assets/feature1.png";
import "../css/ContactUs.css";
import { OutlinedInput } from "@mui/material";
import instagram from "../assets/insta_black.svg";
import xIcon from "../assets/x_black.svg";
import discode from "../assets/discode_black.svg";
import linkedID from "../assets/linkid_black.svg";
import emailLogo from "../assets/clarity_email-solid.svg";
import phone from "../assets/ic_baseline-phone.svg";
import location from "../assets/mingcute_location-fill.svg";
import clock from "../assets/clock.svg";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMediaQuery } from "../Utils/useMediaQuery";
import { Close } from '@mui/icons-material';
import { toast } from "react-toastify";

const libraries = ["places"];
const mapContainerStyleDesk = {
  width: "100%",
  height: "364px",
  borderRadius: "20px",
};
const mapContainerStyleMob = {
  width: "100%",
  height: "188px",
  borderRadius: "20px",
};

const center = {
  lat: 19.852133831041037, // default latitude
  lng: 75.31695619738863, // default longitude
};

const GOOGLE_MAP_API_KEY = "AIzaSyCbZBYJMPiSOy_Rked-UqBldfYSEhcPLrM";

function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState({});
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  const isMobile = useMediaQuery("(max-width:767px)");

  // if (loadError) {
  //     return <div>Error loading maps</div>;
  // }

  // if (!isLoaded) {
  //     return <div>Loading maps</div>;
  // }

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const validateEmail = () => {
    if (!email?.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      console.log("sss====")
      setErrorMsg(prev => { return { ...prev, email: "Please Enter Valid Email" } });
    } else {
      setErrorMsg({ email: "" });
    };
  };

  const onFirstNameBlur = () => {
    if (firstName) {
      setErrorMsg({
        ...errorMsg,
        firstName: ""
      });
      return false;
    } else {
      setErrorMsg(prev => {
        return {
          ...prev,
          ...errorMsg,
          firstName: "Please enter first name"
        }
      });
      return true;
    }
  }

  const onLastNameBlur = () => {
    console.log("0000")
    if (lastName) {
      setErrorMsg({
        ...errorMsg,
        lastName: ""
      });
      return false;
    } else {
      setErrorMsg(prev => {
        return {
          ...prev,
          ...errorMsg,
          lastName: "Please enter last name"
        }
      });
      return true;
    }
  }

  const onSubjectBlur = () => {
    if (subject) {
      setErrorMsg({
        ...errorMsg,
        subject: ""
      });
      return false;
    } else {
      setErrorMsg(prev => {
        return {
          ...prev,
          ...errorMsg,
          subject: "Please enter subject"
        }
      });
      return true;
    }
  }

  const onMessageBlur = () => {
    if (message) {
      setErrorMsg({
        ...errorMsg,
        message: ""
      });
      return false;
    } else {
      setErrorMsg(prev => {
        return {
          ...prev,
          ...errorMsg,
          message: "Please enter message"
        }
      });
      return true;
    }
  }

  const handleSendMail = async () => {
    try {
      if (validateEmail(email) || onFirstNameBlur() || onLastNameBlur() || onSubjectBlur() || onMessageBlur()) {
        return;
      }
      const requestOptions = {
        method: 'POST'
      };
      const res = await fetch(`${process.env.REACT_APP_API_URL}/v1/utils/mailing-list?email=${email}&first_name=${firstName}&last_name=${lastName}&subject=${subject}&message=${message}`, requestOptions);
      console.log("ress==", res);
      if (res.status === 200) {
        setEmail("");
        setFirstName("");
        setLastName("");
        setMessage("");
        setSubject("");
        toast.success("Form Submitted Successfully.")
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="cu_wrapper">
      <img className="cu_feature1Img" src={feature1} alt="imgg" />
      <p className="HeroMainText">We’re here to help</p>
      <p className="HeroSubText">
        Reach out to us for any assistance or inquiries
      </p>
      <div className="cu_rightBox">
        <div className="cu_box">
          <div className="cu_wrap1">
            <div>
              <p className="TalkText">Let’s Talk with us </p>
              <p className="TalkDesc">
                Questions, Comments or Suggestions? Simply fill in the and will
                contact soon!
              </p>
            </div>

            <div className="mob_hide">
              <div>
                <p className="contactConnectText">Other Ways to connect</p>
                <div style={{ display: "flex", gap: "14px" }}>
                  <img src={emailLogo} alt="email" />
                  <p className="emailText">support@scholarsadda.ai</p>
                </div>
                <div
                  style={{ display: "flex", gap: "14px", marginTop: "15px" }}
                >
                  <img src={phone} alt="phone" />
                  <p className="emailText">+91 80 6920 0871</p>
                </div>
                <div
                  style={{ display: "flex", gap: "14px", marginTop: "15px" }}
                >
                  <img src={location} alt="locatin" />
                  <p className="emailText">
                    304, 3rd Floor, Biz Square, Near Kamalnayan Bajaj Hospital,
                    Beed Bypass Road, Chh. Sambhaji Nagar (Aurangabad),
                    Maharashtra- 431005
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "14px",
                    marginTop: "15px",
                  }}
                >
                  <img src={clock} style={{ marginTop: "4px" }} alt="clock" />
                  <p className="emailText">10AM to 7PM, Monday To Sunday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cu_form">
          <div className="cu_fn_ln">
            <div className="contactInputDiv cu_fn">
              <label className="contactInputFieldLabel">First Name</label>
              <OutlinedInput
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="contactInputField"
                placeholder="Enter your First Name"
                onBlur={onFirstNameBlur}
              />
              {errorMsg.firstName && <div className="emailError">
                {errorMsg?.firstName}
              </div>}
            </div>
            <div className="contactInputDiv cu_ln">
              <label className="contactInputFieldLabel">Last Name</label>
              <OutlinedInput
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="contactInputField"
                placeholder="Enter your Last Name"
                onBlur={onLastNameBlur}
              />
              {errorMsg.lastName && <div className="emailError">
                {errorMsg?.lastName}
              </div>}
            </div>
          </div>
          <div className="contactInputDiv">
            <label className="contactInputFieldLabel">Email</label>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => setEmail(e.target.value)}
              className="contactInputField"
              value={email}
              placeholder="Enter your Email"
              onBlur={validateEmail}
            />
            {errorMsg && <div className="emailError">
              {errorMsg?.email}
            </div>}
          </div>
          <div className="contactInputDiv">
            <label className="contactInputFieldLabel">Subject</label>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => setSubject(e.target.value)}
              className="contactInputField"
              placeholder="Enter Subject"
              value={subject}
              onBlur={onSubjectBlur}
            />
            {errorMsg.subject && <div className="emailError">
              {errorMsg?.subject}
            </div>}
          </div>
          <div className="contactInputDiv">
            <label className="contactInputFieldLabel">Message</label>
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => setMessage(e.target.value)}
              className="contactInputField"
              multiline
              rows={4}
              placeholder="Type your message/Query here"
              value={message}
              onBlur={onMessageBlur}
            />
            {errorMsg.message && <div className="emailError">
              {errorMsg?.message}
            </div>}
          </div>
          <button className="submitButton" onClick={handleSendMail}>Submit</button>
        </div>
        <div className="desk_hide">
          <div className="cu_other_way">
            <p className="contactConnectText">Other Ways to connect</p>
            <div style={{ display: "flex", gap: "14px" }}>
              <img src={email} alt="email" />
              <p className="emailText">support@scholarsadda.ai</p>
            </div>
            <div style={{ display: "flex", gap: "14px", marginTop: "15px" }}>
              <img src={phone} alt="phone" />
              <p className="emailText">+91 80 6920 0871</p>
            </div>
            <div style={{ display: "flex", gap: "14px", marginTop: "15px" }}>
              <img src={location} alt="locatin" />
              <p className="emailText">
                304, 3rd Floor, Biz Square, Near Kamalnayan Bajaj Hospital, Beed
                Bypass Road, Chh. Sambhaji Nagar (Aurangabad), Maharashtra-
                431005
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                gap: "14px",
                marginTop: "15px",
              }}
            >
              <img src={clock} style={{ marginTop: "4px" }} alt="clock" />
              <p className="emailText">10AM to 7PM, Monday To Sunday</p>
            </div>
          </div>
        </div>
      </div>

      {/* Google map section */}
      <div>
        <div className="cu_map_wrap">
          {loadError ? (
            <div>Error loading maps</div>
          ) : !isLoaded ? (
            <div>Loading maps</div>
          ) : (
            <GoogleMap
              mapContainerStyle={
                isMobile ? mapContainerStyleMob : mapContainerStyleDesk
              }
              zoom={10}
              center={center}
            >
              <Marker
                position={center}
                icon={{
                  url: location,
                  scaledSize: new window.google.maps.Size(10, 30),
                }}
              />
            </GoogleMap>
          )}
        </div>
        {/* <div className="socialLinkDiv">
          <p className="stayConnectedText">Stay Connected</p>
          <div className="cu_socials">
            <img
              src={instagram}
              alt="locatin"
              onClick={() =>
                openLink(
                  "https://www.instagram.com/scholars__adda/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                )
              }
            />
            <img
              src={linkedID}
              alt="locatin"
              onClick={() =>
                openLink("https://www.linkedin.com/company/officialpolarize/")
              }
            />
            <img
              src={discode}
              alt="locatin"
              onClick={() => openLink("https://discord.gg/ePWxpY8S")}
            />
            <img
              src={xIcon}
              alt="locatin"
              onClick={() =>
                openLink(
                  "https://x.com/AddaSchola14472?t=JJuOaif7-y35tvlotUVDUg&s=09"
                )
              }
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ContactUs;
