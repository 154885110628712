import React, { useState, useEffect } from "react";
import "./../../css/Timer.css";

const Timer = ({ endTime }) => {
  const calculateTimeRemaining = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return endTime - currentTime;
  };

  const [time, setTime] = useState(calculateTimeRemaining);

  const formatTime = (seconds) => {
    if (seconds <= 0) {
      return {
        days: '00',
        hours: '00',
        minutes: '00',
        remainingSeconds: '00'
      };
    }

    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formatDigit = (digit) => (digit < 10 ? `0${digit}` : `${digit}`);
    return {
      days: formatDigit(days),
      hours: formatDigit(hours),
      minutes: formatDigit(minutes),
      remainingSeconds: formatDigit(remainingSeconds),
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTime(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(intervalId); // Stop the timer
      }
    }, 1000); // Update every 1000 milliseconds (1 second)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const calculatedResult = formatTime(time);

  return (
    <div style={{ display: "flex", gap: 5 }}>
      <div className="time-column">
        {calculatedResult.days} <div className="date-info">Days</div>
      </div>
      <div className="dots">:</div>
      <div className="time-column">
        {calculatedResult.hours} <div className="date-info">Hours</div>
      </div>
      <div className="dots">:</div>
      <div className="time-column">
        {calculatedResult.minutes} <div className="date-info">Minutes</div>
      </div>
      <div className="dots">:</div>
      <div className="time-column">
        {calculatedResult.remainingSeconds} <div className="date-info">Seconds</div>
      </div>
    </div>
  );
};

export default Timer;
